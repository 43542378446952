import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import { ManageActivitiesService } from 'src/app/views/pages/manage-activities/services/manage-activities.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Company } from '../../company-configuration/_subs/models/company.model';
import { FieldService } from '../services/field.service';
import { ListType } from '../../contact-setting/models/list-type.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { EventEmitterService } from '../services/event-emitter.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ProductFormService } from '../../product-form/service/product-form.service';
import { ReferenceDocumentService } from '../services/reference-document.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { customAttributesModel } from '../../company-management/models/customAttributes.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ProductQuantityTransferModel } from './models/quantity-transfer.model';


@Component({
  selector: 'kt-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddFieldComponent implements OnInit {
  @ViewChild('mandatoryCB') mandatoryCB: ElementRef;
  @ViewChild('uniqueCB') uniqueCB: ElementRef;
  @ViewChild('otpValidCB') otpValidCB: ElementRef;
  allSelected=false;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes: String = "";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[] = [];
  optionsForQuantityTransfer = [{id: 1, name: 'Transfer From'}, {id: 2, name: 'Transfer To'}];
  entityOptionsForQuantityTransfer = [{id: 2, name: 'Customer'}, {id: 5, name: 'User'}];
  valuesForQuantityTransfer: {id: number, name: string}[] = [];
  entityFieldListForQuantityTransfer: CustomAttibute[];
  isEnableForCompanies: number;
  selectCompanyField: string;
  optionsForActivity = [];
  isPreviousFormcheckboxSelected = false;

  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  attributeContactType: string;
  mandatoryContact:boolean;
  allowBrowseVal:boolean;
  uniqueVal:boolean;
  decimalFlag: boolean;
  addValidationFieldFlag: boolean;
  enableRegexField: boolean;
  addValidationField:boolean;
  otpValidationCheck:boolean;
  mandatoryContactFlag:number;
  allowBrowsingFlag:number;
  uniqueFlag:number;
  otpValidationFlag:number;
  statusFlag:number | string;
 
  stdLookupList: StdLookupAttribute[];
  attributeType: AttributeType;
  characterType: ListType;
  primaryContact: ListType;
  stage: ListType;
  entity: ListType;
  numericValidationType: ListType;
  resultSet: any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv: boolean;
  displayRefDoc: boolean;
  isDisplayText: boolean;
  isDisplayImage: boolean;
  isDisplayNumeric: boolean;
  isDisplaySelection: boolean;
  isProductFormSelected: boolean;
  displayFields: boolean;
  displayLinkedFields: boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes;
  lookup: LooupValues;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName: string;
  instructions: string;
  selectedattributeType: number;

  editing: boolean;
  dateValueType;
  futureDateValue: number;
  previousDateValue: number;
  flag_Value: boolean;
  browseVal:boolean;
  unique_Value:boolean;
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
  characterTypeList:ListType[];
  numericValidationTypeList:ListType[];
  stageTypeList:ListType[];
  entityTypeList:ListType[];
  allEntityTypeList:ListType[];
  entityLists:ListType[];
  primaryContactTypeList:ListType[];
  selectionTypeList:AttributeType[];
  entityList:CustomAttibute[];
  entityfieldList:CustomAttibute[];
  isDisplayEmail:boolean;
  moduleType:string | number;
  singleSelectOption:boolean;
  multiSelectOption:boolean;
  fieldValue;
  linkedfieldList: any;
  linkedParentFormfieldList = [];
  isExist: boolean;
  isDisplayLinkedField: boolean;
  public productFormList;
  selectForm: boolean;
  data = 2;
  parentFormId: number;
 
  isDocumentUpload: boolean;
  documentTypes = [];
  isVideoUpload: boolean;
  uploadedImageCount: number = 0;
  uploadedFiles = [];
  uploadedFileIdObj = [];
  fileControlValue = [];
  wrongFormat: boolean = false;
  uploadError: boolean = false;
  uploadErrorFileName: string;
  fileUploadQueue: number = 0;
  calculationFormula: string = null;
  normalText: boolean;
  headerText: boolean;
  pageSeperator: boolean;
  fieldTypeChanged: boolean;
  selctedEntityFieldLV: LooupValues[] = [];
  showEntityFieldLV = false;
  showLogicalField: boolean = false;

  showDependentFieldCB = false;
  showDependentFieldDD = false;
  dependentFieldList: customAttributesModel[] = [];
  showParentValueDD = false;
  parentLookupValues: LooupValues[] = [];
  selectedEntityField: CustomAttibute;
  dependentcheckBox = false;
  isOptionsAlphabetically: boolean = false;
  alphabeticallyFlag: number;
  isDataList: boolean = false;
  allSelectedParentFieldValues: boolean = false;
  allSelectedValues: boolean = false;
  documentTypeAllSelected: boolean = false;
  logicalFieldName: string = "";
  entityName: string;
  productFormFlag: boolean = false;
  allSelectedOption: boolean = false;
  isFormIntialised: boolean = false;
  numericDecimalFieldPattern: string = "^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";

  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  public attributecharacterType: FormControl = new FormControl();
  public attributenumericValidationType: FormControl = new FormControl();
  public stageFilterType: FormControl = new FormControl();
  public contactFilterType: FormControl = new FormControl();
  public entityValueType: FormControl = new FormControl();
  public entityfieldType: FormControl = new FormControl();
  public selectionTypeValue: FormControl = new FormControl();
  public selectValue: FormControl = new FormControl();
  public selectValueFilter: FormControl = new FormControl();
  public selectDependentFieldFilter: FormControl = new FormControl();
  public selectParentFieldValue: FormControl = new FormControl();

  public addFieldForm: FormGroup;

  public transferOptionFilter: FormControl = new FormControl();
  public entityOptionFilter: FormControl = new FormControl();
  public entityFieldOptionFilter: FormControl = new FormControl();
  public valueFilter: FormControl = new FormControl();
  public previousFormFilter: FormControl = new FormControl();
  public addValidation: FormControl = new FormControl();
  public enterRgx: FormControl = new FormControl();
  public rgxErrorMessage: FormControl = new FormControl();
  
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  isFieldTypeDataList: boolean = false;
  showAllDataListValues: number = 0;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public _field: FieldService,
    public contactService: ContactListService,
    private eventEmitterService: EventEmitterService,
    public customService: CustomService,
    private _activityService: ManageActivitiesService,
    private _ps: ProductFormService,
    public refDocService: ReferenceDocumentService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog) {
      this.unsubscribe = new Subject();
    }

  ngOnInit(): void {
    if([1, 2, 3].includes(+this.moduleType) || +this.moduleType > 100) {
      this.showLogicalField = true;
    }
    this.initaddCustomAttributeForm();
    this.getAttributeType(this.moduleType);
    this.getcharacterTypeList();
    this.getnumericValidationTypeList();
    this.getstageTypeList();
    this.getentityTypeList();
    this.getprimaryContactTypeList();
    this.fieldTypeChanged = false;
    this.displayDateValueDiv = false;
    this.displayMultiSelectDiv = false;
    this.displaySingleSelectDiv = false;
    this.displayRefDoc = false;
    this.isDocumentUpload = false;
    this.isVideoUpload = false;
    this.normalText = false;
    this.headerText = false;
    this.pageSeperator = false;
    this.companyTypes = new Array<Company>();
    this.companyTypesId = '';
    this.standardTypeValue = new StdLookupAttribute();
    this.disabled = false;
    this.duplicate = false;
    this.isDisplayText = false;
    this.isDisplayImage = false;
    this.isDisplayNumeric = false;
    this.displayLinkedFields = false;
    this.isDisplaySelection = false;
    this.addValidationFieldFlag = false;
    this.addValidationField = false;
    this.enableRegexField = false;
    this.isProductFormSelected = false;
    this.dateAttributes = new DateAttributes();
    this.dateAttributes.stdLookupAttribute = new StdLookupAttribute();
    this.isDisplayEmail = false;
    this.uploadedFiles = [];
  }

  getcharacterTypeList() {
    this.characterTypeList = new Array<ListType>();
    this.characterType = new ListType();
    this.characterTypeList.push({ id: 1, value: "All" });
    this.characterTypeList.push({ id: 2, value: "Alpha-Numeric" });
    this.characterTypeList.push({ id: 3, value: "Alphabet" });
  }

  getprimaryContactTypeList() {
    this.primaryContactTypeList = new Array<ListType>();
    this.primaryContact = new ListType();
    this.primaryContactTypeList.push({ id: 1, value: "Yes" });
    this.primaryContactTypeList.push({ id: 2, value: "No" });
  }
  
  getstageTypeList() {
    this.stageTypeList = new Array<ListType>();
    this.stage = new ListType();
    this.stageTypeList.push({ id: 1, value: "In Progress" });
    this.stageTypeList.push({ id: 2, value: "Closed Won" });
    this.stageTypeList.push({ id: 3, value: "Closed Lost" });
  }

  getnumericValidationTypeList() {
    this.numericValidationTypeList = new Array<ListType>();
    this.numericValidationType = new ListType();
    this.numericValidationTypeList.push({ id: 1, value: "Digits" });
    this.numericValidationTypeList.push({ id: 2, value: "Value" });
  }


  getentityTypeList(){
    this.entityTypeList= new Array<ListType>();
    this.allEntityTypeList= new Array<ListType>();
    this.stage= new ListType();
    this.allEntityTypeList.push({ id: 4, value:"Product"});


    this._activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result => {
        result['responseData'].forEach(
          row => {
            if(row.moduleId==2){
              this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
            }
            if(row.moduleId==3 && row.opportunityEnabled){
              this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
            }
            if(row.moduleId==200){
              this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
            }
            if(row.moduleId==6){
              this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
            }
            if(row.moduleId==1){
              this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
            }
          }
        )
        this.entityTypeList = this.allEntityTypeList;
        if(this.attributeType!==undefined && this.attributeType!==null){
          if(this.attributeType.id==19){
            this.entityTypeList=this.allEntityTypeList.filter(ent=>[1,2,3].includes(ent.id));
          }     
        }
      
      },
      err => {
      }
    )
  }
  
  getselectionTypeList() {
    this.getProductList();
    this.selectionTypeList = new Array<AttributeType>();
    this.selectionTypeList.push({ id: 1, typeName: "Single Select Dropdown" });
    this.selectionTypeList.push({ id: 2, typeName: "Multi Select Dropdown" });

  }

  getProductList() {

    this._ps.getProductForm(this.selectedProject).subscribe(
      data => {
    
        this.productFormList = data['responseData'].filter(row => row.isActive == 1 && !this.linkedParentFormfieldList.includes(row.id));
        
      },
      err => {
        //this.errorText=true;
      }  
    )
 
  } 

  getAttributeType(data) {
    this.customService.getAtrributeType(data).subscribe(
      (result) => {

        if(data != 4 && data != 5){
          this.attributeTypelist = result.filter(el => el?.id != 14);
        }
        else{
          //Excluded the linkedAttributeId from the list, in future if the implementation gets done modify this
          this.attributeTypelist = result.filter(el => el?.id !== 13 && el?.id != 14);
        }
      },
      error => {
        this.errorMes = error?.error?.responseData;
      }
    )
  }

  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
        this.standardTypelist=result;
		},
    error => {
      this.errorMes=error?.error?.responseData;
    })
  }

  getCustomFields(moduleType){
      this._field.getFieldsInSetting(moduleType, this.selectedProject)
      .subscribe(
      (result)=>{
      this.entityList=result['responseData'];
      this.entityfieldList= new Array<CustomAttibute>();

        for(let ent of this.entityList){
            if(ent.fieldSpec == 0){
              if(this.attributeType.id==19){
                this.setEntityFieldList(ent);
              }
              if(ent.systemAttribute.id == 8 || ent.systemAttribute.id == 14 || ent.systemAttribute.id == 17){
                this.setEntityFieldList(ent);
              }

            }else{
               this.setEntityFieldList(ent);
            }
         }
         if(this.attributeType.id==19){
          if(moduleType ==1 || moduleType == 2 || moduleType == 3){
            this.entityfieldList=this.entityfieldList.filter(ent=>ent.attributeType.id==7);
          }
        }
    },
    error => {
      console.log(error)
    })
  }

  setEntityFieldList(ent) {
    this.isExist = false;
    if (ent.status == 1)
      this.linkedfieldList.forEach(ele => {
        if (ele.attributedId == ent.id)
          this.isExist = true;
      });

    if (!this.isExist && ent.status == 1){
      this.entityfieldList.push(ent);
    }   
  }

  getEntityFields(moduleType: number) {
    this.SpinnerService.show('add-field');
    this._field.getFieldsInSetting(moduleType, this.selectedProject).subscribe(result => {
      this.entityFieldListForQuantityTransfer = result['responseData']
        .filter(item => (item.attributeType?.id==1 || item.attributeType?.id==2) && (item.systemAttribute?.id!=16
          && item.systemAttribute?.id!=17 && item.systemAttribute?.id!=59 && item.systemAttribute?.id!=61));

      this.SpinnerService.hide('add-field');
    }, error => {
      this.SpinnerService.hide('add-field');
    });
  }

  initaddCustomAttributeForm() {
    this.addFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required, Validators.maxLength(500)])],
      attributeType: [],
      instructions: [],
      dateValueType: [''],
      documentType: [''],
      attributeMultiSelectName: [''],
      attributeSingleName: [],
      refDocument: [],
      attributeCompanyType: [],
      standardTypeValue: [],
      lookup: [],
      futureDateValue: [],
      previousDateValue: [],
      mandatoryCheck: [],
      unique: [],
      minCharacters: [],
      maxCharacters: [],
      characterType: [],
      maxImagesAllowed: [],
      allowBrowsing: [],
      numericValidationType: [],
      numericMinValue: [null, [Validators.pattern(this.numericDecimalFieldPattern)]],
      numericMaxValue: [null, [Validators.pattern(this.numericDecimalFieldPattern)]],
      minDigits: [],
      maxDigits: [],
      fieldValue: [],
      entityValue: [],
      linkedAttributeId: [],
      selectionType: [],
      parentFormId: [],
      status: [{ value: '1', disabled: true }],
      OptionsItems: this.fb.array([this.getOptions()]),

      selectValues: [],
      selectDependentFields: [],
      selectParentFieldValues: [],
      logicalFieldName: [{ value: '1', disabled: true }],
      
      previousForm: [],
      previousFormcheckbox: [],
      
      quantityTransferCheckbox: [],
      customerTransfer: [],
      entityTransfer: [],
      entityFieldTransfer: [],
      entityFieldValueTransfer: [],
      showValues: [],
      addValidation: [],
      enterRgx: [],
      rgxErrorMessage: []
    });
    this.isFormIntialised = true;
  }

  private getOptions() {
    return this.fb.group({
      id: [],
      OptionVal: ['', RxwebValidators.unique()],
      stageId: []
    });
  }

  getControls() {
    return (this.addFieldForm.get('OptionsItems') as FormArray).controls;
  }

  deleteRow(i: number) {
    const control = <FormArray>this.addFieldForm.controls['OptionsItems'];

    if ((this.addFieldForm.get('OptionsItems') as FormArray).controls.length > 1) {
      control.removeAt(i);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getControls(), event.previousIndex, event.currentIndex);
  }

  addTable(event) {
    event.stopPropagation();
    const control = <FormArray>this.addFieldForm.controls['OptionsItems'];
    control.push(this.getOptions());
    setTimeout(() => {
      (document.querySelector('#option' + (control.length - 1)) as HTMLElement).focus();
    }, 10);
  }

  showOptionsAlphabetically(val) {
    this.isOptionsAlphabetically = val;
  }

  changeSelectionType(data) {
    if(this.isPreviousFormcheckboxSelected === true){
      this.getPreviousSubmittedFormDetails();
    }
    this.isProductFormSelected = true;
  }

  Save() {
    this.disabled = true;
    this.errorMes = "";
    
    if (this.displaySingleSelectDiv || this.displayMultiSelectDiv) {
 
      const control = <FormArray>this.addFieldForm.controls['OptionsItems'];
      var length = this.addFieldForm.controls.OptionsItems.value.length;

      for (var i = 0; i < length; i++) {
        control.at(+i).get('OptionVal').setValidators([Validators.required, RxwebValidators.unique()]);
        control.at(+i).get('OptionVal').updateValueAndValidity();
      }

      for (var i = 0; i < length; i++) {
        if (control.at(+i).get('OptionVal').invalid) {
          control.at(+i).get('OptionVal').markAllAsTouched();
          this.disabled = false;
          return;
        }
      }

    }    
    
    const controls = this.addFieldForm.controls;
    if (this.addFieldForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.disabled = false;
      return;
    }



    if (this.mandatoryContact == true) {
      this.mandatoryContactFlag = 1;
    } else {
      this.mandatoryContactFlag = 0;
    }

    if (this.allowBrowseVal == true) {
      this.allowBrowsingFlag = 1;
    } else {
      this.allowBrowsingFlag = 0;
    }


    if (this.uniqueVal == true) {
      this.uniqueFlag = 1;
    } else {
      this.uniqueFlag = 0;
    }

    if(this.otpValidationCheck == true){
        this.otpValidationFlag=1;
      }else{
        this.otpValidationFlag=0;
      }
 
    if (this.isOptionsAlphabetically == true) {
      this.alphabeticallyFlag = 1;
    } else {
      this.alphabeticallyFlag = 0;
    }

    if (this.isDisplayType()) {
      if (this.attributeType.id == 1) {

      } else if (this.attributeType.id == 2) {

      } else {
        this.lookupVal = [];
      }
    }

    if (this.isDisplaySelection) {

      //console.log(this.attributeType);
    }

    if (this.displaySingleSelectDiv || this.displayMultiSelectDiv) {
      this.lookupVal = [];
      const control = <FormArray>this.addFieldForm.controls['OptionsItems'];
      var length = this.addFieldForm.controls.OptionsItems.value.length;

      for (var i = 0; i < length; i++) {
        var optValue = control.at(+i).get('OptionVal').value.trim();

        this.lookupVal.push({
          id: control.at(+i).get("id").value,
          lookupValue: optValue,
          isActive: 1,
          stageId: control.at(+i).get('stageId').value
        });
      }
    }
    
    const addAttributeData = {
      referenceDocumentUrl: controls.refDocument.value === '' ? null : controls.refDocument.value,
      dateValueType: controls.dateValueType.value,
      companyTypes: controls.attributeCompanyType.value,
      documentType: controls.documentType.value ? controls.documentType.value.join() : '',
      singleValues: controls.attributeSingleName.value,
      ValuesMutli: controls.attributeMultiSelectName.value,
      futureDateValue: controls.futureDateValue.value,
      previousDateValue: controls.previousDateValue.value,
      instructions: controls.instructions.value == null ? '' : controls.instructions.value.trim(),
      characterType: controls.instructions.value,
      minCharacters: controls.minCharacters.value,
      maxCharacters: controls.maxCharacters.value,
      allowBrowsing: controls.allowBrowsing.value,
      maxImagesAllowed: controls.maxImagesAllowed.value,
      numericValidationType: controls.numericValidationType.value,
      minDigits: controls.minDigits.value,
      maxDigits: controls.maxDigits.value,
      minNumber: controls.numericMinValue.value,
      maxNumber: controls.numericMaxValue.value,
      linkedAttributeId: controls.linkedAttributeId.value,
      fieldValue: controls.fieldValue.value,
      parentFormId: controls.selectionType.value ? controls.selectionType.value.id : null,
      parentDataListId: controls.linkedAttributeId.value,
      parentFieldValues: null,
      dependentFieldList: null,
      addDatalistDependentFields: this.dependentcheckBox ? 1 : 0,
      showAllDataListValues: null,
      attributeIdentifierName: controls.logicalFieldName.value,
      isAllowQuantityTransfer: controls.quantityTransferCheckbox?.value ? 1 : 0,
      ispreviousFormSelected: controls.previousFormcheckbox?.value ? 1: 0,
      previousFormActivity: null,
      productQuantityTransferDetail: null,
      addValidation: this.addValidationFieldFlag ? 1 : 0,
      regexExpression: controls.enterRgx.value,
      errorMessage: controls.rgxErrorMessage.value
    };

    if(addAttributeData.ispreviousFormSelected == 1){
      addAttributeData.previousFormActivity = controls.previousForm.value;
    }

    if(addAttributeData.isAllowQuantityTransfer == 1) {
      const commaSeparatedValue = controls.entityFieldValueTransfer.value == null ? null : controls.entityFieldValueTransfer.value.join(', ');
      const quantityTransferFormData: ProductQuantityTransferModel = {
        id: null,
        activityTypeId: +this.moduleType,
        attributeId: null,
        isAllowQuantityTransfer: 1,
        projectId: this.selectedProject,
        selectCompanyOption: controls.customerTransfer.value,
        entityTypeId: controls.entityTransfer.value,
        entityFieldId: controls.entityFieldTransfer.value?.id,
        value: commaSeparatedValue,
        showValuesOption: controls.showValues.value
      };
      addAttributeData.productQuantityTransferDetail = quantityTransferFormData;
    }
    else if(controls.attributeType.value?.id == 13 && this.productFormFlag) {
      const quantityTransferFormData: ProductQuantityTransferModel = {
        id: null,
        activityTypeId: +this.moduleType,
        attributeId: null,
        isAllowQuantityTransfer: 0,
        projectId: this.selectedProject,
        selectCompanyOption: null,
        entityTypeId: null,
        entityFieldId: null,
        value: null,
        showValuesOption: null
      };
      addAttributeData.productQuantityTransferDetail = quantityTransferFormData;
    }

    if (this.isDataList) {
      addAttributeData.linkedAttributeId = null;
    }

    if (addAttributeData.fieldValue == 1) {
      addAttributeData.linkedAttributeId = null;
    }
    if ((addAttributeData.linkedAttributeId != null && addAttributeData.linkedAttributeId != 0) && controls.entityValue.value != 4 && this.attributeType.id!=19) {
      for (let atr of this.entityfieldList) {
        if (atr.id == addAttributeData.linkedAttributeId) {
          this.attributeType = atr.attributeType;
          if (atr.attributeType.id == 3 || atr.attributeType.id == 21) {
            this.dateAttributes.stdLookupAttribute = atr.dateAttributes.stdLookupAttribute;
          }
        }
      }
    }

    if (addAttributeData.numericValidationType == 1 || addAttributeData.numericValidationType == '1') {
      var min = addAttributeData.minDigits;
      var max = addAttributeData.maxDigits;

      if (min != "" && max != "") {
        if (min != null && max != null) {
          if (Number(min) > Number(max)) {
            var message = 'Minimum digits cannot be greater than Maximum digits';
            this.OpenAlert(message);
            this.disabled = false;
            return false;
          }
        }
      }
    }
    if (addAttributeData.numericValidationType == 2 || addAttributeData.numericValidationType == '2') {
      var min = addAttributeData.minNumber;
      var max = addAttributeData.maxNumber;
      if (min != "" && max != "") {
        if (min != null && max != null) {
          if (Number(min) > Number(max)) {
            var message = 'Minimum value cannot be greater than Maximum value';
            this.OpenAlert(message);
            this.disabled = false;
            return false;
          }
        }
      }
    }

    if (this.attributeType.id == 6 || this.attributeType.id == 8) {
      var min = addAttributeData.minCharacters;
      var max = addAttributeData.maxCharacters;
      if (min != "" && max != "") {
        if (min != null && max != null) {
          if (Number(min) > Number(max)) {
            var message = 'Minimum characters cannot be greater than Maximum characters';
            this.OpenAlert(message);
            this.disabled = false;
            return false;
          }
        }
      }
    }


    if (this.isDisplayType()) {
      if (this.attributeType.id == 3 || this.attributeType.id == 21) {
        if (addAttributeData.dateValueType == 'Standard') {

          this.dateAttributes.stdLookupAttribute.id = this.standardTypeValue.id;
          this.dateAttributes.stdLookupAttribute.value = this.standardTypeValue.value;
          this.dateAttributes.previousDateAllowed = null;
          this.dateAttributes.futureDateAllowed = null;

        } else if (addAttributeData.dateValueType == 'Custom') {
          this.dateAttributes.previousDateAllowed = Number(addAttributeData.previousDateValue);
          this.dateAttributes.futureDateAllowed = Number(addAttributeData.futureDateValue);
          this.dateAttributes.stdLookupAttribute = null;
        } else {
          this.dateAttributes = null;
        }
      } else {
        this.dateAttributes = null;
      }
    }


    if (this.characterType.id == undefined || this.characterType.id == null) {
      this.characterType.id = 1;
    }

    if (this.isDataList) {
      if (this.selctedEntityFieldLV.length > 0 && controls.selectValues.value?.length > 0) {
        this.lookupVal = [];
        this.lookupVal = this.selctedEntityFieldLV
          .filter(x => controls.selectValues.value.includes(x.id))
          .map(y => {
            return {
              dataListId: this.selectedEntityField.id,
              dataListValueId: y.id,
              id: null,
              isActive: y.isActive,
              lookupValue: y.lookupValue,
              stageId: y.stageId,
              statusDisplayOrder: y.statusDisplayOrder
            };
          });
        this.showAllDataListValues = 0;
      }
     
      if (controls.selectValues.value.length === 0) {
        this.showAllDataListValues = 1;
      }
      addAttributeData.showAllDataListValues = this.showAllDataListValues;

      if (this.selectedEntityField.parentLookupValues != null) {
        let parentDataListId;
        
        const selectedParentValues: Array<any> = this.addFieldForm.controls.selectParentFieldValues.value;
        if (selectedParentValues && selectedParentValues.length > 0) {
          parentDataListId = this.selectedEntityField.parentRelationList
            .find(x => selectedParentValues.includes(x.parentLookupValueId)).parentAttribId;

          let parentFieldValues = [];
          selectedParentValues.forEach(x => {
            parentFieldValues.push({ dataListValueId: x, dataListId: parentDataListId, isActive: 1 });
          });
          addAttributeData.parentFieldValues = parentFieldValues;
        }

      }

      if (this.selectedEntityField.childAttributeList != null) {
        if (this.addFieldForm.controls.selectDependentFields.value?.length > 0) {
          let dependentFieldList = this.addFieldForm.controls.selectDependentFields.value
            .map(x => { return { dataListId: x, attributeId: null, isActive: 1 } });
          
          addAttributeData.dependentFieldList = dependentFieldList;
        }
        
      }
    }
    else {
      addAttributeData.parentDataListId = null;
    }

    var displayOrder = 99999;
    var fieldSpec = 2;

    const saveAttributeData = {
      attributeName: this.attributeName?.trim(),
      projectId: this.selectedProject,
      parentAttribId: addAttributeData.linkedAttributeId,
      parentFormId: addAttributeData.parentFormId,
      instructions: addAttributeData.instructions,
      mandatoryFlag: this.mandatoryContactFlag,
      moduleType: this.moduleType,
      fieldSpec: fieldSpec,
      displayOrder: displayOrder,
      hasUniqueValues: this.uniqueFlag,
      attributeType: this.attributeType,
      characterType: this.characterType.id,
      minCharacters: addAttributeData.minCharacters,
      maxCharacters: addAttributeData.maxCharacters,
      allowBrowsing: this.allowBrowsingFlag,
      maxImagesAllowed: addAttributeData.maxImagesAllowed,
      numericValidationType: this.numericValidationType.id,
      minDigits: addAttributeData.minDigits,
      maxDigits: addAttributeData.maxDigits,
      minNumber: addAttributeData.minNumber,
      maxNumber: addAttributeData.maxNumber,
      documentType: addAttributeData.documentType,
      referenceDocumentUrl: addAttributeData.referenceDocumentUrl,
      isOptionsAlphabetically: this.alphabeticallyFlag,
      calculationFormula: this.calculationFormula,
      lookupValues: this.lookupVal,
      dateAttributes: this.dateAttributes,
      parentDataListId: addAttributeData.parentDataListId,
      addDataListDependentFields: addAttributeData.addDatalistDependentFields,
      dependentFilelds: addAttributeData.dependentFieldList,
      parentFieldValues: addAttributeData.parentFieldValues,
      showAllDataListValues: addAttributeData.showAllDataListValues,
      status: 1,
      attributeIdentifierName: addAttributeData.attributeIdentifierName,
      otpValidation: this.otpValidationFlag,
      decimalFlag: this.decimalFlag ? 1 : 0,
      isAllowQuantityTransfer: addAttributeData.isAllowQuantityTransfer,
      productQuantityTransferDetail: addAttributeData.productQuantityTransferDetail,
      allowPreviousForm: addAttributeData.ispreviousFormSelected,
      previousFormActivity: addAttributeData.previousFormActivity,
      addValidation: addAttributeData.addValidation,
      regexExpression: addAttributeData.regexExpression,
      errorMessage: addAttributeData.errorMessage
    }
    
    this.SpinnerService.show('save-data');
    this._field.addFields(saveAttributeData)
      .pipe(
        tap(CustomAttibute => {
  
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          
        })
      )
      .subscribe(
        data => {
          this.loading = false;
          this.activeModal.close('SUCCESS');
          this.addFieldForm.reset();
          this.SpinnerService.hide('save-data');
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        },
        error => {
          this.SpinnerService.hide('save-data');
          this.errorMes = error?.error?.responseData;
          this.disabled = false;
          this.eventEmitterService.onModalCloseResult('ERROR');
        }
      );
  }

  mapToValue(ids: []): string {
    return ids.map(x => this.documentTypes.find(type => type.id == x).name).join("/");
  }

  changeCharacterType(data) {
    this.characterType.id = data;
  }

  changeStd(data) {
    this.standardTypeValue = data;
  }

  concatField() {
    if(this.logicalFieldName !== null && this.logicalFieldName !== undefined) {
      let logicalField: string = this.addFieldForm.controls.attributeName.value;
      this.addFieldForm.controls.logicalFieldName.setValue(this.logicalFieldName.toLowerCase().replace(/\s/g, '_')+ "." + logicalField.toLowerCase().replace(/\s/g, '_').replace(/[^\w]/g, '_'));
    }
  }

  change(data) {

    this.fieldTypeChanged = true;
    // resetting form
    let attributeName = this.addFieldForm.controls.attributeName.value;
    this.selectForm = false; // to hide "entity field"
    this.enableRegexField = false;  //to hide regex field
    this.isDisplaySelection = false;  // to hide "select form" field
    this.isProductFormSelected = false; // to hide "Allow user to select data from previously submitted form" field
    this.addFieldForm.reset();
    this.addFieldForm.controls.attributeType.setValue(data);
    this.addFieldForm.controls.attributeName.setValue(attributeName);
    //Logical field name
    if (+this.moduleType > 100) {
      this.logicalFieldName = this.entityName;
    } 
    else {
      this.logicalFieldName = this.entityTypeList.find(x => this.moduleType === x.id)?.value;
    }
    if(this.logicalFieldName !== null && this.logicalFieldName !== undefined) {
      this.addFieldForm.get('logicalFieldName').setValue( this.logicalFieldName.toLowerCase().replace(/\s/g, '_')+ ".");
    }
    if(this.addFieldForm.controls.attributeName.value !== null && this.addFieldForm.controls.attributeName.value !== undefined) {
      this.concatField();
    }
    this.addFieldForm.controls.status.setValue('1');
    (this.mandatoryContact && this.mandatoryCB)?this.mandatoryCB['checked'] = false:'';
    this.mandatoryContact=false;
    (this.uniqueVal && this.uniqueCB)?this.uniqueCB['checked'] = false:'';
    this.uniqueVal=false;
    if(this.otpValidationCheck && this.otpValidCB) {
      this.otpValidCB['checked'] = false
    }
    this.otpValidationCheck=false;

    this.attributeType = data;
    if (this.attributeType.id == 3 || this.attributeType.id == 21 ) {
      this.displayDateValueDiv = true;
      this.getStandardLookUpList();
      this.addFieldForm.controls.dateValueType.setValidators([Validators.required]);
      this.addFieldForm.controls.dateValueType.updateValueAndValidity();
    } else {
      this.displayDateValueDiv = false;
      this.addFieldForm.controls.dateValueType.clearValidators();
      this.addFieldForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }

    this.lookupVal = [];
    if (this.attributeType.id == 2) {
      this.displayMultiSelectDiv = true;
    } else {
      this.displayMultiSelectDiv = false;
    }

    if (this.attributeType.id == 1) {
      this.displaySingleSelectDiv = true;
    } else {
      this.displaySingleSelectDiv = false;
    }

    if (this.attributeType.id == 6) {
      this.isDisplayText = true;
    } else {
      this.isDisplayText = false;
    }

    if (this.attributeType.id == 4) {
      this.isDisplayImage = true;
    } else {
      this.isDisplayImage = false;
    }

    if (this.attributeType.id == 7) {
      this.isDisplayNumeric = true;
    } else {
      this.isDisplayNumeric = false;
      this.addFieldForm.controls['numericValidationType'].reset();
    }

    if(this.attributeType.id == 6 || this.attributeType.id == 7){
      this.addValidationField = true;
    }
    else{
      this.addValidationField = false;
      this.addFieldForm.controls['addValidation'].reset();
    }

    if (this.attributeType.id == 6 || this.attributeType.id == 8) {
      this.isDisplayText = true;
      this.addFieldForm.controls['characterType'].setValue(1);
    } else {
      this.isDisplayText = false;
    }

    if (this.attributeType.id == 4) {
      this.isDisplayImage = true;
    } else {
      this.isDisplayImage = false;
      this.addFieldForm.controls['maxImagesAllowed'].reset();
    }

    if (this.attributeType.id == 7) {
      this.isDisplayNumeric = true;
    } else {
      this.isDisplayNumeric = false;
    }

    if (this.attributeType.id == 9) {
      this.isDisplayEmail = true;
    } else {
      this.isDisplayEmail = false;
    }
    
    if (this.attributeType.id == 10) {
      this.headerText = true;
    } else {
      this.headerText = false;
    }

    if (this.attributeType.id == 11) {
      this.normalText = true;
    } else {
      this.normalText = false;
    }

    if (this.attributeType.id == 12) {
      this.pageSeperator = true;
    } else {
      this.pageSeperator = false;
    }
    
    if (this.attributeType.id == 15) {
      this.isDocumentUpload = true;
      this.getDocumentTypes();
    } else {
      this.isDocumentUpload = false;
      this.addFieldForm.controls['documentType'].reset();
      this.addFieldForm.controls['maxImagesAllowed'].reset();
    }

    if (this.attributeType.id == 16) {
      this.isVideoUpload = true;
    } else {
      this.isVideoUpload = false;
      this.addFieldForm.controls['maxImagesAllowed'].reset();
    }

    if (this.attributeType.id == 17) {
      this.displayRefDoc = true;
      this.addFieldForm.controls.refDocument.setValidators([Validators.required]);
      this.addFieldForm.controls.refDocument.updateValueAndValidity();
    } else {
      this.displayRefDoc = false;
      this.resetRefDocument();
    }

    if(this.attributeType.id ==13){
      if(this.allEntityTypeList!==undefined && this.allEntityTypeList!==null){
        this.entityTypeList=this.allEntityTypeList;
      }
      this.isDisplayLinkedField=true;
      this.addFieldForm.controls.fieldValue.setValue(2);
      this.addFieldForm.get('entityValue').setValidators([Validators.required]);
      this.addFieldForm.get('entityValue').updateValueAndValidity();
      this.addFieldForm.get('linkedAttributeId').setValidators([Validators.required]);
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
    } else {
      this.isDisplayLinkedField = false;
      this.addFieldForm.controls.fieldValue.setValue(1);
      this.addFieldForm.get('entityValue').reset();
      this.addFieldForm.get('entityValue').clearValidators();
      this.addFieldForm.get('entityValue').updateValueAndValidity();
      this.addFieldForm.get('linkedAttributeId').reset();
      this.addFieldForm.get('linkedAttributeId').clearValidators();
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
      this.addFieldForm.get('selectionType').reset();
      this.addFieldForm.get('selectionType').clearValidators();
      this.addFieldForm.get('selectionType').updateValueAndValidity();
    }

    if (this.attributeType.id === 18) {
      this.isFieldTypeDataList = true;
      this.isDisplayLinkedField = true;
      this.addFieldForm.controls.fieldValue.setValue(2);
      this.addFieldForm.controls.entityValue.setValue(6);
      this.addFieldForm.get('linkedAttributeId').setValidators([Validators.required]);
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
      this.changeEntity(6);
    } else {
      this.isFieldTypeDataList = false;
    }

    if(this.attributeType.id ==19){
      this.flag_Value = true;
      this.showOptionsEdit(this.flag_Value);
      this.addFieldForm.controls.fieldValue.setValue(2);
      this.isDisplayLinkedField=true;
      this.addFieldForm.get('entityValue').setValidators([Validators.required]);
      this.addFieldForm.get('entityValue').updateValueAndValidity();
      this.addFieldForm.get('linkedAttributeId').setValidators([Validators.required]);
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
      if(this.allEntityTypeList!==undefined && this.allEntityTypeList!==null){
        this.entityTypeList=this.allEntityTypeList.filter(ent=>[1,2,3].includes(ent.id));
      }
    }else{
      this.flag_Value = false;
      this.showOptionsEdit(this.flag_Value);
    }

  }

  clickFile() {
    let element: HTMLElement = document.getElementById("fileInput") as HTMLElement;
    element.click();
  }

  getDocumentIcon(fileType) {
    if (fileType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      return "./assets/media/svg/icons/General/file-excel-solid.svg";
    }
    if (fileType.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      return "./assets/media/svg/icons/General/file-alt-solid.svg";
    }
    if (fileType.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
      return "./assets/media/svg/icons/General/file-powerpoint-solid.svg";
    }
    if (fileType.match('application/pdf')) {
      return "./assets/media/svg/icons/General/PDF-File.svg";
    }
  }

  getSlicedErrorFileName(name: string) {
    if (name.length <= 20) {
      return name;
    }
    return ". . . ." + name.slice(-20);
  }

  checkUploadQueue(event) {
    this.fileUploadQueue--;
    if (this.fileUploadQueue == 0) {
      event.target.value = '';
      this.SpinnerService.hide("uploadRefDoc");
      this.disabled = false;
    }
  }

  processFile(event) {
    this.addFieldForm.controls.refDocument.markAsPristine();
    this.addFieldForm.controls.refDocument.markAsUntouched();
    this.disabled = true;
    this.wrongFormat = false;
    this.uploadError = false;
    var imageFilesFromEvent = event.target.files;
    var imageDiv = document.getElementById('selectedImages');
    let imgCount = this.uploadedImageCount;

    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      this.SpinnerService.show("uploadRefDoc");
      let filesAmount = event.target.files.length;
      this.fileUploadQueue = filesAmount;
      for (let i = 0; i < filesAmount; i++) {
        if (imageFilesFromEvent[i].type.match('image')) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
          
            var data = '<div id="removableDiv' + imgCount + '" class="artist-collection-photo"> '
              + '<div class="remove-img-btn"><button id="myImgCont' + imgCount + '"  data-imageindex=' + imgCount + ' class="closeImageButton close removeImgButton" type="button"><img data-imageindex=' + imgCount + ' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
              + '<img width="90" height="100" src="' + event.target.result + '" alt="media" class="img-thumbnail"></div> 	';
          
            imageDiv.innerHTML += data;
          
            let children = document.getElementsByClassName("closeImageButton");
            for (let i = 0; i < children.length; i++) {
              children[i].addEventListener("click", (event: Event) => {
                this.removeImage(event);
              });
            }
            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });
            imgCount++;
            this.uploadedImageCount++;
            this.checkUploadQueue(event);
          }
          this.refDocService.uploadDoc(+this.moduleType, imageFilesFromEvent[i], this.selectedProject).subscribe(res => {

            reader.readAsDataURL(imageFilesFromEvent[i]);
            this.uploadedFiles.push(imageFilesFromEvent[i]);
            this.fileControlValue.push(res['responseData'].name);
            this.addFieldForm.controls.refDocument.setValue(this.fileControlValue);
          },
            error => {
              this.uploadError = true;
              this.uploadErrorFileName = this.getSlicedErrorFileName(imageFilesFromEvent[i].name);
              this.checkUploadQueue(event);
            });
        } else if (imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
          || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')
          || imageFilesFromEvent[i].type.match('application/pdf')) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            let docIcon = this.getDocumentIcon(imageFilesFromEvent[i].type);
            var data = '<div id="removableDiv' + imgCount + '" class="artist-collection-photo"> '
              + '<div class="remove-img-btn"><button id="myImgCont' + imgCount + '"  data-imageindex=' + imgCount + ' class="closeImageButton close removeImgButton" type="button"><img data-imageindex=' + imgCount + ' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
              + '<img width="90" height="100" src=' + docIcon + ' alt="media" class="img-thumbnail"></div> 	';

            imageDiv.innerHTML += data;

            let children = document.getElementsByClassName("close-svg");
            for (let i = 0; i < children.length; i++) {
              children[i].addEventListener("click", (event: Event) => {
                this.removeImage(event);
              });
            }
            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });

            imgCount++;
            this.uploadedImageCount++;
            this.checkUploadQueue(event);
          }
          this.refDocService.uploadDoc(+this.moduleType, imageFilesFromEvent[i], this.selectedProject).subscribe(res => {

            reader.readAsDataURL(imageFilesFromEvent[i]);
            this.uploadedFiles.push(imageFilesFromEvent[i]);
            this.fileControlValue.push(res['responseData'].name);
            this.addFieldForm.controls.refDocument.setValue(this.fileControlValue);
          },
            error => {

              this.uploadError = true;
              this.uploadErrorFileName = this.getSlicedErrorFileName(imageFilesFromEvent[i].name);
              this.checkUploadQueue(event);
            });
        } else {

          this.wrongFormat = true;
          this.checkUploadQueue(event);
        }
          
      }
    }

  }

  removeImage(clickEvent) {
    let id = clickEvent.target.dataset.imageindex;
    
    let removeFile = this.uploadedFileIdObj.find(el => el.id == id);
    if (!removeFile) {
      return;
    }
    let removeIndex = this.uploadedFiles.findIndex(el => el.name == removeFile.name);
    if (removeIndex < 0) {
      return;
    }
    this.uploadedFiles.splice(removeIndex, 1);
    this.fileControlValue.splice(removeIndex, 1);
    this.addFieldForm.controls.refDocument.setValue(this.fileControlValue);
    
    // remove images from ui
    var imageDiv = document.getElementById('selectedImages');
    imageDiv.innerHTML = "";
    this.uploadedFileIdObj = [];
    this.uploadedImageCount = 0;

    var imageFilesFromEvent = this.uploadedFiles;
    let imgCount = this.uploadedImageCount;

    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      let filesAmount = this.uploadedFiles.length;
      for (let i = 0; i < filesAmount; i++) {
        if (imageFilesFromEvent[i].type.match('image')) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
          
            var data = '<div id="removableDiv' + imgCount + '" class="artist-collection-photo"> '
              + '<div class="remove-img-btn"><button id="myImgCont' + imgCount + '"  data-imageindex=' + imgCount + ' class="closeImageButton close removeImgButton" type="button"><img data-imageindex=' + imgCount + ' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
              + '<img width="90" height="100" src="' + event.target.result + '" alt="media" class="img-thumbnail"></div> 	';
          
            imageDiv.innerHTML += data;
          
            let children = document.getElementsByClassName("close-svg");
            for (let i = 0; i < children.length; i++) {
              children[i].addEventListener("click", (event: Event) => {
                this.removeImage(event);
              });
            }
            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });
            imgCount++;
          }
          reader.readAsDataURL(imageFilesFromEvent[i]);
          this.uploadedImageCount++;
        } else if (imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
          || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')
          || imageFilesFromEvent[i].type.match('application/pdf')) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            let docIcon = this.getDocumentIcon(imageFilesFromEvent[i].type);
            var data = '<div id="removableDiv' + imgCount + '" class="artist-collection-photo"> '
              + '<div class="remove-img-btn"><button id="myImgCont' + imgCount + '"  data-imageindex=' + imgCount + ' class="closeImageButton close removeImgButton" type="button"><img data-imageindex=' + imgCount + ' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
              + '<img width="90" height="100" src=' + docIcon + ' alt="media" class="img-thumbnail"></div> 	';

            imageDiv.innerHTML += data;

            let children = document.getElementsByClassName("closeImageButton");
            for (let i = 0; i < children.length; i++) {
              children[i].addEventListener("click", (event: Event) => {
                this.removeImage(event);
              });
            }
            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });

            imgCount++;

          }
          reader.readAsDataURL(imageFilesFromEvent[i]);
          this.uploadedImageCount++;
        }
      }
    }
  }
  
  getDocumentTypes() {
    this.documentTypes = [{ id: '1', name: 'PDF' }, { id: '2', name: 'Word' }, { id: '3', name: 'Excel' }, { id: '4', name: 'Power Point' }];
  }

  isDisplayStandard(): boolean {
    if (this.addFieldForm.controls.dateValueType.value == 'Standard') {
      this.addFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    } else {
      this.addFieldForm.controls.standardTypeValue.clearValidators();
      this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  resetRefDocument() {
    this.addFieldForm.controls.refDocument.clearValidators();
    this.addFieldForm.controls.refDocument.setValue('');
    this.addFieldForm.controls.refDocument.updateValueAndValidity();
    this.uploadedFiles = [];
    this.fileControlValue = [];
    this.uploadedFileIdObj = [];
    this.uploadedImageCount = 0;
    this.wrongFormat = false;
    this.uploadError = false;
    this.displayRefDoc = false;
  }

  isDisplayType(): boolean {
    this.addFieldForm.controls.attributeType.setValidators([Validators.required]);
    this.addFieldForm.controls.attributeType.updateValueAndValidity();
    return true;
  }
  
  isDisplayexistings(): boolean {
    if (Number(this.moduleType) > 100) {
      if (this.addFieldForm.controls.fieldValue.value == '1') {
        this.displayDateValueDiv = false;
        this.addFieldForm.controls.dateValueType.clearValidators();
        this.addFieldForm.controls.dateValueType.updateValueAndValidity();
        this.resetDateValue();
        this.displayMultiSelectDiv = false;
        this.addFieldForm.controls.attributeMultiSelectName.clearValidators();
        this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
        this.displaySingleSelectDiv = false;
        this.addFieldForm.controls.attributeSingleName.clearValidators();
        this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
        this.isDisplayText = false;
        this.isDisplayImage = false;
        this.isDisplayNumeric = false;
        this.addFieldForm.controls['numericValidationType'].reset();
        this.addFieldForm.controls['attributeType'].reset();
        this.isDisplayEmail = false;
        this.isDocumentUpload = false;
        this.addFieldForm.controls['documentType'].reset();
        this.isVideoUpload = false;
        return true;
      } else {
        this.addFieldForm.controls['entityValue'].reset();
        this.addFieldForm.controls['linkedAttributeId'].reset();
        this.isDisplaySelection = false;
        this.isProductFormSelected = false;
      }
    } else {
      return false;
    }
  }

  isDisplayCustom(): boolean {
    if (this.addFieldForm.controls.dateValueType.value == 'Custom') {
      return true;
    } else {
      return false;
    }
  }

  changeValidation(data) {
    this.numericValidationType.id = data;
  }

  changeEntity(data) {
    if (data == 6)
      this.isDataList = true;
    else
      this.isDataList = false;
      
    this.clearDataListRelatedFieldsAndControls();
    this.addFieldForm.controls.linkedAttributeId.setValue(null);

    this.getCustomFields(data);
    this.isProductFormSelected = false;

    if (data == 200) {
      this.selectForm = false;
      this.getselectionTypeList();
      this.isDisplaySelection = true;
      this.productFormFlag = true;
      this.addFieldForm.controls.selectionType.setValidators([Validators.required]);
      this.addFieldForm.controls.selectionType.updateValueAndValidity();
      this.addFieldForm.get('linkedAttributeId').clearValidators();
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
    } else {
      this.selectForm = true;
      this.isDisplaySelection = false;
      this.productFormFlag = false;
      this.addFieldForm.controls.selectionType.reset();
      this.addFieldForm.controls.selectionType.clearValidators();
      this.addFieldForm.controls.selectionType.updateValueAndValidity();
      this.addFieldForm.get('linkedAttributeId').setValidators([Validators.required]);
      this.addFieldForm.get('linkedAttributeId').updateValueAndValidity();
    }
  }

  clearDataListRelatedFieldsAndControls() {
    this.lookupVal = [];
    this.showParentValueDD = false;
    this.showDependentFieldsDD(false);
    this.parentLookupValues = [];
    this.showEntityFieldLV = false;
    this.selctedEntityFieldLV = [];
    this.dependentFieldList = [];
    this.dependentcheckBox = false;
    this.showDependentFieldCB = false;
    this.addFieldForm.controls.selectValues.setValue("");
    this.addFieldForm.controls.selectDependentFields.setValue("");
    this.addFieldForm.controls.selectParentFieldValues.setValue("");
  }

  getDataListLookupValuesForParent(attribId: number) {
    this._field.getDataListLookupValues(attribId).subscribe(
      (row: any) => { 
        if(row['statusCode']==200){
        const responseData = row['responseData']; // Retrieve responseData from the row object
            this.selectedEntityField.lookupValues = responseData.lookupValues; // update lookupValues directly
            this.selectedEntityField.parentLookupValues = responseData.parentLookupValues;
            this.selectedEntityField.parentRelationList = responseData.parentRelationList;
            this.selectedEntityField.selectedDependentFields = responseData.selectedDependentFields;
            this.selectedEntityField.selectedParentFiledValues = responseData.selectedParentFiledValues;
        this.dataListSetup();
      }
    },
      (error) => {
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  changeEntityField(data) {
    this.clearDataListRelatedFieldsAndControls();
    this.selectedEntityField = this.entityfieldList.find(field => field.id === data);
    this.getDataListLookupValuesForParent(data);
  }
   
  dataListSetup(){
    if (this.isDataList) {
      if (this.selectedEntityField.childAttributeList == null && this.selectedEntityField.parentLookupValues != null) {
        this.showParentValueDD = true;
        this.parentLookupValues = this.selectedEntityField.parentLookupValues;
      }
      this.showEntityFieldLV = true;
      this.selctedEntityFieldLV = this.selectedEntityField.lookupValues;
  
      if (this.selectedEntityField.childAttributeList != null && this.selectedEntityField.parentLookupValues != null) {
        this.showDependentFieldCB = true;
        this.dependentFieldList = this.selectedEntityField.childAttributeList.filter(x => x.status == 1);
        this.showParentValueDD = true;
        this.parentLookupValues = this.selectedEntityField.parentLookupValues;
      }
  
      if (this.selectedEntityField.childAttributeList != null) {
        this.showDependentFieldCB = true;
        this.dependentFieldList = this.selectedEntityField.childAttributeList.filter(x => x.status == 1);
      }
    }
  
  }

  changeparentFieldvalues(value) {
    if (value.length > 0) {
      let relationIds = this.selectedEntityField.parentRelationList
        .filter(x => (value as Array<any>).includes(x.parentLookupValueId))
        .map(y => y.childLookupValueId);
      this.showEntityFieldLV = true;
      this.selctedEntityFieldLV = this.selectedEntityField.lookupValues.filter(x => relationIds.includes(x.id));
    } else {
      this.selctedEntityFieldLV = this.selectedEntityField.lookupValues;
    }
    this.showEntityFieldLV = true;
  }

  previousFormcheckboxChanged(event) {
    this.isPreviousFormcheckboxSelected = event.checked;

    const previousFormControl = this.addFieldForm.get('previousForm');
    if (event.checked) {
      this.getPreviousSubmittedFormDetails();
      previousFormControl.setValidators([Validators.required]);
    }
    else {
      previousFormControl.reset();
      previousFormControl.clearValidators();
    }
    previousFormControl.updateValueAndValidity();

  }

  getPreviousSubmittedFormDetails(){
    this.SpinnerService.show('add-field');
    this._field.getPreviousSubmittedFormDetails(this.selectedProject, +this.moduleType, this.addFieldForm.controls.selectionType.value.id).subscribe(
      res => {
        this.optionsForActivity = res['responseData']
        this.SpinnerService.hide('add-field');
      }, error => {
        this.SpinnerService.hide('add-field');
      });
  }

  //  Selection Change for Product Quantity Transfer Section
  onCheckboxChange(event) {
    const customerTransferControl = this.addFieldForm.get('customerTransfer');
    if(event.checked){
      customerTransferControl.setValidators([Validators.required]);
    } 
    else {
      customerTransferControl.reset();
      customerTransferControl.clearValidators();
      const entityTransferControl = this.addFieldForm.get('entityTransfer');
      entityTransferControl.reset();
      const entityFieldTransferControl = this.addFieldForm.get('entityFieldTransfer');
      entityFieldTransferControl.reset();
      entityFieldTransferControl.clearValidators();
      entityFieldTransferControl.updateValueAndValidity();
      const entityFieldValueTransferControl = this.addFieldForm.get('entityFieldValueTransfer');
      entityFieldValueTransferControl.reset();
      const showValuesControl = this.addFieldForm.get('showValues');
      showValuesControl.reset();
    }
    customerTransferControl.updateValueAndValidity();
  }

  changeEntityForQuantityTransfer(moduleType: number) {
    this.valuesForQuantityTransfer = [];
    this.getEntityFields(moduleType);

    //For User entity only 'Show Values' option made mandatory
    const showValuesControl = this.addFieldForm.get('showValues');
    if(moduleType == 5) {
      showValuesControl.setValidators([Validators.required]);
    }
    else {
      showValuesControl.clearValidators();
    }
    const entityTransferControl = this.addFieldForm.get('entityTransfer');
    entityTransferControl.setValidators([Validators.required]);
    entityTransferControl.updateValueAndValidity();
    const entityFieldTransferControl = this.addFieldForm.get('entityFieldTransfer');
    entityFieldTransferControl.setValidators([Validators.required]);
    entityFieldTransferControl.updateValueAndValidity();
    const entityFieldValueTransferControl = this.addFieldForm.get('entityFieldValueTransfer');
    entityFieldValueTransferControl.setValidators([Validators.required]);
    entityFieldValueTransferControl.updateValueAndValidity();
    showValuesControl.updateValueAndValidity();
  }

  changeEntityFieldForQuantityTransfer(fieldData) {
    const entityFieldTransferControl = this.addFieldForm.get('entityFieldTransfer');
    entityFieldTransferControl.setValidators([Validators.required]);
    entityFieldTransferControl.updateValueAndValidity();
    this.valuesForQuantityTransfer = [];
    if(fieldData.systemAttribute?.id == 34 || fieldData.systemAttribute?.id == 58) {
      //Owner, Reports To
      this.SpinnerService.show('add-field');
      this._activityService.getUserDownHierarchy(this.selectedProject).subscribe(res => {
        res['responseData'].forEach(element => {
          this.valuesForQuantityTransfer.push({
            id: element.id,
            name: element.fullName
          })
        });
        this.SpinnerService.hide('add-field');
      }, error => {
        this.SpinnerService.hide('add-field');
      });
    }
    else if(fieldData.systemAttribute?.id == 57) {
      //User Role
      this.SpinnerService.show('add-field');
      this._activityService.getActiveRoles(this.selectedProject).subscribe(res => {
        res['responseData'].forEach(element => {
          this.valuesForQuantityTransfer.push({
            id: element.roleId,
            name: element.roleName
          })
        });
        this.SpinnerService.hide('add-field');
      }, error => {
        this.SpinnerService.hide('add-field');
      });
    }
    else {
      fieldData.lookupValues.forEach(element => {
        this.valuesForQuantityTransfer.push({
          id: element.id,
          name: element.lookupValue
        })
      });
    }
  }

  /** For  Select All in Multiselect */
  
  toggleAllSelection(matSelect: MatSelect, ngModelValue: boolean) {
    if (ngModelValue) {
      matSelect.options.forEach((item: MatOption) => { (item.value !== undefined && item.value !== null) ? item.select() : null });
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick(matSelect: MatSelect, ngModelName: string) {
    let newStatus = true;
    matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this[ngModelName] = newStatus;
  }

  showDependentFieldsDD(checked) {
    this.showDependentFieldDD = checked;
    this.dependentcheckBox = checked;

  }

  isDisplayNumericValue(): boolean {
    if (this.addFieldForm.controls.numericValidationType.value == '2') {
      return true;
    } else {
      return false;
    }
  }

  isDisplayNumericDigit(): boolean {
    if (this.addFieldForm.controls.numericValidationType.value == '1') {
      return true;
    } else {
      return false;
    }
  }

  showOptions(val) {
    this.allowBrowseVal = val;
  }

  showOptionsUnique(val) {
    this.uniqueVal = val;
  }

  showOptionsEdit(val: boolean) {
    this.mandatoryContact = val;
  }

  allowDecimalsCB(val) {
    this.decimalFlag = val;
    if(val) {
      this.otpValidationCheck = false;
    } else {
      this.otpValidationCheck = true;
      const minVal: String = String(this.addFieldForm.get('numericMinValue').value);
      this.addFieldForm.get('numericMinValue').setValue(this.extractWholeNumber(minVal));
      const maxVal: String = String(this.addFieldForm.get('numericMaxValue').value);
      this.addFieldForm.get('numericMaxValue').setValue(this.extractWholeNumber(maxVal));
    }
  }

  allowRgxValidationCB(event) {
    this.addValidationFieldFlag = event.checked;
    const enterRgxControl = this.addFieldForm.get('enterRgx');
    const rgxErrControl = this.addFieldForm.get('rgxErrorMessage');
    if (enterRgxControl) {
      if (event.checked) {
        enterRgxControl.setValidators([Validators.required, this._field.regexValidator()]);
        rgxErrControl.setValidators([Validators.required]);
        this.enableRegexField = true;
      } else {
        enterRgxControl.clearValidators();
        rgxErrControl.clearValidators();
        this.enableRegexField = false;
      }
    }
    enterRgxControl.updateValueAndValidity();
    rgxErrControl.updateValueAndValidity();
  }
  
  extractWholeNumber(val: String): number {
    if(val!=='' && val!==null) {
      val = val.replace(/\.\d*/, '');
      if(val!=='' && val!==null && val.match(/^\d*$/) !==null) {
        return Number(val);
      }
    }
    return null;
  }

  showOptionsOtpValid(val) {
    this.otpValidationCheck=val;
  }

  resetDateValue(){
    this.addFieldForm.controls['dateValueType'].reset();
  }

  OpenAlert(message) {
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: { top: '20px' },
      data: { title: 'Alert', body: initialNotice }
    });
    
  }

  Close() {
    this.activeModal.close();
    this.addFieldForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addFieldForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate = false;

    
    if ((value || '').trim()) {
      for (let fr of this.lookupVal) {
        var name = fr.lookupValue;
        if (value.trim().toLowerCase() == name.toLowerCase()) {
          this.duplicate = true;
        }
      }
      if (this.duplicate == false) {
        this.lookupVal.push({
          lookupValue: value.trim(),
          isActive: 1, stageId: null
        });
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup: LooupValues): void {
    const index = this.lookupVal.indexOf(lookup);

    if (index >= 0) {
      this.lookupVal.splice(index, 1);
    } 
    if (index == 0) {
      this.addFieldForm.controls['attributeSingleName'].reset();
      this.addFieldForm.controls['attributeMultiSelectName'].reset();
    }
  }

  resetAll() {
    this.addFieldForm.controls['mandatoryCheck'].reset();
    this.addFieldForm.reset();
    this.lookupVal = [];
    this.addFieldForm.controls['attributeMultiSelectName'].reset();
    this.addFieldForm.controls['attributeSingleName'].reset();
    this.disabled = false;
    this.displayDateValueDiv = false;
    this.addFieldForm.controls.dateValueType.clearValidators();
    this.addFieldForm.controls.dateValueType.updateValueAndValidity();
    this.resetDateValue();
    this.displayMultiSelectDiv = false;
    this.addFieldForm.controls.attributeMultiSelectName.clearValidators();
    this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    this.displaySingleSelectDiv = false;
    this.addFieldForm.controls.attributeSingleName.clearValidators();
    this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
    this.isDisplayStandard();
    this.isDisplayCustom();
  }

}
