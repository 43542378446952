// Angular
import { AfterViewInit, ChangeDetectorRef , Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
// RxJS
import { Subject, Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';

export interface UserOption {
  value: string;
  viewValue: string;
  icon: any;
  tabType: number
}

@Component({
  selector: 'kt-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss']
})

export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {


  // Public properties
  @Input() fixed = true;
  @Input() clear = false;
  @Input() width = 'fluid';
  @Input() subheaderClasses = '';
  @Input() subheaderContainerClasses = '';
  @Input() displayDesc = false;
  @Input() displayDaterangepicker = true;

  preSelectedDates: {startDate: Moment, endDate: Moment};
  preSelectedDatesForDetailedPoints: {startDate: Moment, endDate: Moment};
  preSelectedDatesForAct: {startDate: Moment, endDate: Moment};
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  rangePickerMaxDate;
  today: number = Date.now();
  title = '';
  moduleType:number;
  desc = '';
  singularName='';
  pruralName='';
  rightTabView=false;
  filterModalView=false;
  filterApplied=false;
  dailyActivityDatePicker=false;
  detailedPointsDatePicker=false;
  activitiesDatePicker=false;
  teamFlag=false;
  girdViewFlag=true;
  viewMode:number=1;
  selected2:any;
  selectedOpporunityView: any;
  dashBoardIconView=false;

  breadcrumbs: Breadcrumb[] = [];
  selected: {year: number, month: number, day: number};
  maxDate:any;
  minDate:any;
  
  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    public subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef) {
    this.preSelectedDates = {
      startDate: moment().startOf('month'),
      endDate: moment()
    };
    this.preSelectedDatesForDetailedPoints = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    };
    this.preSelectedDatesForAct = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    };
      this.rangePickerMaxDate=moment();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const today = new Date();
    this.selected2 = {value: 'self', icon: 'person', viewValue: 'Self'};
    this.selectedOpporunityView = {value: 'grid', icon: 'view_module', viewValue: 'Grid View'}
    this.changeOpportunityView(this.selectedOpporunityView);
    this.selected = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    }
    this.applyFilter();
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
      // breadcrumbs title sometimes can be undefined
      if (bt) {
        Promise.resolve(null).then(() => {
          this.title = bt.title;
          this.desc = bt.desc;
          this.singularName=bt.singularName;
          this.pruralName=bt.pruralName;
        });
      }
    }));

    this.subscriptions.push(this.subheaderService.moduleType$.subscribe(mt =>{
      if(mt){
        Promise.resolve(null).then(()=>{
          this.moduleType=mt;
        })
      }
    }))
    this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
        
      });
    }));

//For customer and activities
    this.subscriptions.push(this.subheaderService.rightTabCustomerActivity$.subscribe(rt => {
      Promise.resolve(null).then(() => {
        this.rightTabView = rt.viewFlag;
        this.viewMode = rt.viewMode;
        if(this.viewMode==1)
          this.selected2 = {value: 'self', icon: 'person', viewValue: 'Self', tabType:1};
        else if(this.viewMode==2)
          this.selected2 = {value: 'team', icon: 'group', viewValue: 'Team', tabType:2};
        else if(this.viewMode==3)
          this.selected2 = {value: 'all', icon: 'groups', viewValue: 'All', tabType:3};
        else
          this.selected2 = {value: 'unassigned', icon: 'person_search', viewValue: 'Unassigned', tabType:3};
      });
      if(rt.tabList){
        this.selectedUserOptionsCustomer = this.userOptionsCustomer.filter(x => rt.tabList.includes(x.tabType));
        this.selectedUserOptionsActivities = this.userOptionsActivities.filter(x => rt.tabList.includes(x.tabType));
      }
    }));

    this.subscriptions.push(this.subheaderService.rightTab$.subscribe(rt => {
      Promise.resolve(null).then(() => {
        this.rightTabView = rt.viewFlag;
        this.viewMode = rt.viewMode;
        if(this.viewMode==1)
          this.selected2 = {value: 'self', icon: 'person', viewValue: 'Self', tabType:1};
        else if(this.viewMode==2)
          this.selected2 = {value: 'team', icon: 'group', viewValue: 'Team', tabType:2};
        else 
          this.selected2 = {value: 'all', icon: 'groups', viewValue: 'All', tabType:3};
      });
      if(rt.tabList) {
        this.selectedUserOptions = this.userOptions.filter(x => rt.tabList.includes(x.tabType));
      }
    }));
    this.subscriptions.push(this.subheaderService.gridView$.subscribe(gvFlag => {
      Promise.resolve(null).then(() => {
        this.girdViewFlag = gvFlag;
        if(this.girdViewFlag){
          this.selectedOpporunityView = {value: 'grid', icon: 'view_module', viewValue: 'Grid View', tabType:0};
        }
        else{
          this.selectedOpporunityView = {value: 'list', icon: 'view_list', viewValue: 'List View', tabType:0};
        }
      })
    }))

    this.subscriptions.push(this.subheaderService.filterButton$.subscribe( fb=> {
      Promise.resolve(null).then(() => {
        this.filterModalView  = fb.showFilter;    
        this.filterApplied = fb.filterApplied;
      });  
      this.cdr.markForCheck();
    }));


    this.subscriptions.push(this.subheaderService.dashBoardIcon$.subscribe( db=> {
      Promise.resolve(null).then(() => { console.log(db);
        this.dashBoardIconView  = db.showIcons;    
      });  
      // this.cdr.markForCheck();
    }));

    this.subscriptions.push(this.subheaderService.dailyActivityDateRangePicker$.subscribe( val=> {
      Promise.resolve(null).then(() => {console.log(val);
        this.dailyActivityDatePicker  = val;
        if(this.dailyActivityDatePicker===true) {
          this.preSelectedDates = {
            startDate: moment().startOf('month'),
            endDate: moment()
          };
        }
      });
    }));

    this.subscriptions.push(this.subheaderService.detailedPointsDateRangePicker$.subscribe( val=> {
      Promise.resolve(null).then(() => {console.log(val);
        this.detailedPointsDatePicker  = val;
        if(this.detailedPointsDatePicker===true) {
          this.preSelectedDatesForDetailedPoints = {
            startDate: moment().startOf('month'),
            endDate: moment()
          };
        }
      });
    }));

    this.subscriptions.push(this.subheaderService.activitiesDateRangePicker$.subscribe( val=> {
      Promise.resolve(null).then(() => {console.log(val);
        this.activitiesDatePicker  = val;
        if(this.activitiesDatePicker===true) {
          this.preSelectedDatesForAct = {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
          };
        }
      });
    }));

    console.log("View : "+ this.rightTabView +", Team Flag : "+ this.teamFlag);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  /**
   * User Select Dropdown
   */
  userOptionsCustomer: UserOption[] = [
    {value: 'self', icon: 'person', viewValue: 'Self', tabType:1},
    {value: 'team', icon: 'group', viewValue: 'Team', tabType:2},
    {value: 'all', icon: 'groups', viewValue: 'All', tabType:3},
    {value: 'unassigned', icon: 'person_search', viewValue: 'Unassigned', tabType:3}

  ];

  selectedUserOptionsCustomer: UserOption[] = this.userOptionsCustomer;

  userOptionsActivities: UserOption[] = [
    {value: 'self', icon: 'person', viewValue: 'Self', tabType:1},
    {value: 'team', icon: 'group', viewValue: 'Team', tabType:2},
    {value: 'all', icon: 'groups', viewValue: 'All', tabType:3},
  ];

  selectedUserOptionsActivities: UserOption[] = this.userOptionsActivities;

  userOptions: UserOption[] = [
    {value: 'self', icon: 'person', viewValue: 'Self', tabType:1},
    {value: 'team', icon: 'group', viewValue: 'Team', tabType:2},
    {value: 'all', icon: 'groups', viewValue: 'All', tabType:3}
  ];

  selectedUserOptions: UserOption[] = this.userOptions;
  opportunityViewOptions: UserOption[] = [
    {value: 'list', icon: 'view_list', viewValue: 'List View', tabType:0},
    {value: 'grid', icon: 'view_module', viewValue: 'Grid View', tabType:0}
  ];

  compareFn(f1: UserOption, f2: UserOption): boolean {
    return f1 && f2? f1.value === f2.value : f1 === f2;
  }

  changeUserCustomerActivity(selectedVal: any){
    console.log(selectedVal);
    if(selectedVal.value == 'team')
      this.subheaderService.setRightTabConfigCustomerActivity(true, 2, null);
    else if(selectedVal.value=="self")
      this.subheaderService.setRightTabConfigCustomerActivity(true, 1, null);
    else if(selectedVal.value=="all")
      this.subheaderService.setRightTabConfigCustomerActivity(true, 3, null);
    else
      this.subheaderService.setRightTabConfigCustomerActivity(true, 4, null);
  }

  changeUser(selectedVal: any){
    console.log(selectedVal);
    if(selectedVal.value == 'team')
      this.subheaderService.setRightTabConfig(true, 2, null);
    else if(selectedVal.value=="self")
      this.subheaderService.setRightTabConfig(true, 1, null);
    else 
      this.subheaderService.setRightTabConfig(true, 3, null);
  }
  changeOpportunityView(selectedVal: any){
    if(selectedVal.value == 'grid'){
      this.subheaderService.setGridView(true);
    }
    else{
      this.subheaderService.setGridView(false);
    }
  }

  openFilterModal(){
    console.log("Opening modal"); 
    this.subheaderService.openFilterModal();  
  }
  selectedStartDate(event) {
    console.log(event.startDate.$d);
    
    this.maxDate = moment(event.startDate.$d).month();
    console.log(moment(event.startDate.$d).daysInMonth());
    localStorage.setItem('totalDays',(moment(event.startDate.$d).daysInMonth()).toString());
    localStorage.setItem('firstDay',moment(event.startDate.$d).startOf('month').format('dddd'));

    console.log(moment(event.startDate.$d).startOf('month').format('dddd'));

  }
  selectedEndDate(event) {
    console.log(event.endDate.$d);
    
    this.minDate = moment(event.endDate.$d).month();
    
  }
  applyFilter(){
    this.subheaderService.emitDateRangeChanged(moment(this.formatAsStartDate()).format('YYYY-MM-DD'), moment(this.formatAsEndDate()).format('YYYY-MM-DD'));
  }

  private formatAsStartDate() {
    return `${this.selected.year}-${this.selected.month}-01`;
  }
  private formatAsEndDate() {
    return moment(this.formatAsStartDate()).endOf('month').toDate();

  }

  addDashBoard(){
    this.subheaderService.addDashBoard(); 
  }

  addDisplayOrder(){
    this.subheaderService.addDisplayOrder(); 
  }

  ngModelChangeDateRangePicker(e) {
    this.subheaderService.applyDateFilter(this.preSelectedDates.startDate.format("YYYY-MM-DD"), this.preSelectedDates.endDate.format("YYYY-MM-DD"));
  }
  
  ngModelChangeDateRangePickerForDetailedPoints(e) {
    this.subheaderService.applyDateFilterForDetailedPoints(this.preSelectedDatesForDetailedPoints.startDate.format("YYYY-MM-DD"), this.preSelectedDatesForDetailedPoints.endDate.format("YYYY-MM-DD"));
  }

  ngModelChangeDateRangePickerForAct(e) {
    this.subheaderService.applyDateFilterForAct(this.preSelectedDatesForAct.startDate.format("YYYY-MM-DD"), this.preSelectedDatesForAct.endDate.format("YYYY-MM-DD"));
  }

  openDateRangePicker(e) {
    this.pickerDirective.open(e);
  }

  dayPlannerBulkUpload() {
    this.subheaderService.dayPlannerBulkUpload();
  }
}
