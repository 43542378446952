<div class="card card-custom" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-4 pr-5">
        
        <div class="card-title text-primary font-weight-bold font-size-h6"> 
            <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="activity" [ngStyle]="{'cursor':'pointer'}">
                <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
                <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
            </a>
            Activities
        </div>

        <!-- <div class="card-title text-primary font-weight-bold font-size-h6"> Activities </div> -->
        <div class="card-toolbar">
            <span *ngIf="callDetail?.ivrCall>0">
                <img *ngIf="callDetail?.callerIdNumber?.length>0" src="./assets/media/svg/icons/Communication/call-icon.svg" title="Call" (click)="call(callDetail)">
                <img *ngIf="callDetail?.callerIdNumber?.length==0" src="./assets/media/svg/icons/Communication/call-icon-disabled.svg" title="{{callDetail.callerIdFieldName+ ' not available'}}">
                &nbsp;
            </span>
            <div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" *ngIf ="!dialerFlag" title="Add Activity">
                <a ngbDropdownToggle 
                    class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary"
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false">
                    <i class="fas fa-plus"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                    <!--<kt-add-activity [activityModule]="moduleString" [companyId]="parentData"></kt-add-activity>-->
                    
                    <div class="act-add-list">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Activity</h4>
                        </div>
                        <div class="modal-body">
                            <ng-container>
                                <div class="menu-submenu menu-submenu-classic menu-submenu-left" >
                                    <span class="menu-arrow menu-arrow-adjust"></span>
                            
                                    <ul  class="menu-subnav" *ngFor="let activity of rowData">
                                        <li class="menu-item" >
                                            <a class="menu-link pl-0" (click)="AddActivity(activity.activityId)" >
                                                <span  class="menu-text"> {{ activity.activityName}} </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>  
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <!-- Body -->
    <div id="activity" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 activity-body-scroll">
    <div class="card-body p-5 activity-scroll">
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <div class="timeline timeline-3">
            <div class="timeline-items">
                <!--begin::Item-->
                <div class="timeline-item" *ngFor="let activity of activityList">
                    <!--begin::Icon-->
                <div class="timeline-media {{activity.borderColor}} {{activity.bgLightClass}}" >
                        <span class="svg-icon svg-icon-primary {{activity.textClass}}">
                            {{activity.activityDoneByInitials}}
                        </span>
                    </div>
                    <!--end::Icon-->
        
                    <!--begin::Info-->
                    <div class="timeline-info h-100">
                        <table class="table table-borderless table-vertical-center">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        <div class="view-activity font-weight-bold font-size-lg {{activity.textClass}}" (click)="viewActivity(activity)"> {{activity.name}}-{{activity.serialNo}} </div>
                                        <div class="timeline-label text-muted font-size-sm">{{activity.created}}</div>
                                        <div>
                                            <div style="height: 1.5rem; display: flex;" *ngFor="let pf of activity.productFormData">
                                                <img style="margin-right: 0.5rem;" src="./assets/media/svg/icons/General/cart.svg" class="pl-1 pr-1 svg-alignment">
                                                <p>{{pf}}</p>
                                            </div>
                                        </div>
                                       
                                    </td>
                                    <td class="text-right p-0" width="15%">
                                        <span class="label label-lg {{activity.lableLight}} label-inline" *ngIf="(activity.status != 'N/A')">
                                            <i class="fas {{activity.iconClass}} mr-2"></i>
                                             {{activity.winProbability}}%
                                        </span>
                                    </td>
                                    <td class="text-right p-0" width="20%">
                                        <span class="label label-lg {{activity.statusLable}} label-inline" *ngIf="(activity.status != 'N/A')">{{activity.status}}</span>
                                    </td>
                                    <td *ngIf="activity.isAttachment" class="text-right p-0" style="cursor: pointer;">
                                        <img *ngIf="true" src="./assets/media/svg/icons/General/download.svg" class="pl-1 pr-1 svg-alignment" (click) = "downloadZip(activity)">
                                    </td>
                                    <td class="text-right p-0" width="6%">
                                        <div *ngIf="(activity.activityTypeName === 'Estimate' || activityTypeName === 'Order')">
                                            <!--<a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)">
                                                <i class="fas fa-download"></i>
                                            </a>-->
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="p-0">
                                        <div *ngIf="(activity.activityTypeName === 'Estimate' || activity.activityTypeName === 'Order')">
                                            <img src="./assets/media/svg/icons/General/money_2.svg" class="pr-2"> 
                                                {{activity.estimatedRevenue}}
                                        </div>
                                        <div class="font-weight-normal text-dark-50" *ngIf="(activity.description != null)">
                                            {{activity.description}}
                                        </div>
                                        <div class="d-flex flex-column flex-grow-1">
                                            <span class="text-muted font-size-sm pt-2">
                                                <!--<img src="./assets/media/svg/icons/General/people_outline_24px.svg" class="pl-3 pr-4">-->
                                                <span class="label label-light-primary mr-2" *ngFor="let contact of activity.contacts" ngbTooltip="{{contact.name}}">
                                                    {{contact.initials}}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="symbol-list d-flex flex-wrap mt-2" *ngIf="activity.displayImage">
                                            <div class="symbol mr-2" *ngFor="let image of activity.images">
                                                <img alt="Pic" src="{{image}}"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--end::Info-->
                </div>
                <!--end::Item-->
            </div>
        </div>
        <!--end: Items-->
    </div>
</div> 
</div>
<ngx-spinner size="default" type="ball-spin-clockwise" name="dashboard-activity">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
<ngx-spinner size="default" type="ball-spin-clockwise" name="download-report">  
    <p style="color: white">Downloading.... </p>  
</ngx-spinner>