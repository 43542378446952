import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { opportunitiesModel } from '../../opportunities/models/opportunities.model';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';
import { contactTypeModel } from '../models/contactType.model';
import { contactsModel } from '../models/contacts.model';
import { companiesModel } from '../../company-management/models/companies.model';
@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { }

  getAllContactDetails(projectId: number){ 
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getContactLookup?projectId='+ projectId);
     // environment.baseUrl + '/transactions/api/contact/getContactLookupSelfOrTeam?projectId='+ projectId);
  }
  getAllContactDetailsForSelfAndTeam(projectId: number,moduleType:Number){ 
    return this.http.get<contactsModel[]>(
     environment.baseUrl + '/transactions/api/contact/getContactLookup?projectId='+ projectId+'&moduleType='+moduleType);

  }

  getUserContacts(projectId: number){ 
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getCustomerContacts?projectId='+ projectId);

  }

  getCustomerContact(projectId: number,companyId:string|number){ 
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getCustomerContacts?projectId='+ projectId+'&companyId='+companyId);

  }

  addEditContact(data){
    data.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<contactsModel>(
      environment.baseUrl + '/transactions/api/contact/addEditContact',data)
  }

  getAllContactType(projectId: number){ 
    return this.http.post<contactTypeModel[]>(
      environment.baseUrl + '/contact/getAllContactType',{"projectId": projectId})
  }
   
  getAllCompanyDetails(projectId: number){ 
    return this.http.post<any[]>(
      environment.baseUrl + '/transactions/api/company/getAllCompanyDetails',{"projectId": projectId})
  }

  getContactFilter(projectId:number, contactView:number){
    return this.http.post(
      environment.baseUrl + '/transactions/api/contact/getContactLookupSelfOrTeam?projectId='+projectId+'&contactView='+contactView,null);
     
}

getCompany(projectId:number){
  return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId);
}
getCompanyForSelfAndTeam(projectId:number,moduleType:Number,teamFlag:boolean=false,projectFlag:boolean=false){
  return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId+'&moduleType='+moduleType+'&teamFlag='+teamFlag+'&projectFlag='+projectFlag);
}

getCompanyIdName(projectId:number,moduleType:Number,customerView:number=3){
  return this.http.get<contactsModel>(
    environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId='+projectId+'&moduleType='+moduleType+'&customerView='+customerView);
}

getFields(moduleType, projectId: number) {
  return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType)
}

  //Loopkup
  getAttribLookup(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getAttribLookupValues?attributeId=' + attributeId);
  }

}
