import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatAll } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AttributeType } from '../../contact-setting/models/attribute-type.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { DateAttributes } from '../../contact-setting/models/date-attributes.model';
import { LooupValues } from '../../contact-setting/models/looup-values.model';
import { UserLocalizationService } from './UserLocalization.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegExpParser, parseRegExpLiteral } from 'regexpp';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(private http: HttpClient,private userLocalization:UserLocalizationService) { }

  getFields(moduleType, projectId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/transactions/api/customType/getFields?projectId=' + projectId + '&moduleType=' + moduleType);
  }
 

  getFieldsInSetting(moduleType, projectId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType);
  }

  //Loopkup
  getAttribLookup(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getAttribLookupValues?attributeId=' + attributeId);
  }

  //CustomDateAttribute
  getCustomDateAttribute(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getCustomDateAttributes?attributeId=' + attributeId);
  }

  //CustomDateAttribute
  getDataListLookupValues(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getDataListLookupValues?attributeId=' + attributeId);
  }

  addFields(saveAttributeData:any){
      saveAttributeData.userTime=this.userLocalization.getUserTime();
      saveAttributeData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
      return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/addCustomAttribute',saveAttributeData);
  }

  add1Fields(attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes,moduleType:string, projectId: number){
    return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/addCustomAttribute',{
      attributeName,"projectId": projectId,
      "moduleType":moduleType,mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes,
      "userTime":this.userLocalization.getUserTime(),"userTimeZone":this.userLocalization.getUserTimeZoneCode()});
 }

  editFields(saveAttributeData:any){
    saveAttributeData.userTime=this.userLocalization.getUserTime();
    saveAttributeData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/editCustomAttribute',saveAttributeData);
   }

  saveSequenceOrder(sequenceData){
      return this.http.post<any[]>(environment.baseUrl+'/setting/api/customType/sequenceFields', sequenceData);
  }

  getFieldVisibilitySettings(projectId: number, moduleType: number,filterByRole:boolean = false) {
    return this.http.get(environment.baseUrl + '/setting/api/fields/getFieldVisibilitySettings?projectId=' + projectId + '&moduleType=' + moduleType + '&filterByRole='+filterByRole);
  }

  getPreviousSubmittedFormDetails(projectId: number, activityId: number, productFormId:number) {
    return this.http.get(environment.baseUrl + '/setting/api/customType/getPreviousSubmittedFormDetails?projectId=' + projectId + '&activityId=' + activityId + '&productFormId='+productFormId);
  }

  regexValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; 
      }

      try {
        new RegExp(value);
      } catch (e) {
        return { invalidRegex: 'The entered regex is invalid.' }; // Catch standard invalid regex errors
      }

      try {
        const parser = new RegExpParser();
        parser.parsePattern(value); // Advanced syntax check using regexpp library
      } catch (e) {
        return { invalidRegex: 'The entered regex is invalid.' };
      }
      
      //Advanced checking for unbalance parentheses
      if (!this.isBalanced(value, '(', ')')) {
        return { invalidRegex: 'Unbalanced parentheses.' };
      }
      if (!this.isBalanced(value, '[', ']')) {
        return { invalidRegex: 'Unbalanced square brackets.' };
      }
      if (!this.isBalanced(value, '{', '}')) {
        return { invalidRegex: 'Unbalanced curly braces.' };
      }

      if (!this.validateSpecialCharacters(value)) {
        return { invalidRegex: 'Invalid use of special characters.' };
      }

      return null; // Return null if the regex is valid
    };

  }

  isBalanced(str: string, open: string, close: string): boolean {
    let count = 0;
    for (const char of str) {
      if (char === open) {
        count++;
      } else if (char === close) {
        count--;
        if (count < 0) {
          return false;
        }
      }
    }
    return count === 0;
  }

  validateSpecialCharacters(str: string): boolean {
    try {
      const specialCharPattern = /\\[dDsSwWbB]|\\.|\\[0-9]{1,3}|\\x[0-9A-Fa-f]{2}|\\u[0-9A-Fa-f]{4}|\\c[A-Za-z]/;
      const matches = str.match(/\\./g);
      if (matches) {
        for (const match of matches) {
          if (!specialCharPattern.test(match)) {
            return false;
          }
        }
      }
      return true;
    } catch {
      return false;
    }
  }

}
