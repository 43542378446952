<div class="container">
    <div class="row">
        <div class="col-lg-3 col-xxl-3 pr-2">    
            <kt-company-widget1-sales-stat cssClasses="gutter-b" [parentData]="companyId"
            (isInsightsCollapsed)="insightsCollapsed($event)"></kt-company-widget1-sales-stat>
            <kt-widget6-conversations *ngIf="isWhatsappConfigured" cssClasses="gutter-b" [parentData]="companyId"
            [isInsightsCollapsed] = "isInsightsCollapsed"
            [companyInfoData]="companyInfoData" 
            (openChat)="openChat($event)"></kt-widget6-conversations>
        </div>
        <div class="col-lg-6 col-xxl-6 pl-2 pr-2">
            <kt-widget2-recent-activities cssClasses="gutter-b" [parentData]="companyId" [companyInfoData]="companyInfoData" [isActCollaspsed] = "isActCollaspsed"></kt-widget2-recent-activities>
            <kt-widget7-whatsapp-conversations *ngIf="isWhatsappConfigured" cssClasses="gutter-b"  [isActCollaspsed] = "isActCollaspsed"  [companyInfoData]="companyInfoData"></kt-widget7-whatsapp-conversations>
        </div>
        <div class="col-lg-3 col-xxl-3 pl-2">
            <kt-widget4-company cssClasses="gutter-b" [parentData]="companyId" [optionalId]="companyId" (companyChildEvent)="receiveCompanyData($event)"></kt-widget4-company>
            <kt-widget5-opportunity *ngIf="enableOpportunityFeature" cssClasses="gutter-b" [parentData]="companyId"></kt-widget5-opportunity>
            <kt-widget3-contact cssClasses="gutter-b" [parentData]="companyId"></kt-widget3-contact>
        </div>
    </div>
</div>