import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors,FormArray, ValidatorFn } from '@angular/forms';
import { CompaniesService } from '../../company-management/services/companies.service';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../services/address.service';
import { countriesModel } from '../../company-management/models/countries.model';
import { stateModel } from '../../company-management/models/state.model';
import { cityModel } from '../../company-management/models/city.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { CustomAttributesService } from '../services/custom-attributes.service';
import { ContactsService } from '../../contact-management/services/contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from '../services/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { OpportunitiesService } from '../../opportunities/services/opportunities.service';
import { ProductService  } from '../../product-management/services/product.service';
import { environment } from 'src/environments/environment';
import { Subscription, forkJoin } from 'rxjs';
import { ReferenceDocumentService } from '../services/reference-document.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { validators } from 'src/assets/plugins/formvalidation/src/js';
import { ApprovalService } from '../../approvals/services/approvals.service';
import * as moment from 'moment';
import { ActivitiesService } from '../../activities/services/activities.service';
import { UserLocalizationService } from '../services/UserLocalization.service';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { SystemAttributeEnum } from '../constant/SystemAttributeEnum.constants';
import { SystemAttribute } from '../../contact-setting/models/system-attribute.model';
import { ProductFormService } from '../../product-form/service/product-form.service';
import { FieldService } from '../services/field.service';



export interface action{
  key:number;
  value:String
  remarksId:number
}

@Component({
  selector: 'kt-edit-approval',
  templateUrl: './edit-approval.component.html',
  styleUrls: ['./edit-approval.component.scss']
})
export class EditApprovalComponent implements OnInit {

  @ViewChild('myButton') myButton : ElementRef;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];
  fieldVisibilityNotAttributeIds = [5,14,19,10,11,12,17];
  companyId:number|string;
  contactId:number;
  opportunityId:number;
  flag:String;
  ShowModal:boolean=false;
  skuItemsArray={};
  skuList:any;
  totalSum: number = 0;
  showImageArray=[];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  imgURL:any;
  images=[];
  imagesnameArray=[];
  systemAttribute11Array=[];
  systemAttribute6Array=[];
  newOpenDiv=[];
  openDivs=[];
  moduleType:number;
  fieldVisibilityConfig = [];
  moduleName:String;
  customAttributeValues=[];
  customAttributesTestValues:[];
  isSubmitted:boolean=true;
  errorMes:string="";
  disabled:Boolean=false;
  currentRemarksId:number;
  maxDate= new Date();
  minDate= new Date();
  customAttributesList=[];
  customAttributesList2=[];
  parentPropertyId:Number;
  countriesList:countriesModel[];
  stateList:stateModel[];
  cityList:cityModel[];
  image:any;
  addCompaniesForm :FormGroup;
  editId:any;
  editOppVal:any;
  singularName: string;
  systemAttributeOwnerArray=[];
  errorFlag:boolean;
  imageNoUploaded: boolean = false;
  isSubmitShow:any;
  myFormValueChanges$;
  DisplayDateFlag:boolean;
  DisplayDateMinFlag:boolean;
  DisplayDateMaxFlag:boolean;
  approvalId:number;
  actionForm: FormGroup; 
  remarksForm:FormGroup;
  approvalRemarks:String;
  approvalActionId:number;
  isCompleted:boolean;
  actionOnDate:string;
  actionDateDisplay:any;
  isActivityPage:boolean=false;
  modalHeader:string;
  activityTypeName:string;
  activityFlag:boolean;
  teamFlag:boolean=false;
  projectFlag:boolean=false;
  planId:number;
  showRemarks:boolean=false;
  optionalRemarks:boolean=false;
  viewMode:number
  isFromCompanyDashboard: boolean;
  private subscriptions: Subscription[] = [];
  // image
  fileArray = {};
  baseUrl = environment.imageUrl;
  uploadQueue = {};
  baseURL = environment.imageUrl;

  documentTypeMap = new Map([
    [1, {type: "PDF", extension:".pdf", matchString: "application/pdf"}],
    [2, {type: "Word", extension:".docx", matchString: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"}],
    [3, {type: "Excel", extension:".xlsx", matchString: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}],
    [4, {type: "Power Point", extension:".pptx", matchString: "application/vnd.openxmlformats-officedocument.presentationml.presentation"}]
  ]);
  documentIconUrlMap = new Map([
    ["pdf", "./assets/media/svg/icons/General/PDF-File.svg"],
    ["docx", "./assets/media/svg/icons/General/file-alt-solid.svg"],
    ["xlsx", "./assets/media/svg/icons/General/file-excel-solid.svg"],
    ["pptx", "./assets/media/svg/icons/General/file-powerpoint-solid.svg"]
  ]);

  private numericPattern="^(0|[1-9][0-9]*)$";
  private decimalPattern="^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";

  productFormFieldArray=[];
  productFormFields={};
  productFormDiscountDetails={};
  productFormDisabled = {};

  public singleDropdownFilter: FormControl = new FormControl();
  public multiDropdownFilter: FormControl = new FormControl();
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();
  spinnerCount: number = 0;

  actionType:action[]=null;
  noFieldData: boolean = false;
  activityId: number;
  rowData: any;
  public attributeIdVisiblityMap: Map<number, number> = new Map<number, number>();
  public workflowApprovalId:number;
  public isFieldDisable:boolean=false;
  public isAdvanceSettingEnabled:boolean

  isEditAllowed = false;
  public companyInfoData:any;
  isDeleteAllowed = false;
  public productFormIdWithProductIdNameMap=new Map<number, any[]>();
  private previousDependentProductFormArray=[];

  
 
  

  constructor(private fb: FormBuilder,
    private _cs:CompaniesService,
    public activeModal: NgbActiveModal,
    private _as:AddressService,
    private _us:UploadImageService,
    private _docUpload: ReferenceDocumentService,
    private _customAttrService:CustomAttributesService,
    private _contact_service:ContactsService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    private _field: FieldService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private approvalService:ApprovalService,
    private activitiesService:ActivitiesService,
    private userLocalization:UserLocalizationService,
    private productFormService: ProductFormService) {
    }

  ngOnInit(): void {
    this.productFormFieldArray=[];
    this.productFormFields={};
    this.productFormDiscountDetails={};
    let now = moment(this.actionOnDate).format("DD-MMM-YYYY HH:mm:ss");
    this.actionDateDisplay= moment(this.actionOnDate).format("DD-MMM-YYYY HH:mm:ss");
    if(this.isCompleted){
      if(this.activityFlag==true){
        this.modalHeader=this.activityTypeName+":"+ this.actionDateDisplay;
      }
      else if(this.isFromCompanyDashboard) {
        this.modalHeader=this.activityTypeName+" : "+ this.actionOnDate;
      }
      else{
        this.modalHeader='Action On : '+ this.actionDateDisplay;
      }
    }
    else if(this.isActivityPage){
     this.modalHeader=this.activityTypeName+' : '+this.actionDateDisplay;
    }
    else{
      this.modalHeader='Action';
    }
    
    //form builder 
    this.initaddCompanyForm();
    this.actionForm= new FormGroup({
      action: new FormControl(this.approvalActionId, Validators.required)
    });
    this.remarksForm = new FormGroup({
      remarks: new FormControl(this.approvalRemarks, Validators.required)
    })
    if(this.isCompleted){
      this.isFieldDisable=true;
      this.actionForm.get('action').disable();
      this.remarksForm.get('remarks').disable();
    }
    if(this.isActivityPage){
      this.actionForm.get('action').clearValidators();
      this.actionForm.get('action').updateValueAndValidity();
      this.remarksForm.get('remarks').clearValidators();
      this.remarksForm.get('remarks').updateValueAndValidity();
    }
    
    if(!this.isEditAllowed){
      this.isFieldDisable=true;
    }

    
    this.SpinnerService.show('addCompany')

    this.getModuleName();
    this.DisplayDateFlag= false;
    this.DisplayDateMinFlag = false;
    this.DisplayDateMaxFlag = false;

    this.getAction();
     this.getAllCountries();
     
    // get fields with field Visibility Config
     this.getFieldVisibilityConfig();
     this.errorFlag=false;
     if(this.isSubmitShow != false){  
      this.isSubmitShow="true";
     }


  }

  getFieldVisibilityConfig(){
    if(this.moduleType==SystemAttributeEnum.CONTACT_MODULE || 
      this.moduleType==SystemAttributeEnum.COMPANY_MODULE || 
      this.moduleType==SystemAttributeEnum.OPPORTUNITY_MODULE || 
      this.moduleType>=SystemAttributeEnum.ACTIVITY_MODULE) {
      this._field.getFieldVisibilitySettings(this.selectedProject, this.moduleType,true).subscribe(res => {
        if(res['responseData']) {
          res['responseData'].filter(x => x['fieldVisibilityConfig'])
          .forEach(x => {
            this.fieldVisibilityConfig.push(...x['fieldVisibilityConfig'])
          })
          this.getFields();
        }
      });
    }
    else{
      this.getFields();
    }
  }

  getModuleName(){
    switch (this.moduleType){
      case 1 :{
        this.moduleName="Contact";
        break;
      }
      case 2 :{
        this.moduleName="Company";
        break;
      }
      case 3 :{
        this.moduleName="Opportunity";
        break;
      }
      default:{
        this.moduleName="";
        break;
      }
    }
  }

  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  
      var id='div_'+res.id;
      var division1=<HTMLElement>document.getElementById(id);
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues[0].id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }


  getAllOwner(){
    this._customAttrService.getOwner(this.selectedProject,Number(this.moduleType)).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.fullName;

        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }

  getAllOpportunities(skipReset : boolean= false){
    // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
      let teamFlag = false;
      let projectFlag = true;
      let compId = this.companyId;
      if(this.companyId === undefined || this.companyId === null){
        compId = "";
      }

      if(this.viewMode >=0) {
        if(this.viewMode === 1) {
          teamFlag = false;
          projectFlag = false;
        } else if(this.viewMode === 2) {
          teamFlag = true;
          projectFlag = false;
        } else if(this.viewMode === 3) {
          teamFlag = false;
          projectFlag = true;
        }
      }
    
     this._os.getAllOpportunityDetails2(compId, teamFlag, projectFlag).subscribe(
       data=>{
        
         data['responseData'].forEach(res=>{
           
             res.lookupValue=res.opportunityName;
             res.id=res.opportunityId;
           
         })
         var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
       
         if(removeIndex >-1){
           
           this.customAttributesList[removeIndex].lookupValues=data['responseData'];
           this.customAttributesList[removeIndex].hasChildAttribute=true;
           if(this.flag !== 'opportunity' && !skipReset) {
            this.getAllOpportunitiesDependentInputs(0);// to reset opportunity linked field when select customer changed
          }
   
         }
 
         if(this.opportunityId && this.flag=="opportunity"){
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 
 
         }
 
       }
     ) 
   }
 
   getAllOpportunitiesDependentInputs(id){
     //api to prefill opportunity inputs
     this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
      this.systemAttributeopportunityListArray.forEach(res=>{
        if(res.systemAttribute.id==26 && this.opportunityId && this.flag=="opportunity") {
          return;
        }
        const control = this.addCompaniesForm.controls[res.combinedAttributeName];
        if(control) {
          control.reset();
        }
      });
       var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
    
       res['responseData'][0]?.customAttributeValues.forEach(res1=>{
         if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
           
           this.addCompaniesForm.controls[this.systemAttribute11Array[0].combinedAttributeName].setValue(res1.value[0].id);   
           this.addCompaniesForm.controls[this.systemAttribute11Array[0].combinedAttributeName].disable(); 
         }
         if(  res1.systemAttribute && res1.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_COMPANY_NAME_FIELD_ID){
           const val = res1.value[0] ? res1.value[0].id : null;
           this.addCompaniesForm.controls[this.systemAttribute11Array[0].combinedAttributeName].setValue(val);
         }
         this.systemAttributeopportunityListArray.forEach(res=>{
           
           
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
              if(res.systemAttribute.id==21){
                  if(res1.value.length > 0){
                    this.addCompaniesForm.controls['countryName'].setValue(res1.value[0].name+'@'+res1.value[0].id);
                  }
                  this.getAllStates(res1.value[0].id);
                  if(res1.value.length > 1){
                    this.addCompaniesForm.controls['stateName'].setValue(res1.value[1].name+'@'+res1.value[1].id);
                    this.getAllCities(res1.value[1].id);
                  }
                  
                  if(res1.value.length > 2){
                    this.addCompaniesForm.controls['cityName'].setValue(res1.value[2].name+'@'+res1.value[2].id);
                  }
                
              }
               if(res.systemAttribute.id==26){
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
               }else{                
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id);  
               }          
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              
               if(res.systemAttribute.id==26){
                
                 if(this.opportunityId && this.flag=="opportunity"){
 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                 }
                 
               }else{                
                 if(res.attributeType.id==2){
                   var arraynew=[];
                   res1.value.forEach(d=>{
                     arraynew.push(d.id);
                   })
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
                 
                 }  
               }                
           
             }
           }         
         })
       })
     })
   }
 
   getAllCompanyDependentInputs(id){
     //api to prefill opportunity inputs
     this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{
      const responseData = res['responseData'][0];
      this.systemAttributecompanyListArray.forEach(res=>{
        if(res.systemAttribute && res.systemAttribute.id == 25) return;
        if([4, 15, 16].includes(res.attributeType.id)) {
          this.resetLinkedMediaFields(res);
        }
        else {
          const control = this.addCompaniesForm.controls[res.combinedAttributeName];
          if(control) {
            control.reset();
            if(res.attributeType.id === 18) {
              if(res.childAttributeList && res.childAttributeList.length > 0) {
                res.childAttributeList.forEach( child => {
                  const childControl = this.addCompaniesForm.controls[child.combinedAttributeName];
                  if(childControl) {
                    childControl.reset();
                  }
                })
              }
              if(responseData.customAttributeValues.find(x => x.attributeId == res.linkedAttributeId) === undefined) {
                this.invokeChildMethod(0, res, '');
              }
            }
          }
        }
      });
 
       if(!responseData) return;
       responseData.customAttributeValues.forEach(res1=>{
       
         this.systemAttributecompanyListArray.forEach(res=>{
          if(res.systemAttribute && res.systemAttribute.id == 25) return;
     
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
               
               }
                      
               
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              if(res1.attributeTypeId === 18) {
                let value = res1.value[0]?.id;
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(value ? +value : null); 
                this.invokeChildMethod(value, res, '');
                if(res.childAttributeList && res.childAttributeList.length > 0) {
                  res.childAttributeList.forEach((child, idx) => {
                    responseData.customAttributeValues.forEach(resp=>{

                      if(child.parentLinkedDataListAttribId == res.id && child.id == resp.attributeId && res.linkedAttributeId == resp.parentLinkedDatalistAttribId) {
                        let childValue = resp.value[0]?.id;
                        this.addCompaniesForm.controls[child.combinedAttributeName].setValue(childValue ? +childValue : null); 
                      }
                    })
                  });
                }
              }
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                if(res1.systemAttribute?.id == SystemAttributeEnum.ACTIVITY_COMPANY_FIELD_ID){
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name); 
                } 
               
               }
           
             }
           }
          
          
         })
       })
     })
   }
 
   resetLinkedMediaFields(element: CustomAttibute) {
     this.fileArray[element.combinedAttributeName].files = [];
     this.fileArray[element.combinedAttributeName].documentIcons = [];
     this.fileArray[element.combinedAttributeName].uploaded = 0;
     this.fileArray[element.combinedAttributeName].hasError = false;
     this.fileArray[element.combinedAttributeName].showUploadIcon = true;
     this.addCompaniesForm.controls[element.combinedAttributeName].reset();
   }
 
   getAllEstimates(){
    this._customAttrService.getActivityLookup(this.moduleType,this.selectedProject,this.companyId,this.opportunityId).subscribe(
      data=>{
       

        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId;          
        })

        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }

   getAllProducts(){
     this._ps.getProductLookup(this.selectedProject).subscribe(
       data=>{
         this.skuList= data['responseData'];     
         this.setupProductForms();   
       }
     ) 
   }

   setupProductForms(){
 
     for (const element of this.productFormFieldArray){
       if(element.parentAttributeId!=null && element.parentAttributeId>0){
         continue;
       }
       this._customAttrService.getFields(element.parentFormId, this.selectedProject).subscribe(
         res=>{
           this.productFormFields[element.combinedAttributeName]={fields:res['responseData'].filter(ele => ele.status==1),
           formGroup: {},
           productFieldName:"",
           productFieldNameInput:"",
           productFormView: null,
         numericFields:0,
         enteredNumericFields:[0],
         numericData:[{}],
         subTotal: 0,
         discountValue: Number(0),
         discountPercent: Number(0),
         total: 0,
         summaryFieldCombinedAttributeName:""
         };

           this.productFormFields[element.combinedAttributeName].fields.forEach((ele: CustomAttibute)=>{ 
             if(ele.systemAttribute?.id ==48)
             {
               this.productFormFields[element.combinedAttributeName].productFieldName=ele.combinedAttributeName;
               this.productFormFields[element.combinedAttributeName].productFieldNameInput=ele.combinedAttributeName+"inputField";
               this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = ((ele.mandatoryFlag === 1) && (element.mandatoryFlag == 1)) ?[null, Validators.required] : [null];
               this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName+"inputField"] = [{value:null,disabled:true}];
             }
             else if(ele.mandatoryFlag === 1 && element.mandatoryFlag === 1){
               this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [null, Validators.required];
           }
             else{
               this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [];
             }
     
             if(ele.attributeType.id==7){
               this.productFormFields[element.combinedAttributeName].numericFields += 1;
             }
 
             if(ele.attributeType.id==4){
               this.fileArray[element.combinedAttributeName + ele.combinedAttributeName+0] = {
                 "attributeName": ele.combinedAttributeName,
                 "files": [],
                 "maxUpload": ele.maxImagesAllowed,
                 "showUploadIcon": true,
                 "showDeleteOption":true,
                 "uploaded": 0,
                 "hasError": false
               };
             }
           })
           
         this.productFormService.getSelectedProductForm(element.parentFormId).subscribe(
           rdata=>{
             this.productFormDiscountDetails[element.combinedAttributeName]={
             isAllowDiscount: false,
             isDiscountValue:false,
             isDiscountPercent:false
           };
 
          const productDetailsArray = this.skuList.map(productDetails => ({
            ...productDetails,
            isSelectionAllowed: rdata['responseData']?.productIdList?.includes(productDetails.productId),
          }));
          
        this.productFormIdWithProductIdNameMap.set(element.parentFormId, productDetailsArray || []);
        
        if(rdata['responseData']?.allowDiscounts == 1 ){
             this.productFormDiscountDetails[element.combinedAttributeName].isAllowDiscount = true;
           }
           if(rdata['responseData']?.allowDiscounts == 1 && rdata['responseData']?.discountType?.length > 0){
             const discType: any[] = rdata['responseData']['discountType'];
             if(discType.includes(0) || discType.includes('0')){
               this.productFormDiscountDetails[element.combinedAttributeName].isDiscountValue = true;
             }
             if(discType.includes(1) || discType.includes('1')){
               this.productFormDiscountDetails[element.combinedAttributeName].isDiscountPercent = true;
             }
           }
           
           const summaryTotalFieldAttributeId=rdata['responseData'].summaryTotalField;
           const summaryTotalFieldObject=this.productFormFields[element.combinedAttributeName].fields.find(x =>x.id === summaryTotalFieldAttributeId);
           if(summaryTotalFieldObject !== undefined){
             this.productFormFields[element.combinedAttributeName].summaryFieldCombinedAttributeName=summaryTotalFieldObject.combinedAttributeName;
           }
 
           this.productFormFields[element.combinedAttributeName].productFormView = rdata['responseData'].productFormView;
           if(rdata['responseData'].productFormView===1)
           {
             const selectedSkuIds: Array<Number> = this.populateProductFormWithSavedData(element, true);
             const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
             let controlSize = control.length;
             for(let i=0;i<this.productFormIdWithProductIdNameMap.get(element.parentFormId).length;i++,controlSize++){
              if(!selectedSkuIds.includes(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productId)) {
                if(!this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].isSelectionAllowed){
                  continue;
                }
                const newIndex=this.addTable(element.combinedAttributeName);
                this.updateItems(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productId,newIndex,element.combinedAttributeName,element.parentFormId);
                control.at(newIndex).get( this.productFormFields[element.combinedAttributeName].productFieldName).setValue(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productId);
                control.at(newIndex).get( this.productFormFields[element.combinedAttributeName].productFieldNameInput).setValue(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productName);
              }
             }
           } else {
            this.populateProductFormWithSavedData(element);
          }
         })
       })
     }
   }

  private populateProductFormWithSavedData(element: any, skipIfBlank = false): Array<Number> {
    let selectedSkuIds: Array<Number> = [];
    const productFormValues = this.editOppVal.customAttributeValues.find(res => res.attributeId === element.id);
    const productFormData = productFormValues?.productFormData;
    const productFormSummaryDiscountData = productFormValues?.productFormSummaryDiscountData;
    if (productFormData == undefined|| (Array.isArray(productFormData) && productFormData.length === 0)) {
      if(!skipIfBlank)
        this.createFormGroupForProductForm(element, null, 0);
    }
    else {
      productFormData.forEach((pData, pIndex) => {
        if (pIndex > 0) {
          this.productFormFields[element.combinedAttributeName].numericData.push({});
          this.productFormFields[element.combinedAttributeName].enteredNumericFields.push(0);
        }
        const selectedSkuId: Number = this.createFormGroupForProductForm(element, pData, pIndex);
        if(selectedSkuId) selectedSkuIds.push(selectedSkuId);
      });
      this.productFormFields[element.combinedAttributeName].discountValue = productFormSummaryDiscountData.value ? Number(productFormSummaryDiscountData.value) : 0;
      this.productFormFields[element.combinedAttributeName].discountPercent = productFormSummaryDiscountData.percent ? Number(productFormSummaryDiscountData.percent) : 0;
      this.calculateSummeryFields(element.combinedAttributeName);
    }
    return selectedSkuIds;
  }

  clickImage(attribName, arrayAttribName: string = null){
    if(arrayAttribName !== null){
      let element:HTMLElement =document.getElementById(attribName + arrayAttribName) as HTMLElement;
      element.click();
    }
    else{
      let element:HTMLElement =document.getElementById(attribName) as HTMLElement;
      element.click();
    }
  }

   addTable(combinedAttributeName) {
    if(this.productFormDisabled[combinedAttributeName]) return;
     const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
     
     this.productFormFields[combinedAttributeName].fields.forEach(ele=>{ 
       if(ele.attributeType.id == 4){
         this.fileArray[combinedAttributeName + ele.combinedAttributeName+control.length] = {
           "attributeName": ele.combinedAttributeName,
           "files": [],
           "maxUpload": ele.maxImagesAllowed,
           "showUploadIcon": true,
           "uploaded": 0,
           "hasError": false
         };
       }
     })
   
     control.push(this.fb.group(this.productFormFields[combinedAttributeName]?.formGroup));
     const newIndex = control.length - 1;
     this.productFormFields[combinedAttributeName].enteredNumericFields.push(0);
     this.productFormFields[combinedAttributeName].numericData.push({});
     return newIndex;
   }

   deleteRow(combinedAttributeName, i: number) {
    if(this.productFormDisabled[combinedAttributeName]) return;
     const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
     if(control.length===1) return;
     control.removeAt(i);
  
     this.productFormFields[combinedAttributeName].enteredNumericFields.splice(i, 1);
     this.productFormFields[combinedAttributeName].numericData.splice(i, 1);
   
     let totalFieldCombinedName ='';
     this.productFormFields[combinedAttributeName].fields.forEach(f => {
       if(f.systemAttribute?.id === 52){
         totalFieldCombinedName = f.combinedAttributeName;
       } 
     });
     this.calculateSummeryFields(combinedAttributeName);
   }

   
  getCompanyDetails(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      res=>{    
        this.editOppVal=res['responseData'];
        if(!res['responseData'] || res['responseData'].length===0) {
          this.noFieldData = true;
          return;
        }
  
      }
    )
  }
  
  initaddCompanyForm(){

    this.addCompaniesForm = new FormGroup({   
     
    });
  }

  
  getAllCountries(){
    this._as.getAllCountries(this.selectedProject).subscribe(
		(result)=>{ 
       this.countriesList=result['responseData']; 
		},
		error => {
			console.log(error)
    })
  }

  changeCountry(countryId){
    //get states
    var id=countryId.split('@',2);
    this.getAllStates(id[1]);
  }

  getSKU() {
    
    return this.fb.group({
     sku: ['', Validators.compose([ Validators.required])],
     quantity: ['', Validators.compose([ Validators.required])],
     listPrice: [''],
     sellingPrice: ['', Validators.compose([ Validators.required])],
     discount: [''],
     tax: ['', Validators.compose([ Validators.required])],
     total: ['']
    });
  }

  getControls(combinedAttributeName) {
    return (this.addCompaniesForm.get(combinedAttributeName) as FormArray)?.controls;

  }
  updateItems(selectedProductId,i,combinedAttributeName,parentFormId) {
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  let attribute = null;
  let listPriceAttributeName = null;
  let listPriceCombinedAttributeName = null;
  let listPriceAttributeId = null;
  let productPrice = null;
  this.productFormFields[combinedAttributeName].fields.forEach(ele=>{ 
    if(ele.systemAttribute?.id == 50){
      attribute = ele.combinedAttributeName;
      listPriceAttributeId = ele.id;
      listPriceAttributeName = ele.attributeName;
      listPriceCombinedAttributeName = ele.combinedAttributeName;
    }
  })
    for(let sku of this.productFormIdWithProductIdNameMap.get(parentFormId)){
      if(sku.productId == selectedProductId){
        control.at(+i).get(attribute).setValue(sku.price);
        productPrice = sku.price;

  
      }
   }

   if(attribute){
    if(!this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId] && productPrice>=0){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] += 1;
    }
    else if(this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId] && productPrice === null){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] -= 1;
    }
    this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId]={
      attributeId: listPriceAttributeId,
      attributeName: listPriceAttributeName,
      combinedAttributeName: listPriceCombinedAttributeName,
      value: productPrice
    };

    // calculate total
    if(this.productFormFields[combinedAttributeName].enteredNumericFields[i]==this.productFormFields[combinedAttributeName].numericFields){
      const keys = Object.keys(this.productFormFields[combinedAttributeName].numericData[i]);
      let productFormData=[];
      keys.forEach((key, index) => {

      productFormData.push(
      this.productFormFields[combinedAttributeName].numericData[i][key]
      )   

    });
    // call api method
    this.calculateTotal(combinedAttributeName, productFormData, i, control);

    }
    this.calculateSummeryFieldsIfMatches(combinedAttributeName, attribute);
   }
}

calculateSummeryFieldsIfMatches(productFormCombinedAttributeName, fieldCombinedAttributeName) {
  if (this.productFormFields[productFormCombinedAttributeName].summaryFieldCombinedAttributeName === fieldCombinedAttributeName) {
    this.calculateSummeryFields(productFormCombinedAttributeName);
  }
}

discountValueChanged(combinedName){
  if(this.productFormFields[combinedName].discountValue === null || this.productFormFields[combinedName].discountValue===''){
    this.productFormFields[combinedName].discountValue = 0;
  }
  if(this.productFormFields[combinedName].subTotal){
    const discountPercent = (this.productFormFields[combinedName].discountValue / this.productFormFields[combinedName].subTotal) * 100;
    this.productFormFields[combinedName].discountPercent = discountPercent;
    this.productFormFields[combinedName].total = this.productFormFields[combinedName].subTotal - this.productFormFields[combinedName].discountValue;
  }
}

discountPercentChanged(combinedName){
  if(this.productFormFields[combinedName].discountPercent === null || this.productFormFields[combinedName].discountPercent === ''){
    this.productFormFields[combinedName].discountPercent = 0;
  }

  const discountAmt = (this.productFormFields[combinedName].subTotal * this.productFormFields[combinedName].discountPercent) / 100;
  this.productFormFields[combinedName].discountValue = discountAmt;
  this.productFormFields[combinedName].total = this.productFormFields[combinedName].subTotal - discountAmt;
}

calculateTotal(combinedAttributeName, productFormData, i, control){
  let totalFieldCombinedNameList : string[] =[];
  let calculationFormulaList : string[]=[];
  this.productFormFields[combinedAttributeName].fields.forEach(f => {
    if(f.attributeType.id==14){
      totalFieldCombinedNameList.push(f.combinedAttributeName);
      calculationFormulaList.push(f.calculationFormula.replace(/,/g, ''));
    } 
  });

  if(calculationFormulaList.length>0 && productFormData?.length>0){
    totalFieldCombinedNameList.forEach((totalFieldcombinedAttributeName, index)=>{
      // call api
      this.productFormService.validateExpression({expression: calculationFormulaList[index], attributeList: productFormData}).subscribe(
        res=>{
          if(res['responseData']?.validated){
            control.at(+i).get(totalFieldcombinedAttributeName).setValue(res['responseData']?.calculatedTotal);
            // calculate sub total
            this.calculateSummeryFields(combinedAttributeName);
          }
        });
    })
  }
}

calculateSummeryFields(combinedAttributeName){
  const totalFieldCombinedName=this.productFormFields[combinedAttributeName].summaryFieldCombinedAttributeName;
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  let subTotal = 0;
  for(let i=0;i<control.length;i++){
    if(totalFieldCombinedName) {
      let total = control.at(i).get(totalFieldCombinedName).value === null ? 0 : Number(control.at(+i).get(totalFieldCombinedName).value);
      subTotal += total
    }
  }
  this.productFormFields[combinedAttributeName].subTotal = subTotal;
  // calc total
  this.productFormFields[combinedAttributeName].total = this.productFormFields[combinedAttributeName].subTotal;
  // re calc discount
  if(this.productFormFields[combinedAttributeName].discountValue && this.productFormDiscountDetails[combinedAttributeName].isDiscountValue){
    this.discountValueChanged(combinedAttributeName);
  }
  if(this.productFormFields[combinedAttributeName].discountPercent && this.productFormDiscountDetails[combinedAttributeName].isDiscountPercent){
   this.discountPercentChanged(combinedAttributeName);
  }
}

productFormFieldChange(combinedAttributeName,i,field,value){
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  if(field.attributeType.id==7){
    if(!this.productFormFields[combinedAttributeName].numericData[i][field.id] && value){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] += 1;
    }
    else if(this.productFormFields[combinedAttributeName].numericData[i][field.id] && !value){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] -= 1;
      this.productFormFields[combinedAttributeName].numericData[i][field.id] = null;
    }

    if(value){
      this.productFormFields[combinedAttributeName].numericData[i][field.id]={
        attributeId: field.id,
        attributeName: field.attributeName,
        combinedAttributeName: field.combinedAttributeName,
        value: value
      };
    }
    
  }
  if(this.productFormFields[combinedAttributeName].enteredNumericFields[i]==this.productFormFields[combinedAttributeName].numericFields){
    const keys = Object.keys(this.productFormFields[combinedAttributeName].numericData[i]);
    let productFormData=[];
    keys.forEach((key, index) => {

    productFormData.push(
    this.productFormFields[combinedAttributeName].numericData[i][key]
    )   
    
  });
  // call api method
  this.calculateTotal(combinedAttributeName, productFormData, i, control);
  
  }
}
private createFormGroupForProductForm(element: any,pData:any,pDataIndex): Number {
  let selectedSkuId: Number = null;
  const isDisabled = !!this.productFormDisabled[element.combinedAttributeName];
    let productFormGroup = {};
    this.productFormFields[element.combinedAttributeName].fields.forEach((ele: CustomAttibute) => {
      const fieldData=pData?(pData.value.find(d => ele.id === d.id)?.name) :null;
      if (ele.systemAttribute?.id == 48) {
        this.productFormFields[element.combinedAttributeName].productFieldName = ele.combinedAttributeName;
        this.productFormFields[element.combinedAttributeName].productFieldNameInput = ele.combinedAttributeName + "inputField";
        if(fieldData) selectedSkuId = Number(fieldData);
        productFormGroup[ele.combinedAttributeName] = [{ value: fieldData ? selectedSkuId : null, disabled: isDisabled }];
        const selectedSku = this.productFormIdWithProductIdNameMap.get(element.parentFormId).find(x => x.productId === selectedSkuId)
        productFormGroup[ele.combinedAttributeName + "inputField"] = [{ value:  selectedSku && selectedSku.productName ? selectedSku.productName : null, disabled: true }];
        if(selectedSku==undefined || selectedSku==null){
          return;
        }
      } else {
        // for image/Document/Video/reference document
        if([4,15,16,17].includes(ele.attributeType.id)){
          this.fileArray[element.combinedAttributeName + ele.combinedAttributeName + pDataIndex] = {
            "attributeName": ele.combinedAttributeName,
            "files": [],
            "maxUpload": ele.maxImagesAllowed,
            "showUploadIcon": true,
            "uploaded": 0,
            "hasError": false
          };
          let files = [];
          fieldData?.split(',').forEach(img=>{
            files.push(img);
          });
          const fileAttr = this.fileArray[element.combinedAttributeName + ele.combinedAttributeName + pDataIndex];
          fileAttr.files = files;
          fileAttr.uploaded = files.length;
          fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
          productFormGroup[ele.combinedAttributeName] = [{ value:files, disabled: isDisabled }];
        }
        //for singleSelect or numeric type field
        else if(ele.attributeType.id==1||ele.attributeType.id==7){
          productFormGroup[ele.combinedAttributeName] = [{ value:  fieldData ? Number(fieldData):null, disabled: isDisabled }];
          if(fieldData) {
            this.productFormFields[element.combinedAttributeName].enteredNumericFields[pDataIndex] += 1;
            this.productFormFields[element.combinedAttributeName].numericData[pDataIndex][ele.id]={
              attributeId: ele.id,
              attributeName: ele.attributeName,
              combinedAttributeName: ele.combinedAttributeName,
              value: Number(fieldData)
            };
          }
        }
        //for multiSelectFields
        else if(ele.attributeType.id==2){
          productFormGroup[ele.combinedAttributeName] = [{ value: fieldData?JSON.parse(fieldData):null, disabled: isDisabled }];
        }
        else{
          productFormGroup[ele.combinedAttributeName] = [{ value:  fieldData ? fieldData:null, disabled: isDisabled }];
        }
      }
    })
    const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
    control.push(this.fb.group(productFormGroup));
    return selectedSkuId;
}


  getAllStates(countryId){
    this._as.getStatesForCountry(countryId).subscribe(
      (result)=>{  
        this.stateList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  changeState(stateId){
    //get cities
    var id=stateId.split('@',2);
    this.getAllCities(id[1]);
  }

  getAllCities(stateId){
    this._as.getCitiesForState(stateId).subscribe(
      (result)=>{
        this.cityList=result['responseData']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  getAllCompanies(){
     this._cs.getCompanyIdName(this.selectedProject,this.moduleType,this.viewMode).subscribe(
      data=>{
       
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.name;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    )
 
  }

  getAllContacts(){
    this._contact_service.getAllContactDetailsForSelfAndTeam(this.selectedProject,this.moduleType).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.contactName;
            res.id=res.contactId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute6Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }
  getFields(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{       
        this.editOppVal=data['responseData'][0];
        this.addCompaniesForm = new FormGroup({
          serialNo: new FormControl({ value: this.editOppVal?.serialNo||'', disabled: true })
        });
                    
         if(this.workflowApprovalId){
          this._customAttrService.fetchApprovalFieldDetails(this.selectedProject,this.moduleType,this.workflowApprovalId).subscribe(
            detail=>{
              detail['responseData'].forEach(a=>{
                this.attributeIdVisiblityMap.set(a.attributeId,a.fieldVisibility)
              });
              this.setupFormFields();
            }
            
          )
         }else{
          this.setupFormFields();
         }
    

          },
          error => {
            this.disabled=false;
            this.errorMes=error.error.responseData.errorMsg;
    
          }
        )

    
  }
  setupFormFields(){

    let linkedDataListDetails = [];
    forkJoin([
      this._customAttrService.getLinkedDataListDetails(this.moduleType, this.selectedProject), 
      this._customAttrService.getFields(this.moduleType, this.selectedProject)
    ]).subscribe(
      ([getLinkedDataListDetailsResponse, getFieldApiResponse]) => {
        // Handle responses from both APIs here
        linkedDataListDetails = getLinkedDataListDetailsResponse['responseData'];
        this.processGetFieldApiResponse(getFieldApiResponse['responseData'], linkedDataListDetails)
      },
      error => {
        this.disabled=false;
        this.errorMes=error?.error?.responseData?.errorMsg;
      }
    );
  }

  private async processGetFieldApiResponse(responseData, linkedDataListDetails = []) {
    const linkedDlMap: Map<number, any> = (linkedDataListDetails ?? []).reduce((map, obj) => {
      map.set(obj.id, obj);
      return map;
    }, new Map<string, number>());

    responseData = responseData.filter(ele => {

      //removing field  whose visiblity is hidden
      if(this.attributeIdVisiblityMap.has(ele.id)
      && this.attributeIdVisiblityMap.get(ele.id)==2){
        return 0;
      }

      if(ele.status == 1){
        // if(ele.attributeType.id==4 ){
        //   if( ele.allowBrowsing==1 ){
        //     return 1;
        //   }else{
        //     return 0;
        //   }
        // }else{
          return 1;
        // }
      }
    })

    this.SpinnerService.hide('addCompany');
    this.ShowModal=true
    responseData.forEach(element=>{
      if(element.systemAttribute==null)
      {
        element.systemAttribute={};
      }
    

      if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
        
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            
            

        }else{

            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl())
            
        }

        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        }

        let controlValue = 0;
        this.editOppVal?.customAttributeValues.forEach(ele=>{
     
          if(ele.attributeId==element.id){

            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element});
              }
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
              controlValue = ele.value[0].id;
              if(element.systemAttribute.id==25)
              {
                this.companyId=controlValue;
              }
            }
          }
        });
                
        if(element.parentAttributeId === 0 && element.hasChildAttribute) {
          setTimeout(() => {
            this.invokeChildMethod(controlValue, element, '');
          }, 10);
        }
        

      }else{
        if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           
            
          if(element.mandatoryFlag==1){
            this.addCompaniesForm.addControl('countryName',  new FormControl('',Validators.compose([ Validators.required])));
            this.addCompaniesForm.addControl('stateName',  new FormControl('',Validators.compose([ Validators.required])));
            this.addCompaniesForm.addControl('cityName',  new FormControl('',Validators.compose([ Validators.required])));
           
          }else{

            this.addCompaniesForm.addControl('countryName',  new FormControl());
            this.addCompaniesForm.addControl('stateName',  new FormControl());
            this.addCompaniesForm.addControl('cityName',  new FormControl());

          }
          if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
          && this.attributeIdVisiblityMap.get(element.id)==1)){
            this.addCompaniesForm.get('countryName').disable();
            this.addCompaniesForm.get('stateName').disable();
            this.addCompaniesForm.get('cityName').disable();
          
          }

        

          this.editOppVal.customAttributeValues.forEach(ele=>{
      
            if(ele.attributeId==element.id){
        
              if(ele.value.length>0){

                if(ele.value.length > 0){
                  this.addCompaniesForm.controls['countryName'].setValue(ele.value[0].name+'@'+ele.value[0].id);
                }
                //after filling country get states 
                this.getAllStates(ele.value[0].id);
                if(ele.value.length > 1){
                  this.addCompaniesForm.controls['stateName'].setValue(ele.value[1].name+'@'+ele.value[1].id);
                    //after filling state get cities
                  this.getAllCities(ele.value[1].id);
                }
                
                if(ele.value.length > 2){
                  this.addCompaniesForm.controls['cityName'].setValue(ele.value[2].name+'@'+ele.value[2].id);
                }
              }

            }
          })

        }
      }

      if(element.attributeType.id==2){
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        }else{

            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }

        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        
        }
        
        var multiSelectArray=[];
        this.editOppVal.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(ele.value.length>0){
                if(element.parentAttributeId !==0){
                  this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
                }
                this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
        
              }
              ele.value.forEach(res=>{
                multiSelectArray.push(res.id);
              })
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
            }
          }
        })
      }

      if(element.attributeType.id==5){
        //radio
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
 
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        }else{
              
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
  
        }
                          
        this.editOppVal.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
            }
          }
        })
      }
         
      if(element.attributeType.id==6  || element.attributeType.id==20){
        //text
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        }else{

            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        
        }
      
        this.editOppVal?.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
            }
          }
        })

      }

      if (element.attributeType.id == 6) {
        let patternString = ".*";
        let patternErrorMsg = '';
        if (element.addValidation) {
          patternString = element.regexExpression;
          patternErrorMsg = element.errorMessage;
        }
        element.patternErrorMsg = patternErrorMsg;
        this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', [Validators.pattern(patternString)]));
      }

      if(element.attributeType.id==7){
        let pattern = this.numericPattern;
        let patternErrorMsg = '';
        if (element.decimalFlag) {
          pattern = this.decimalPattern;
        } else if (element.addValidation) {
          pattern = element.regexExpression;
          patternErrorMsg = element.errorMessage;
        }
        element.patternErrorMsg = patternErrorMsg;
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(pattern)])));
        }else{

          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',Validators.compose([ Validators.pattern(pattern)])));
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        
        }
        
        this.editOppVal?.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
             this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
            }
          }
        })
      }

      if(element.attributeType.id==8){
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        }else{

            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        
        }
        
        this.editOppVal?.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
            this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
            }
          }
        })

      }
        
      if(element.attributeType.id==3 || element.attributeType.id==21){
       
          this.maxDate= new Date();

          this.minDate= new Date();
          var date = new Date();
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var end = new Date(now.getFullYear(), 11, 31);
          var diff = Number(now) - Number(start);
          var oneDay = 1000 * 60 * 60 * 24;

          if(element.dateAttributes){
    
            if(element.dateAttributes.stdLookupAttribute != null){
              this.DisplayDateMinFlag=false;
              this.DisplayDateMaxFlag=false;
              if(element.dateAttributes.stdLookupAttribute.id == 2){
                this.DisplayDateFlag=true;
                element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 3){
                this.DisplayDateFlag=true;
                element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);
            
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 5){
                this.DisplayDateFlag=true;
                element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay()+6);
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 6){
                this.DisplayDateFlag=true;
                
                //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
            
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 7){
                this.DisplayDateFlag=true;
                //var date = new Date();
                //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                element.dateAttributes.maxDate= new Date(date.getFullYear(), date.getMonth() + 1, 0);
            
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 8){
                this.DisplayDateFlag=true;
                //var now = new Date();
                //var start = new Date(now.getFullYear(), 0, 0);
                //var diff = Number(now) - Number(start);
                //var oneDay = 1000 * 60 * 60 * 24;
                var day = Math.floor(diff / oneDay);
                element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
             
              }
              else if(element.dateAttributes.stdLookupAttribute.id == 9){
                this.DisplayDateFlag=true;
                //var now = new Date();
                //var start = new Date(now.getFullYear(), 0, 0);
                //var end = new Date(now.getFullYear(), 11, 31);
                var diffStart = Number(now) - Number(start);
                var diffend = Number(end) - Number(now) ;
                //var oneDay = 1000 * 60 * 60 * 24;
                var day = Math.floor(diffStart / oneDay);
                var dayEnd = Math.floor(diffend / oneDay);
                element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
               }
            }else{
                if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed == null){

                  this.DisplayDateFlag=false;
                  this.DisplayDateMinFlag=false;
                  this.DisplayDateMaxFlag=false;
                }
                else if(element.dateAttributes.previousDateAllowed == 0 && element.dateAttributes.futureDateAllowed == 0){
        
                  this.DisplayDateFlag=true;
                  this.DisplayDateMinFlag=false;
                  this.DisplayDateMaxFlag=false;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                }
                else if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed != null){
                
                  this.DisplayDateMaxFlag=true;
                  this.DisplayDateMinFlag=false;
                  this.DisplayDateFlag=false;
                  if(element.dateAttributes.futureDateAllowed ==0){
                    element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                  }else{
                    element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                  }
                }
                else if(element.dateAttributes.previousDateAllowed != null && element.dateAttributes.futureDateAllowed == null){
              
                  this.DisplayDateMinFlag=true;
                  this.DisplayDateFlag=false;
                  this.DisplayDateMaxFlag=false;
                  if(element.dateAttributes.previousDateAllowed ==0 ){
                    element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                  }else{
                    element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                  }
                }else{
                 
                  this.DisplayDateFlag=true;
                  this.DisplayDateMinFlag=false;
                  this.DisplayDateMaxFlag=false;
                  if(element.dateAttributes.previousDateAllowed ==0 ){
                    element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                  }else{
                   element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                  }
      
                  if(element.dateAttributes.futureDateAllowed ==0){
                    element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                  }else{
                    element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                  }
                } 
             }
             if(element.attributeType.id==21){
              let DateTimeMinDate = new Date(element.dateAttributes.minDate);
              let minYear = DateTimeMinDate.getFullYear();
              let minMonth = (DateTimeMinDate.getMonth() + 1).toString().padStart(2, '0'); 
              let minDays = DateTimeMinDate.getDate().toString().padStart(2, '0');
              element.dateAttributes.minDate = `${minYear}-${minMonth}-${minDays}T00:00:00`;
              let DateTimeMaxDate = new Date(element.dateAttributes.maxDate);
              let maxYear = DateTimeMaxDate.getFullYear();
              let maxMonth = (DateTimeMaxDate.getMonth() + 1).toString().padStart(2, '0'); 
              let maxDays = DateTimeMaxDate.getDate().toString().padStart(2, '0');
              element.dateAttributes.maxDate = `${maxYear}-${maxMonth}-${maxDays}T00:00:00`;
             }     

             
          }
         
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        }else{

            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
        
        }
       

        this.editOppVal?.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
            }
          }
        })
      }
     if(element.attributeType.id ==22){

        if(element.mandatoryFlag==1&& element.parentAttributeId === 0){
  
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
      }else{
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl(''));
      }if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
      && this.attributeIdVisiblityMap.get(element.id)==1)){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
              
      }
      this.editOppVal?.customAttributeValues.forEach(ele=>{
        if(ele.attributeId==element.id){
          if(ele.value.length>0){
            if(element.parentAttributeId !==0){
              this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
            }
           this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
          }
        }
      })
      }

      if(element.attributeType.id==4){

        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
      }else{

          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
      }
                      
        let files = [];
        this.fileArray[element.combinedAttributeName] = {
          "attributeName": element.combinedAttributeName,
          "files": files,
          "maxUpload": element.maxImagesAllowed,
          "showUploadIcon": true,
          "showDeleteOption":true,
          "uploaded": 0,
          "hasError": false
        };
        const fileAttr = this.fileArray[element.combinedAttributeName];
          
        this.editOppVal?.customAttributeValues.forEach(ele=>{
     
          if(ele.attributeId==element.id){
           
            if(ele.value?.length>0){
              for (let i = 0; i < ele.value.length; i++) {
                files.push(ele.value[i].name.replace(this.baseUrl, ""));                           
              }
              fileAttr.files = files;
              fileAttr.uploaded = files.length;
              this.updateFileControlValue(element.combinedAttributeName);

            }
          }
                          
          fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
        })
      }
              
      if(element.attributeType.id==9){
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
          // this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
           this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
        }else{
         
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
               
        }
        

        this.editOppVal?.customAttributeValues.forEach(ele=>{
          if(ele.attributeId==element.id){
            if(ele.value.length>0){
              if(element.parentAttributeId !==0){
                this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
              }
             this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
            }
          }
        })

      }
              
      if(element.attributeType.id==15){
         
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
               
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
      }else{

          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
      }

      let validDocTypes = [];
      let allowedDocText = [];
      if(element.documentType != null && element.documentType != ""){
        (element.documentType as string).split(",").forEach(x => {
          let type =this.documentTypeMap.get(+x);
          validDocTypes.push(type);
          allowedDocText.push(type.extension);
        });
      }
      else{
        for (const [key, value] of this.documentTypeMap.entries()) {
          validDocTypes.push(value);
          allowedDocText.push(value.extension);
        }
      }
                      
        let files = [];
        this.fileArray[element.combinedAttributeName] = {
          "attributeName": element.combinedAttributeName,
          "files": files,
          "documentIcons": [],
          "allowedDocText": allowedDocText.join(", "),
          "validDocType": validDocTypes,
          "maxUpload": element.maxImagesAllowed,
          "showUploadIcon": true,
          "showDeleteOption":true,
          "uploaded": 0,
          "hasError": false
        };
        const fileAttr = this.fileArray[element.combinedAttributeName];
          
        this.editOppVal?.customAttributeValues.forEach(ele=>{
     
          if(ele.attributeId==element.id){
           
            if(ele.value?.length>0){
              for (let i = 0; i < ele.value.length; i++) {
                files.push(ele.value[i].name.replace(this.baseUrl, ""));
                let fileType = ele.value[i].name.split('.').pop().toLowerCase();
                fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
              }
              fileAttr.files = files;
              fileAttr.uploaded = files.length;
              this.updateFileControlValue(element.combinedAttributeName);
            }
          }

          fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
        })
      }

      if(element.attributeType.id==16){
        if(element.mandatoryFlag==1 && element.parentAttributeId ===0){

          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
        } else{

          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }

        let files = [];
        this.fileArray[element.combinedAttributeName] = {
          "attributeName": element.combinedAttributeName,
          "files": files,
          "maxUpload": element.maxImagesAllowed,
          "showUploadIcon": true,
          "showDeleteOption":true,
          "uploaded": 0,
          "hasError": false
        };
        const fileAttr = this.fileArray[element.combinedAttributeName];
          
        this.editOppVal?.customAttributeValues.forEach(ele=>{
     
          if(ele.attributeId==element.id){
           
            if(ele.value?.length>0){
              for (let i = 0; i < ele.value.length; i++) {
                files.push(ele.value[i].name.replace(this.baseUrl, ""));
              }
              fileAttr.files = files;
              fileAttr.uploaded = files.length;
              this.updateFileControlValue(element.combinedAttributeName);
            }
            return;
          }
        })
        fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
        fileAttr.showUploadIcon=element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID?false:fileAttr.showUploadIcon;
      }

      if(element.attributeType.id==17){
        this.fileArray[element.combinedAttributeName] = {
          "attributeName": element.combinedAttributeName,
          "files": [],
          "documentIcons": [],
          // "allowedDocText": allowedDocText.join(", "),
          // "validDocType": validDocTypes,
          "maxUpload": element.maxImagesAllowed,
          "showUploadIcon": false,
          "uploaded": 0,
          "hasError": false
        };
        const fileAttr = this.fileArray[element.combinedAttributeName];

        (element.referenceDocumentUrl as Array<string>).forEach( file => {
          fileAttr.files.push(file);
          let fileType = file.split('.').pop().toLowerCase();
          const icon = this.documentIconUrlMap.get(fileType);
          fileAttr.documentIcons.push(icon ? icon : file);
        })

      }
      //disabling few dialerActivity field
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_TYPE_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_DATE_TIME_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_DURATION_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }

      //disabling few dialerActivity field
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_TYPE_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_DATE_TIME_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_DURATION_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }
      if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID){
        this.addCompaniesForm.get(element.combinedAttributeName).disable();
      }

          //Condition for Product-Form
          if(element.attributeType.id==13){
            if(element.allowPreviousForm === 1){
              this.addCompaniesForm.addControl("selectfrom" + element.combinedAttributeName, new FormControl({ value: '', disabled: true }));
            }
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormArray([]))
            if(this.workflowApprovalId){
            this.attributeIdVisiblityMap.set(element.id,this.attributeIdVisiblityMap.has(element.parentAttributeId)?this.attributeIdVisiblityMap.get(element.parentAttributeId):1);
            }
            if(element.parentFormId != null ){
              this.productFormFieldArray.push(element);
              this.productFormDisabled[element.combinedAttributeName] = this.isFieldDisable || 
                    (this.attributeIdVisiblityMap.has(element.id) && this.attributeIdVisiblityMap.get(element.id) == 1);
            }

      }

         
      //other conditions
      if(element.systemAttribute.id==11 || element.systemAttribute.id==5 || element.systemAttribute.id==25){
        this.systemAttribute11Array.push(element);  
      }
      if(element.systemAttribute.id==26)
      {
        this.systemAttributeopportunityArray.push(element);
      }

        //Condition for Data List
      if (element.attributeType.id == 18) {

        if ([...linkedDlMap.keys()].includes(+element.id)) {
          const linkedDataList = linkedDlMap.get(element.id);
          element.addDataListDependentFields = linkedDataList.addDataListDependentFields;
          element.childAttributeList = linkedDataList.childAttributeList;
          element.hasChildAttribute = linkedDataList.hasChildAttribute;
          element.lookupValues = linkedDataList.lookupValues;
          element.parentDataListId = linkedDataList.parentDataListId;
          element.hasChildAttribute = linkedDataList.hasChildAttribute;
        }
            
        if (element.mandatoryFlag == 1 && element.parentAttributeId === 0) {
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
        } else {
          this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
        }
        if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(element.combinedAttributeName).disable();
  
        }
        // START::setting value to main datalist field
        let controlValue = 0;
        this.editOppVal?.customAttributeValues.forEach(ele => {
  
          if (ele.attributeId == element.id) {

            if (ele.value.length > 0) {
              if (element.parentAttributeId !== 0) {
                this.newOpenDiv.push({ divId: this.createDivId(element), field: element });
              }
              this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
              controlValue = ele.value[0].id;

            }
          }
        });

        if (element.hasChildAttribute) {
          setTimeout(() => {
            this.invokeChildMethod(controlValue, element, '');
          }, 10);
        }
        // END::setting value to main datalist field

        if (element.childAttributeList?.length > 0) {
          element.childAttributeList.forEach(childElement => {
            // setting some proprties to child datalist fields for later use
            childElement.parentAttributeId = element.parentDataListId;
            childElement.parentCombinedAttributeName = element.combinedAttributeName;
            childElement.mandatoryFlag = element.mandatoryFlag;
            childElement.parentLinkedDataListAttribId = element.id;

            if (childElement.systemAttribute === null) {
              childElement.systemAttribute = {};
            }

            if (childElement.attributeType.id == 1) {
              if (childElement.mandatoryFlag == 1 && childElement.parentAttributeId === 0) {
                this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
              } else {
                this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl());
              }
              if(this.isFieldDisable||(this.attributeIdVisiblityMap.has(element.id)
        && this.attributeIdVisiblityMap.get(element.id)==1)){
          this.addCompaniesForm.get(childElement.combinedAttributeName).disable();
        
        }
              // START::setting value to child datalist field
              this.editOppVal?.customAttributeValues.forEach(ele => {

                if (ele.attributeId == childElement.id && ele.parentLinkedDatalistAttribId == childElement.parentLinkedDataListAttribId) {

                  if (ele.value.length > 0) {
                    this.newOpenDiv.push({ divId: this.createDivId(childElement), field: childElement });
                    this.addCompaniesForm.controls[childElement.combinedAttributeName].setValue(ele.value[0].id);
                  }
                }
              });
              // END::setting value to child datalist field
            }
          }
          )
        }
      }

      //CONDITION FOR CONTACTS LIST
      if(element.systemAttribute.id==6 || element.systemAttribute.id==27){
        this.systemAttribute6Array.push(element);  
      }
       //CONDITION FOR COMPANY OWNER LIST
      if(element.systemAttribute.id==34 || element.systemAttribute.id==35 || element.systemAttribute.id==65){

       this.systemAttributeOwnerArray.push(element);   
      }
      if(element.linkedAttributeId != null ){
                    
        if(element.linkedAttributeModuleId==3){             
          this.systemAttributeopportunityListArray.push(element);  
        }
        if(element.linkedAttributeModuleId==2){             
          this.systemAttributecompanyListArray.push(element);  
        }
        if(element.linkedAttributeModuleId==1){             
          this.systemAttributecontactsListArray.push(element);  
        }   

      }

      if(element.systemAttribute.id==28){
  
        if(element.mandatoryFlag==1){

          this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()],[Validators.required]));
          
        }else{
          this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()]));
        }
        

        this.myFormValueChanges$ = this.addCompaniesForm.controls['skuItems'].valueChanges;
   
       // this.myFormValueChanges$.subscribe(skuItems => this.updateItems(skuItems));
      }

    })

    if(this.systemAttributeproductsArray.length >0 || this.productFormFieldArray.length >0){
         
      await this.getAllProducts();

    }
    let customAttributesList = [];
        (responseData as Array<any>).forEach(field => {
            customAttributesList.push(field);
          if (field.attributeType.id == 18 && field.childAttributeList !== null && field.childAttributeList.length > 0) {
            customAttributesList.push(...field.childAttributeList);
          }
        });
        this.customAttributesList = customAttributesList.map(x => {
          x['isHidden'] = false;
          if(this.isCompleted) {
            x['isEdit'] = false;
          }else{
            x['isEdit'] = true;
          }
          return x;
        });
	
	let indexList: Array<number> = [];
        let previousFormActivityModules: Set<number> = new Set();
        customAttributesList.forEach((value, index) => {
          if (value.attributeType.id === 13 && value.parentFormId !== null && value.allowPreviousForm === 1) {
            indexList.push(index);
            previousFormActivityModules.add(value.previousFormActivity);
          }
        })

        indexList.reverse().forEach(x => {
          const objToBeAdd = this.getSelectFromFieldObj(customAttributesList[x]);
          const prevPfMapping = this.editOppVal.previousProductFormActivityMappingDTO
            .find(x => x.producFormAttributeid === objToBeAdd.previousFormAttribId);

            if(prevPfMapping.refActivityId!= null && prevPfMapping.refActivitySubmittedOn!= null){
            objToBeAdd.instructions = `Selected response submitted on ${prevPfMapping?.refActivitySubmittedOn}`;
            }
            
            customAttributesList.splice(x, 0, objToBeAdd);

          this.addCompaniesForm.get(objToBeAdd.combinedAttributeName).setValue(prevPfMapping ? prevPfMapping.refActivityId : null);

        }
        );
        this.customAttributesList = customAttributesList;
        previousFormActivityModules.forEach(moduleType => {
          this.getActivitySerialNo(this.selectedProject, moduleType);
        })

   this.getFieldValues();
   if(this.isActivityPage){
   this.setupfieldVisibilityConfigurationAndValue();
   }

  }
  
  getSelectFromFieldObj(customAttr) {
    return {
      "parentAttributeId": 0,
      "parentFormId": customAttr.parentFormId,
      "linkedAttributeId": null,
      "linkedAttributeModuleId": null,
      "isActive": 1,
      "attributeName": "Select From",
      "attributeIdentifierName": "company.selectfrom",
      "combinedAttributeName": "selectfrom" + customAttr.combinedAttributeName,
      "parentCombinedAttributeName" : customAttr.combinedAttributeName,
      "instructions": '',
      "systemAttribute": {},
      "mandatoryFlag": 0,
      "status": 1,
      "moduleType": 2,
      "attributeType": {
        "id": 1,
        "typeName": "Single Select Dropdown",
        "isActive": 1
      },
      "lookupValues": [],
      "dateAttributes": {},
      "fieldSpec": 1,
      "displayOrder": 7,
      "hasUniqueValues": 0,
      "characterType": 0,
      "minCharacters": null,
      "maxCharacters": null,
      "allowBrowsing": 0,
      "maxImagesAllowed": 0,
      "numericValidationType": 0,
      "minDigits": null,
      "maxDigits": null,
      "minNumber": null,
      "maxNumber": null,
      "calculationFormula": null,
      "calculationExpression": null,
      "documentType": "",
      "referenceDocumentUrl": [
        ""
      ],
      "isOptionsAlphabetically": 0,
      "otpValidation": 0,
      "decimalFlag": 0,
      "allowPreviousForm": 0,
      "previousFormActivity": null,
      "hasChildAttribute": false,
      "path": [],
      "childAttributeList": null,
      "parentLookupValues": null,
      "parentRelationList": null,
      "selectedParentFiledValues": null,
      "selectedDependentFields": null,
      "dataListLookupValues": null,
      "parentDataListId": null,
      "addDataListDependentFields": null,
      "showAllDataListValues": null,
      "isHidden": customAttr.isHidden,
      "isEdit": false,
      "previousFormActivityModule": customAttr.previousFormActivity,
      "previousFormAttribId": customAttr.id,
      "isSelectFromField": true
    }
  }

  getActivitySerialNo(projectId, moduleType){
    this._customAttrService.getActivitySerialNo(projectId, moduleType).subscribe(
      res => {
        let lookup = (res['responseData'] as Array<any>).map(x=>{
          return {id: x.activityid , lookupValue : x.serialKey, submittedOn : x.submittedOn};
        });
        this.customAttributesList.forEach(res=>{
          if(res.previousFormActivityModule === moduleType){
            res.lookupValues = lookup;
          }
        })
    
      }
    )

  }

  setupfieldVisibilityConfigurationAndValue(){
    if(this.moduleType==SystemAttributeEnum.CONTACT_MODULE || 
      this.moduleType==SystemAttributeEnum.COMPANY_MODULE || 
      this.moduleType==SystemAttributeEnum.OPPORTUNITY_MODULE || 
      this.moduleType>=SystemAttributeEnum.ACTIVITY_MODULE) {
        this.fieldVisibilityConfig.forEach(fieldsconfig => {
          this.customAttributesList.forEach(fields => {
            if (fieldsconfig.attributeId == fields.id) {
              if (fieldsconfig.fieldVisibility == 1){
                if(!this.fieldVisibilityNotAttributeIds.includes( fields.attributeType.id)){
                    this.setupFieldVisibilityforHidden(fields,fieldsconfig);
                }  
              } else if (fieldsconfig.fieldVisibility == 2 && !this.fieldVisibilityNotAttributeIds.includes( fields.attributeType.id)) {
                this.setupFieldVisibilityforViewOnly(fields,fieldsconfig);
              }
            }
          })
        })
      }
  }

  setupFieldVisibilityforHidden(fields:any,fieldsconfig:any){
    if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId) {
      if (fieldsconfig.parentDataListId == fields.parentLinkedDataListAttribId) {
        if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);}
        fields['isHidden'] = true;
      }
    } else if (fields.attributeType.id == 1 && [15, 16, 21].includes(fields.systemAttribute.id)) {
      if(!this.addCompaniesForm.controls['countryName'].value){
      const countryStateCityVals = (fieldsconfig.value as string).split("->");
      this.addCompaniesForm.controls['countryName'].setValue(countryStateCityVals[0]);
      this.getAllStates(countryStateCityVals[0].split('@')[1]);
      this.addCompaniesForm.controls['stateName'].setValue(countryStateCityVals[1]);
      this.getAllCities(countryStateCityVals[1].split('@')[1]);
      this.addCompaniesForm.controls['cityName'].setValue(countryStateCityVals[2]);
      }
      fields['isHidden'] = true;
    }else if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId == null) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);}
      fields['isHidden'] = true;
    }else if (fields.attributeType.id == 2) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(Number));}
      fields['isHidden'] = true;
    } else if (fields.attributeType.id == 4 || fields.attributeType.id == 15 || fields.attributeType.id == 16) {
      if(this.fileArray[fields.combinedAttributeName].files.length==0){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(String));
      let files = [];
      const fileAttr = this.fileArray[fields.combinedAttributeName];
      let fileValues = fieldsconfig.value.split(',').map(String);
      if (fileValues?.length > 0) {
        for (let i = 0; i < fileValues.length; i++) {
          files.push(fileValues[i]);
          fileAttr.files = files;
          fileAttr.uploaded = files.length;
          if (fields.attributeType.id == 15) {
            let fileType = fileValues[i].split('.').pop().toLowerCase();
            fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
          }
        }
      }
    }
    fields['isHidden'] = true;
    } else if (fields.attributeType.id == 18) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);
      if(fields.parentAttributeId === 0 && fields.hasChildAttribute) {
          this.invokeChildMethod(fieldsconfig.value, fields,'');
      }
    }
    fields['isHidden'] = true;
    }
    else {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value);}
      fields['isHidden'] = true;
    }
  }



  setupFieldVisibilityforViewOnly(fields:any,fieldsconfig:any){
    if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId) {
      if (fieldsconfig.parentDataListId == fields.parentLinkedDataListAttribId) {
        if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));}
        this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
        fields['isEdit'] = false;
      }
    } else if (fields.attributeType.id == 1 && [15, 16, 21].includes(fields.systemAttribute.id)) {  
      if(!this.addCompaniesForm.controls['countryName'].value && fieldsconfig.value){
      const countryStateCityVals = (fieldsconfig.value as string).split("->");
      this.addCompaniesForm.controls['countryName'].setValue(countryStateCityVals[0]);
      this.getAllStates(countryStateCityVals[0].split('@')[1]);
      this.addCompaniesForm.controls['stateName'].setValue(countryStateCityVals[1]);
      this.getAllCities(countryStateCityVals[1].split('@')[1]);
      this.addCompaniesForm.controls['cityName'].setValue(countryStateCityVals[2]);
      }
      this.addCompaniesForm.controls['countryName'].disable();
      this.addCompaniesForm.controls['stateName'].disable();
      this.addCompaniesForm.controls['cityName'].disable();
      fields['isEdit'] = false;
    }else if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId == null) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));}
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    } else if (fields.attributeType.id == 2 ) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(Number));}
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    } else if (fields.attributeType.id == 4 || fields.attributeType.id == 15 || fields.attributeType.id == 16) {
      this.fileArray[fields.combinedAttributeName].showUploadIcon = false;
      this.fileArray[fields.combinedAttributeName].showDeleteOption = false;
      if(this.fileArray[fields.combinedAttributeName].files.length==0 && fieldsconfig.value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(String));
        let files = [];
        const fileAttr = this.fileArray[fields.combinedAttributeName];
        let fileValues = fieldsconfig.value.split(',').map(String);
        if (fileValues?.length > 0) {
          for (let i = 0; i < fileValues.length; i++) {
            files.push(fileValues[i]);
            fileAttr.files = files;
            fileAttr.uploaded = files.length;
            fileAttr.showUploadIcon = false;
            fileAttr.showDeleteOption = false;
            if (fields.attributeType.id == 15) {
              let fileType = fileValues[i].split('.').pop().toLowerCase();
              fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
            }
          }
        }
        this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
        fields['isEdit'] = false;
      }else{
        this.fileArray[fields.combinedAttributeName].showUploadIcon = false;
        this.fileArray[fields.combinedAttributeName].showDeleteOption = false;
      }
    } else if (fields.attributeType.id == 18) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);
      if(fields.parentAttributeId === 0 && fields.hasChildAttribute) {
        this.invokeChildMethod(fieldsconfig.value, fields,'');
    }
  }
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    }
    else {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value);}
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    }
  }
  getFieldValues(){    
      // this.showDivs(); -> it's no longer needed as new implemented changes in invokeChildMethod() will handel this functionality with some differences
      if(this.systemAttribute11Array.length >0){
      
      //if EditApprovalComponent opened from companyDashboard
        if(this.isFromCompanyDashboard){
          const removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
          if(removeIndex >-1){
            this.customAttributesList[removeIndex].lookupValues=[{id:this.companyInfoData?.companyId,lookupValue:this.companyInfoData?.name}];
          }
        }else{
          this.getAllCompanies();
        }
        
 
      }
 
      if(this.systemAttribute6Array.length >0){
        
        this.getAllContacts();
 
      }
 
      if(this.systemAttributeopportunityArray.length >0){
        
        this.getAllOpportunities(true);

      }

      if(this.systemAttributeopportunityListArray.length >0){
 
        if(this.opportunityId && this.flag=="opportunity"){
         
          this.getAllOpportunitiesDependentInputs(this.opportunityId);

        }
        else{
          this.systemAttributeopportunityListArray.forEach(rep=>{
            if(rep.systemAttribute.id !==26){
              var divId='div_'+rep.id;
              var division1=<HTMLElement>document.getElementById(divId);
              if(division1){            
                division1.classList.add('hidden');
              }
            }
           
          })        
        }
      }

      if(this.systemAttributecompanyListArray.length >0){
 
        if(this.opportunityId && this.flag=="company"){
          this.getAllCompanyDependentInputs(this.opportunityId);

        }
      }



      if(this.systemAttributeproductsArray.length >0){
           
        this.getAllProducts();

      }

      if(this.systemAttributeEstimateArray.length >0){
        
        this.getAllEstimates();

      }

      if(this.systemAttributeStatusArray.length >0){
   
        this.setStatus();

      }

      if(this.systemAttributeOwnerArray.length >0){
        
        this.getAllOwner();

      }
  }

  showDivs(){
    setTimeout(() => {
      this.newOpenDiv.forEach(res => {
        var division1 = <HTMLElement>document.getElementById(res.divId);

        if (division1) {
          const field = res.field;
          this.addValidators(field.combinedAttributeName, field.mandatoryFlag === 1, field.attributeType.id, res.decimalFlag === 1);  
          division1.classList.remove('hidden');
        }
      });
    }, 10);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    let control = null;
    if(controlName=="action") {
      control = this.actionForm.controls[controlName];
    } else if(controlName=="remarks" && this.currentRemarksId==3){
        this.remarksForm.get('remarks').setValidators(Validators.required);
        this.remarksForm.get('remarks').updateValueAndValidity();
      control = this.remarksForm.controls[controlName];
     } else if(controlName=="remarks" && this.currentRemarksId==2){
      this.remarksForm.controls[controlName].clearValidators();
      this.remarksForm.controls[controlName].updateValueAndValidity();
      return false;
     } 
    else {
      control = this.addCompaniesForm.controls[controlName];
    }
    if (!control) {
      return false;
    }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isFormArrayControlHasError(controlName: string,formArrayControlName,formArrayIndex, validationType: string): boolean {

      const control =<FormArray>this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    const formArrayControl=control.at(formArrayIndex).get(formArrayControlName)
      const result = formArrayControl.hasError(validationType) && (formArrayControl.dirty || formArrayControl.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.addCompaniesForm.reset();
  }
/*
  clickImage(id){
    let element:HTMLElement =document.getElementById(id) as HTMLElement;
    element.click();
  }
  isError(val){

  }
  */
  AddCompany(controls){
    let approvalData ={
      "approvalId": this.approvalId,
      "approvalAction": this.actionForm.get('action').value,
      "approvalRemarks":this.remarksForm.get('remarks').value,
      "projectId": this.selectedProject,
      "allowEditing": this.isAdvanceSettingEnabled ? 1 : 0,
    }
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;


      if((element.attributeType.id==1 ||
        element.attributeType.id==2 ||
        element.attributeType.id==5 ||
        element.attributeType.id==6 ||
        element.attributeType.id==20 ||
        element.attributeType.id==7 || 
        element.attributeType.id==8 || 
        element.attributeType.id==9 ||
        element.attributeType.id==18) &&
         (element.systemAttribute.id!==16) &&
          (element.systemAttribute.id!==15) &&
           (element.systemAttribute.id!==21)){
            if(element.isSelectFromField) {
              return;
            }
       
        this.customAttributeValues.push({
          "attributeId":element.id,
          "parentLinkedDatalistAttribId": element.moduleType==6 ? element.parentLinkedDataListAttribId : null,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
            
          ]
          
        })
      }else{
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
          var country= this.addCompaniesForm.controls.countryName['value'];
          var state=this.addCompaniesForm.controls.stateName['value'];
          var city = this.addCompaniesForm.controls.cityName['value'];
          var address2='';
          if(country!=null && state!=null && city!=null){
            address2=(country.trim()+'->'+state.trim()+'->'+city.trim());
          }else if(country!=null && state==null && city==null){
           address2=(country.trim());
          }else if(country!=null && state!=null && city==null){
            address2=(country.trim()+'->'+state.trim());
          }else{
           address2='';
          }
        
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':address2
               }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }          
            
          ]
          
        })
      }

      if(element.attributeType.id==21 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']?.replace('T', ' ')
            }                      
          ]          
        })
      }
      if(element.attributeType.id==22 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']
            }                      
          ]          
        })
      }

      if(element.attributeType.id==4 || element.attributeType.id==15 || element.attributeType.id==16){

        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          this.addCompaniesForm.controls[id]['value']?.forEach(resultImage=>{
            resultArrayOfImages.push(
              {
                'name':resultImage
              }
            )
          })
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages
            
          })       
        }
      }

      if(element.attributeType.id==13 && element.parentFormId){
        this.skuItemsArray = {};
        let k=0;
            
        const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
        var length=this.addCompaniesForm.controls[element.combinedAttributeName]?.value.length||0;

        for(var i=0;i<length;i++){

           let productFormData = {};
           let productRow = [];

          for(let j=0; j < this.productFormFields[element.combinedAttributeName].fields?.length; j++){
            const column = this.productFormFields[element.combinedAttributeName].fields[j];

            if(column.attributeType.id == 4){
              productFormData = {
                attributeId: column.id,
                value: (this.fileArray[element.combinedAttributeName + column.combinedAttributeName + i].files as Array<string>)?.join(',')
              };
            }
            else{
              productFormData = {
                attributeId: column.id,
                value: control.at(+i).get(column.combinedAttributeName).value
              };
            }

            if(productFormData['value'] !== null && productFormData['value'] !== '' ){
              productRow.push(productFormData);
            }
          }
           k=k+1;
           this.skuItemsArray[k]=productRow;
        }

        let summaryLevelDiscount={};
        let value1= this.productFormFields[element.combinedAttributeName]?.discountValue||0;
        let decimalValue=(Math.round(Number(value1)*100)/100).toFixed(2);
        let value2= this.productFormFields[element.combinedAttributeName]?.discountPercent||0;
        let decimalPercent=(Math.round(Number(value2)*100)/100).toFixed(2);
        summaryLevelDiscount={
          "value":decimalValue,
          "percent": decimalPercent
        }
        this.skuItemsArray["summaryDiscount"]=summaryLevelDiscount;

        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[this.skuItemsArray]  
        })
      }

    })


    function formatDate(date) {
      if(date == null || date == "")
        return "";
      else {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
      }
    }

    var addData;

    if(this.moduleType==1){
      addData = {
        "contactId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "transTimeZone":this.userLocalization.getUserTimeZoneCode(),
        "customAttributeValues": this.customAttributeValues
       
      };
      if(this.isAdvanceSettingEnabled){
        approvalData['contactRequestDTO'] = addData;
      }
    } else if(this.moduleType==2){
      addData = {
        "companyId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "transTimeZone":this.userLocalization.getUserTimeZoneCode(),
        "customAttributeValues": this.customAttributeValues
  
      };
      if(this.isAdvanceSettingEnabled){
        approvalData['companyRequestDTO'] = addData;
      }
    } else if(this.moduleType==3){
      addData = {
        "opportunityId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "transTimeZone":this.userLocalization.getUserTimeZoneCode(),
        "customAttributeValues": this.customAttributeValues
  
      };
      if(this.isAdvanceSettingEnabled){
        approvalData['opportunityRequestDTO'] = addData;
      }
    } else if(this.moduleType>=100){
      addData = {
        "activityId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "moduleType": this.moduleType,
        "planId":this.planId,
        "userTime":this.userLocalization.getUserTime(),
        "transTimeZone":this.userLocalization.getUserTimeZoneCode(),
        "customAttributeValues": this.customAttributeValues  

      };
      if(this.isActivityPage){
          return addData;
      }
      if(this.isAdvanceSettingEnabled){
        approvalData['activityRequestDTO'] = addData;
      }
    }
    else if(this.moduleType==4){
      addData = {
        "productId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "userTime":this.userLocalization.getUserTime(),
        "transTimeZone":this.userLocalization.getUserTimeZoneCode(),
        "customAttributeValues": this.customAttributeValues  
      };
    } else{
        console.log("No addData");
    }
    
    return approvalData;
  }

  Save(){
    if(!this.isEditAllowed) return;
    this.disabled=true;
    this.errorFlag=false;
    
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      var message='';
      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        var min = element.minDigits;
        var max = element.maxDigits;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
          if(element.decimalFlag) {
            elementValue = this.extractWholeNumber(elementValue);
          }
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue.toString().length)){
                this.errorFlag=true;
                message='Enter number greater than '+Number(min) +' digits for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          else if(max!="" && max!=null){
              if(Number(elementValue.toString().length) > Number(max)){
                this.errorFlag=true;
                message='Enter number less than '+Number(max) +' digits for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }else if((min!=null && max!=null) && ((min!="0" || max!="0") || (min!=0 || max!=0))){
            if((Number(min) > Number(elementValue.toString().length)) || (Number(elementValue.toString().length) > Number(max))){
              this.errorFlag=true;
              message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          }
        }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
       
        var min = element.minNumber;
        var max = element.maxNumber;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
        //if(min!="" && max!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue)){
                this.errorFlag=true;
                message='Enter value greater than '+Number(min) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          if(max!="" && max!=null){
              if(Number(elementValue) > Number(max)){
                this.errorFlag=true;
                message='Enter value less than '+Number(max) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          if(min!=null && max!=null){
            if((Number(min) > Number(elementValue)) || (Number(elementValue) > Number(max))){
              this.errorFlag=true;
              message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          } 
      }
    }

    // image validation section             
    if(element.attributeType.id === 4 && element.maxImagesAllowed != 0 ){      
      const fileAttr = this.fileArray[element.combinedAttributeName];
      if(fileAttr.files.length > element.maxImagesAllowed){
        fileAttr.hasError = true;
        fileAttr.errorMsg = `Maximum ${element.maxImagesAllowed} image(s) allowed`;
        this.errorFlag = true;
      }      
    }

    })
    let isInvalid = false;
    const controls = this.addCompaniesForm.controls;
    if (this.addCompaniesForm.invalid) {
      Object.keys(controls).forEach(controlName =>{
          controls[controlName].markAsTouched();
          if((controls[controlName] as FormArray).controls){
            if((controls[controlName] as FormArray).controls.length>0){
              const grps = <FormGroup[]>(controls[controlName] as FormArray).controls;
              grps.forEach(g => {
                Object.keys(g.controls).forEach( name => {
                  g.controls[name].markAsTouched();
                });
              })
            }
          }
      }
        );
        this.disabled=false;
        isInvalid = true;
    }
    if (this.actionForm.invalid) {
      Object.keys(this.actionForm.controls).forEach(controlName =>
        this.actionForm.controls[controlName].markAsTouched()
        );
        this.disabled=false;
        isInvalid = true;
    }
    
    if (this.remarksForm.invalid && this.currentRemarksId==3) {
      Object.keys(this.remarksForm.controls).forEach(controlName =>
        this.remarksForm.controls[controlName].markAsTouched()
        );
        this.disabled=false;
        isInvalid = true;
    }
    
    if(this.errorFlag || isInvalid){
      this.disabled=false;
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }
    

    const addData=this.AddCompany(controls);
    //  return;
    if(this.isActivityPage){
      this._customAttrService.add(addData,this.moduleType).subscribe(

        data => {
          this.addCompaniesForm.reset();
          this.passEntry.emit('SUCCESS');
          this.activeModal.close('SUCCESS');
          this.eventEmitterService.onModalCloseResult('SUCCESS');
          
        },
        error => {
          this.disabled=false;
          this.errorMes=error.error.message;
         
          this.eventEmitterService.onModalCloseResult('ERROR');
        });
    }
    else{
      this.approvalService.updateApproval(addData).subscribe(

        data => {
          
          this.addCompaniesForm.reset();
          this.passEntry.emit('SUCCESS');
          this.activeModal.close('SUCCESS');
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        },
        error => {
          this.disabled=false;
          this.errorMes=error.error.message;
         
          this.eventEmitterService.onModalCloseResult('ERROR');
        }
  
       );

    }
    

    // this._customAttrService.add(addData,this.moduleType).subscribe(
      
    //   data => {

    //     this.addCompaniesForm.reset();
    //     this.passEntry.emit('SUCCESS');
    //     this.activeModal.close('SUCCESS');
    //     this.eventEmitterService.onModalCloseResult('SUCCESS');
        
    //   },
    //   error => {
    //     this.disabled=false;
    //     this.errorMes=error.error.responseData.errorMsg;
    //     console.log(this.errorMes);
    //     this.eventEmitterService.onModalCloseResult('ERROR');
    //   }
        
    // );
  }

  invokeChildMethod(lookupvalue, customAttr, editdata){
    if (!lookupvalue) {
      lookupvalue = 0;
    }
    
    let attributeId=customAttr.id;
    let parentLinkedDataListAttribId = 0;
    
    // Datalist child field condition
    if (customAttr.attributeType.id == 18) {
      attributeId = customAttr.parentDataListId;
      parentLinkedDataListAttribId = customAttr.id;
    } 
    else if (customAttr.moduleType == 6) {
      parentLinkedDataListAttribId = customAttr.parentLinkedDataListAttribId;
    } else if (customAttr.attributeType.id === 1 && customAttr.linkedAttributeId !== null) {
      attributeId = customAttr.linkedAttributeId;
    }
    
    if(customAttr.systemAttribute && customAttr.systemAttribute.id == 25){ // for select customer field
     this.companyId=lookupvalue;
     this.getAllOpportunities();
     this.getAllCompanyDependentInputs(lookupvalue);
    }
    if(customAttr.systemAttribute && customAttr.systemAttribute.id == 26){
      this.getAllOpportunitiesDependentInputs(lookupvalue);
    }
    
    if(customAttr.systemAttribute && customAttr.systemAttribute.id == 25){ // for select customer field
     this.companyId=lookupvalue;
     this.getAllOpportunities();
     this.getAllCompanyDependentInputs(lookupvalue);
    }
    if(customAttr.systemAttribute && customAttr.systemAttribute.id == 26){
      this.getAllOpportunitiesDependentInputs(lookupvalue);
    }
    
    //deleting previous product form if it is dependent on other field having no child(attributeId)
    if( this.previousDependentProductFormArray.length>0){
      this.dependentProductFormDeleteOrAdd(attributeId, null,lookupvalue,false);
    }

    //cal  api
    this.showSpinner();
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{        
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          });
          let childAttribDivIds = [];

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{

              //for pending and completed approval
              if(this.workflowApprovalId){
                //for dataList child attribute
                if (customAttr.attributeType.id == 18) {
                  this.attributeIdVisiblityMap.set(element.id,this.attributeIdVisiblityMap.has(parentLinkedDataListAttribId)?this.attributeIdVisiblityMap.get(parentLinkedDataListAttribId):1);
                }else{
                  this.attributeIdVisiblityMap.set(element.id,this.attributeIdVisiblityMap.has(attributeId)?this.attributeIdVisiblityMap.get(attributeId):1);
                } 
              }
              //logic for product form if it is dependent on other field(attributeId)
              if(element.parentFormId!=null || this.previousDependentProductFormArray.length>0){
                this.dependentProductFormDeleteOrAdd(attributeId, element,lookupvalue,true);
              }
              if(this.isFieldDisable||(this.attributeIdVisiblityMap.get(attributeId)==1)){
                          this.addCompaniesForm.get(element.combinedAttributeName)?.disable();
                        }
              else if(this.attributeIdVisiblityMap.get(attributeId)==2){
                this.addCompaniesForm.removeControl(element.combinedAttributeName);
                this.customAttributesList.splice(this.customAttributesList.find(x => x.id === element.id),1)
              }
              
              if(customAttr.linkedAttributeId!==null) {
                const linkedField = this.customAttributesList.find(x => x.linkedAttributeId === element.id);
                if(linkedField) {
                  element.combinedAttributeName = linkedField.combinedAttributeName;
                  element.mandatoryFlag = linkedField.mandatoryFlag;
                }
              }    
              // update combined attribute name and mandatoryFlag info for datalist child fields
              if (customAttr.attributeType.id == 18) {
                element.combinedAttributeName = customAttr.combinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }    
              else if(customAttr.moduleType == 6 && customAttr.parentCombinedAttributeName) {
                element.combinedAttributeName = customAttr.parentCombinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }
              
              if(editdata){
                editdata.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
      
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    
                    }
                  }
                })
              }
              
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
            
              // var divId='div_'+element.id;
              var divId = this.createDivId(element, customAttr.linkedAttributeId!==null, this.customAttributesList);
              childAttribDivIds.push(divId);

              if(removeIndex >-1){
               
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item.divId; }).indexOf(String(divId));
                

                if(removeIndexInside >-1){
                  
                  //yes exists then no need to do anything
                }else{
                  
                  this.openDivs[removeIndex].value.push({
                    divId: divId, combinedAttributeName: element.combinedAttributeName, 
                    attributeTypeId: element.attributeType.id, 
                    id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                  });
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                           
                    this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                    division1.classList.remove('hidden');
                  } 
                }
                               

              }else{
                
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    {
                      divId: divId, combinedAttributeName: element.combinedAttributeName, 
                      attributeTypeId: element.attributeType.id, 
                      id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId}
                  ] 
                })

                var division1=<HTMLElement>document.getElementById(divId);
                
                if(division1){            
                           
                  this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                  division1.classList.remove('hidden');
                }               
          
              } 

              const childAttrib = this.findChildAttribByIdAndCombinedName(element.id, element.combinedAttributeName, customAttr.linkedAttributeId!==null, customAttr.attributeType.id);
            
              if (childAttrib) {
                
                childAttrib.lookupValues = element.lookupValues;
                if (childAttrib.attributeType.id === 1) { // following lines will execute for single select only as only single selects may have child fields
                  if (element.lookupValues.find(x => x.id == this.addCompaniesForm.get(childAttrib.combinedAttributeName)?.value)) {
                    this.invokeChildMethod(this.addCompaniesForm.get(childAttrib.combinedAttributeName).value, childAttrib, '');
                  }
                  else {
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib);
                  }
                }
              }

            });
            // ----------------> START:: hiding the divs of child attrib when such a value selected for parent attrib 
            // where there's no relation mapping with child attrib
            var removeIndex = this.openDivs.map(function (item) { return item.attributeId; }).indexOf(attributeId);
            let childAttribsToKeep = [];
            
            if (removeIndex > -1) {
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res => {
                if (parentLinkedDataListAttribId===res.parentLinkedDataListAttribId && !childAttribDivIds.includes(res.divId)) {
                  var division = <HTMLElement>document.getElementById(res.divId);
                  if (division) {
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null, customAttr.attributeType.id);
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                } else {
                  childAttribsToKeep.push(res);
                }
              });
            };
            this.openDivs[removeIndex].value = childAttribsToKeep;
            // <---------------- END
          }else{
            // -> hiding the divs of child attrib whose relationships doesn't exist anymore
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
        
            let markToRemovedChildDivIds: string[] = [];
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                if(res.parentLinkedDataListAttribId===parentLinkedDataListAttribId) { // proceed only if parentLinkedDataListAttribId is matched
                  markToRemovedChildDivIds.push(res.divId);
                  var division=<HTMLElement>document.getElementById(res.divId);
                  if(division){      
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null, customAttr.attributeType.id);        
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                }
              });
              //remove key an pairs
              if(this.openDivs[removeIndex].value) {
                if(this.openDivs[removeIndex].value.length === markToRemovedChildDivIds.length) { // remove the whole
                  this.openDivs.splice(removeIndex, 1);           
                }
                else if(this.openDivs[removeIndex].value.length !== markToRemovedChildDivIds.length) { // remove only the mark for remove ones
                  this.openDivs[removeIndex].value = this.openDivs[removeIndex].value.filter(x => !markToRemovedChildDivIds.includes(x.divId));
                }
              }            
              // <- END      
            }
          }
        }
        this.hideSpinner();
      }
    )
  }

  findChildAttribByIdAndCombinedName(id, combinedAttributeName, isLinkedField, attributeType: number) {
    if(isLinkedField  && attributeType !== 18) {
      return this.customAttributesList.find(item => item.linkedAttributeId == id);
    }
    return this.customAttributesList.find(item => item.id == id && item.combinedAttributeName == combinedAttributeName);
  }

  resetChildAttribsAndinvokeChildMethod(childAttrib, clearValidatorsFlag = false) {
    //  -> it will reset child attrib and invoke child method for the same to remove the grand child attribs
    const control = this.addCompaniesForm.get(childAttrib.combinedAttributeName);
    if(control){
      control.reset();
      if(clearValidatorsFlag) {
        control.clearValidators()
        control.updateValueAndValidity();
      }
      if([4, 15, 16].includes(childAttrib.attributeType.id)) {
        const selectedFileLength = this.fileArray[childAttrib.combinedAttributeName].files.length;
        for (let index = 0; index < selectedFileLength; index++) {
          this.removeFile(childAttrib.combinedAttributeName, 0)
        }
      }
    }
    if(childAttrib.attributeType.id == 1){
      this.invokeChildMethod(0, childAttrib, '');
    }
  }

  createDivId(customAttr, isLinkedField: boolean = false, fieldList: any[] = []): string {
    if(isLinkedField) {
      const linkedField = fieldList.find(x => x.linkedAttributeId === customAttr.id);
      if(linkedField) {
        return 'div_' + linkedField.combinedAttributeName + '_' + linkedField.id;
      }
    }
    return 'div_' + customAttr.combinedAttributeName + '_' + customAttr.id;
  }

  showSpinner() {
    if( this.spinnerCount++ === 0) {
      this.SpinnerService.show('addCompany');
    }
  }

  hideSpinner() {
    if( --this.spinnerCount === 0) {
      this.SpinnerService.hide('addCompany');
    }
  }
  
  addValidators(combinedAttributeName: string, mandatoryFlag: boolean, attributeTypeId: number, decimalFlag: boolean){
    if([10, 11, 17].includes(attributeTypeId)){
      return;
    }
    let validatorFns: ValidatorFn[] = [];
    if(mandatoryFlag){
      validatorFns.push(Validators.required);
    }
    if(attributeTypeId == 7){
      let pattern=this.numericPattern;
      if(decimalFlag) {
        pattern = this.decimalPattern;
      }
      validatorFns.push(Validators.pattern(pattern));
    }
    else if(attributeTypeId == 9){
      validatorFns.push(Validators.email);
    }
    const control = this.addCompaniesForm.get(combinedAttributeName);
    control?.setValidators(validatorFns);
    control?.updateValueAndValidity();
  }

  checkFileType(file, attributeTypeId, validDocTypes) {
    if(attributeTypeId == 4){
      return file.type.match('image/jpeg|image/png');
    }
    if(attributeTypeId == 15){
      for(let type of validDocTypes){
        if(file.type.match(type.matchString)){
          return true;
        }
      }
      return false;
    }
    if(attributeTypeId == 16){
      return file.type.match('video/mp4|video/quicktime|audio/mpeg');
    }
    else{
      return false;
    }
  }

  getFileErrorMsg(attribTypeId, allowedDocs){
    if(attribTypeId == 4){
      return 'Only .jpeg, .png files allowed';
    }
    if(attribTypeId == 15){
      return `Only ${allowedDocs} files allowed`;
    }
    if(attribTypeId == 16){
      return 'Only .mp4, .mov, mp3 files allowed';
    }
    return 'Error';
  }

  getSlicedErrorFileName(name: string){
    if(name.length <=20){
      return name;
    }
    return ". . . ." + name.slice(-20);
  }

  checkUploadQueue(attr, event, updateControl: boolean = true){
    if(--this.uploadQueue[attr] == 0){
      this.SpinnerService.hide(attr);
      if(updateControl)
        this.updateFileControlValue(attr);
      
      event.target.value='';
      delete this.uploadQueue[attr];
      if(Object.keys(this.uploadQueue).length == 0){
        this.disabled = false;
      }
    }
  }

  updateFileControlValue(attr){
    this.addCompaniesForm.controls[attr].setValue(this.fileArray[attr].files);
  }

  processFile(event, attribName, attributeTypeId, maxImage, fieldCombinedAttributeName: string = '',formArrayIndex:string='') {
    const arrayAttribName=fieldCombinedAttributeName+formArrayIndex;
    const initialFileCount = event.target.files.length;
    if (initialFileCount > 0) {
      this.disabled = true;
      this.SpinnerService.show(attribName+arrayAttribName);
      const fileAttr = this.fileArray[attribName+arrayAttribName];
      fileAttr.hasError = false;
      this.uploadQueue[attribName+arrayAttribName] = initialFileCount;

      let uploadedFilesCount = fileAttr.files.length;
      for (let i = 0; i < initialFileCount; i++) {
        if ( !maxImage || uploadedFilesCount < maxImage) {
          if (this.checkFileType(event.target.files[i], attributeTypeId, fileAttr.validDocType)) {
            if(attributeTypeId == 4){
              this.subscriptions.push(this._us.uploadImage(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
  
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
  
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                if(arrayAttribName!=null && arrayAttribName!='' && (formArrayIndex!=null && formArrayIndex.toString()!='')){
                  const control = <FormArray>this.addCompaniesForm.controls[attribName];
                  control.at(+formArrayIndex).get(fieldCombinedAttributeName).setValue(fileAttr.files);
                }
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if(attributeTypeId == 15){
              this.subscriptions.push(this._docUpload.uploadDoc(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let fileType = res.responseData.name.split('.').pop().toLowerCase();
                fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
  
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
  
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if(attributeTypeId == 16){
              if(event.target.files[i].size < 26214400){
                this.subscriptions.push(this._docUpload.uploadDoc(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                  fileAttr.files.push(res.responseData.name);
                  let uploadCount = fileAttr.files.length;
                  fileAttr.uploaded = uploadCount;
    
                  fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                },
                  error => {
                    fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                    fileAttr.hasError = true;
                    this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                  })
                );
              }
              else{
                fileAttr.errorMsg = 'File size limit 25 MB';
                fileAttr.hasError = true;
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
              }
            }
              // this.subscriptions.push(fileUploadSubscription);
            uploadedFilesCount++;
          } else {
            fileAttr.errorMsg = this.getFileErrorMsg(attributeTypeId, fileAttr.allowedDocText);
            fileAttr.hasError = true;
            this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
          }
        } else {
          this.uploadQueue[attribName+arrayAttribName] -= (initialFileCount-i-1);
          this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
          break;
        }
      }
    }
  }

  removeFile(attr, id, fieldCombinedAttributeName:string = '',formArrayIndex:string=''){
    const arrayAttribName=fieldCombinedAttributeName+formArrayIndex;
    const fileAttr = this.fileArray[attr + arrayAttribName];
    if(fileAttr){
      fileAttr.files.splice(id, 1);
      fileAttr.documentIcons?.splice(id, 1);
      let uploadCount = fileAttr.files.length;
      fileAttr.uploaded = uploadCount;
      fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    }
      fileAttr.hasError = false;
      arrayAttribName === '' ? this.updateFileControlValue(attr) : this.updateFormArrayControlValue(attr,fieldCombinedAttributeName,formArrayIndex);
}

updateFormArrayControlValue(combinedAttribName,fieldCombinedAttributeName,formArrayIndex){
    const control = <FormArray>this.addCompaniesForm.controls[combinedAttribName];
    const fileAttr = this.fileArray[combinedAttribName+fieldCombinedAttributeName+formArrayIndex];
    control.at(+formArrayIndex).get(fieldCombinedAttributeName).setValue(fileAttr.files);
}

  showImageModalOnly(imgURL){
    const ref = this.modalService.open(ImageViewerComponent,
      {
        centered: false,
        size : 'xl'
      }
    );
    ref.componentInstance.dataString = imgURL.replace(this.baseUrl, '');

    ref.result.then((result) => {
     // this.modalResult(result);
    });
  }

  showRefDocModal(attr, id){
    const fileAttr = this.fileArray[attr];
    if(fileAttr){
      let fileType = fileAttr.files[id].split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      if(icon){
        window.open(fileAttr.files[id]);
      }
      else{
        this.showImageModalOnly(fileAttr.files[id]);
        // const ref = this.modalService.open(ImageViewerComponent,
        //   {
        //     centered: false,
        //     size : 'xl'
        //   }
        // );
        // ref.componentInstance.dataString = fileAttr.files[id].replace(this.baseUrl, '');
    
        // ref.result.then((result) => {
        //  // this.modalResult(result);
        // });
      }
    }
  }

  OpenAlertValidation(message){
    const initialNotice = message;
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       width: '600px',
       position: {top: '20px'},
       data: { title: 'Alert', body: initialNotice }
     });
  }
  

  getAction() {
    if(!this.approvalId)
    {
      this.actionType=[];
      return;
    }
    this.subscriptions.push(this.approvalService.getAction(this.selectedProject,this.approvalId).subscribe(
      data => {
        this.actionType=data['responseData'];
        if(this.isCompleted){
          const foundObject = this.actionType.find(obj =>obj.key==this.approvalActionId );
          this.currentRemarksId = foundObject ? foundObject.remarksId : null;
          if (this.currentRemarksId==2 || this.currentRemarksId==3){
            this.showRemarks=true;
          }
          if(this.currentRemarksId==2){
            this.optionalRemarks=true;
          } 
        }
      }
    
    ));
  }

  getRemarks(id: number) {
    const foundObject = this.actionType.find(obj =>obj.key==id );
    this.currentRemarksId = foundObject ? foundObject.remarksId : null;
    if(this.currentRemarksId==1){
      this.showRemarks=false;
    }
    else if(this.currentRemarksId==2){
      this.optionalRemarks=true;
      this.showRemarks=true;
    }
    else if(this.currentRemarksId==3){
      this.optionalRemarks=false;
      this.showRemarks=true;
    }   
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  delete()
  {
    if(!this.isDeleteAllowed) return;
    this.activitiesService.deleteActivities(this.editId,this.moduleType,this.selectedProject).subscribe(
      data => {
        if(data['statusCode'] != 200){
          this.errorFlag = true;
          //this.errorText = data['message'];
        } else {
          this.activeModal.close("SUCCESS");
         // this.refreshGrid();
        }
    },
    err=>{
      this.errorFlag=true;
    }
    );


  }
  extractWholeNumber(val: String): number {
    if(val!=='' && val!==null) {
      val = val.replace(/\.\d*/, '');
      if(val!=='' && val!==null && val.match(/^\d*$/) !==null) {
        return Number(val);
      }
    }
    return null;
  }
  getDecimalFieldLength(value: string): number {
    if(value) {
      const wholeNumber = this.extractWholeNumber(String(value));
      if(wholeNumber !== null) {
        return String(wholeNumber).length;
      }
    }
    return 0;
  }
  private dependentProductFormDeleteOrAdd(attributeId: any, element: any,lookupId:any,hasChild:boolean) {
    if (this.previousDependentProductFormArray.some(r => r.parentAttribId == attributeId)) {
  
      const indexesToRemove = this.previousDependentProductFormArray
      .map((item, index) => ({ index, item }))
      .filter(({ item }) => item.parentAttribId === attributeId && item.parentLookupId !== lookupId)
      .map(({ index }) => index);
    
    if (indexesToRemove.length > 0) {
      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        const idx = indexesToRemove[i];
        const combinedAttribToBeDeleted = this.previousDependentProductFormArray[idx].combinedAttributeName;
    
        // Additional operations on deletion
        const prevControl = <FormArray>this.addCompaniesForm.controls[combinedAttribToBeDeleted];
        this.productFormFields[combinedAttribToBeDeleted].fields.forEach((y: CustomAttibute) => {
          if (y.attributeType.id == 4) {
            for (let j = 0; j < prevControl.length; j++) {
              const propertyToDelete = combinedAttribToBeDeleted + y.combinedAttributeName + j;
              if (propertyToDelete in this.fileArray) {
                delete this.fileArray[propertyToDelete];
              }
            }
          }
        });
    
        delete this.productFormFields[combinedAttribToBeDeleted];
        delete this.productFormDiscountDetails[combinedAttribToBeDeleted];
        this.addCompaniesForm.setControl(combinedAttribToBeDeleted, new FormArray([]));
        this.previousDependentProductFormArray.splice(idx, 1);
      }
    }
    }
    if(hasChild){
      element.parentAttribId = attributeId;
      element.parentLookupId=lookupId;

      if(element.parentFormId!=null){
        this.previousDependentProductFormArray.push(element);
        this.setupProductFormsForDependentField(element);
      }
     }
    
  }
  setupProductFormsForDependentField(dependentProductFormField) {

    this._customAttrService.getFields(dependentProductFormField.parentFormId, this.selectedProject).subscribe(
      res => {
        this.productFormFields[dependentProductFormField.combinedAttributeName] = {
          fields: res['responseData'].filter(ele => ele.status == 1),
          formGroup: {},
          productFieldName: "",
          productFieldNameInput: "",
          productFormView: null,
          numericFields: 0,
          enteredNumericFields: [0],
          numericData: [{}],
          subTotal: 0,
          discountValue: Number(0),
          discountPercent: Number(0),
          total: 0,
          summaryFieldCombinedAttributeName: ""
        };
        this.productFormFields[dependentProductFormField.combinedAttributeName].fields.forEach((ele: CustomAttibute) => {
          if (ele.systemAttribute?.id == 48) {
            this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldName = ele.combinedAttributeName;
            this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldNameInput = ele.combinedAttributeName + "inputField";
            this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = ((ele.mandatoryFlag === 1) && (dependentProductFormField.mandatoryFlag == 1)) ? [null, Validators.required] : [null];
            this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName + "inputField"] = [{ value: null, disabled: true }];
          }
          else if (ele.mandatoryFlag === 1 && dependentProductFormField.mandatoryFlag == 1) {
            this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = [null, Validators.required];
          }
          else {
            this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = [];
          }

          if (ele.attributeType.id == 7) {
            this.productFormFields[dependentProductFormField.combinedAttributeName].numericFields += 1;
          }

          if (ele.attributeType.id == 4) {
            this.fileArray[dependentProductFormField.combinedAttributeName + ele.combinedAttributeName + 0] = {
              "attributeName": ele.combinedAttributeName,
              "files": [],
              "maxUpload": ele.maxImagesAllowed,
              "showUploadIcon": true,
              "showDeleteOption":true,
              "uploaded": 0,
              "hasError": false
            };
          }
        })
       
        this.productFormService.getSelectedProductForm(dependentProductFormField.parentFormId).subscribe(
          res => {
            this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName] = {
              isAllowDiscount: false,
              isDiscountValue: false,
              isDiscountPercent: false
            };

            const productDetailsArray = this.skuList.map(productDetails => ({
              ...productDetails,
              isSelectionAllowed: res['responseData']?.productIdList?.includes(productDetails.productId),
            }));
            
          this.productFormIdWithProductIdNameMap.set(dependentProductFormField.parentFormId, productDetailsArray || []);
            if (res['responseData']?.allowDiscounts == 1) {
              this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isAllowDiscount = true;
            }
            if (res['responseData']?.allowDiscounts == 1 && res['responseData']?.discountType?.length > 0) {
              const discType: any[] = res['responseData']['discountType'];
              if (discType.includes(0) || discType.includes('0')) {
                this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isDiscountValue = true;
              }
              if (discType.includes(1) || discType.includes('1')) {
                this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isDiscountPercent = true;
              }
            }

            const summaryTotalFieldAttributeId = res['responseData'].summaryTotalField;
            const summaryTotalFieldObject = this.productFormFields[dependentProductFormField.combinedAttributeName].fields.find(x => x.id === summaryTotalFieldAttributeId);
            if (summaryTotalFieldObject !== undefined) {
              this.productFormFields[dependentProductFormField.combinedAttributeName].summaryFieldCombinedAttributeName = summaryTotalFieldObject.combinedAttributeName;
            }

            this.productFormFields[dependentProductFormField.combinedAttributeName].productFormView = res['responseData'].productFormView;
            if (res['responseData'].productFormView === 1) {
              const selectedSkuIds: Array<Number> = this.populateProductFormWithSavedData(dependentProductFormField, true);
              const control = <FormArray>this.addCompaniesForm.controls[dependentProductFormField.combinedAttributeName];
              let controlSize = control.length;
              for (let i = 0; i < this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId).length; i++,controlSize++) {
                if(!selectedSkuIds.includes(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productId) ){
                  if(!this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].isSelectionAllowed){
                    continue;
                  }
                const newIndex=this.addTable(dependentProductFormField.combinedAttributeName);
                this.updateItems(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productId, newIndex, dependentProductFormField.combinedAttributeName,dependentProductFormField.parentFormId);
                control.at(newIndex).get(this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldName).setValue(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productId);
                control.at(newIndex).get(this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldNameInput).setValue(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productName);
              }
              }
              
            }else{
              this.populateProductFormWithSavedData(dependentProductFormField);
            }
          })
      })

  }

  //[required] takes boolean flag giving 0/1
  isMandatory(mandatoryFlg:number){
    return mandatoryFlg === 1 ? true : false;
  }

}

