import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialerService } from './services/dialer.service';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, interval } from 'rxjs';
import { ManageActivitiesModel } from '../manage-activities/models/manage-activities.model';
import { stateModel } from '../company-management/models/state.model';
import { cityModel } from '../company-management/models/city.model';
import { CustomAttributesService } from '../shared/services/custom-attributes.service';
import { UploadImageService } from '../shared/services/upload-image.service';
import { ReferenceDocumentService } from '../shared/services/reference-document.service';
import { ProductFormService } from '../product-form/service/product-form.service';
import { ContactsService } from '../contact-management/services/contacts.service';
import { AddressService } from '../shared/services/address.service';
import { CompaniesService } from '../company-management/services/companies.service';
import { ProductService } from '../product-management/services/product.service';
import { OpportunitiesService } from '../opportunities/services/opportunities.service';
import { CustomAttibute } from '../contact-setting/models/custom-attibute.model';
import { SystemAttributeEnum } from '../shared/constant/SystemAttributeEnum.constants';
import { ImageViewerComponent } from '../shared/image-viewer/image-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppIntegrationService } from '../shared/services/app-integration.service';
import { AppConfigurationFieldsEnum } from '../shared/constant/AppConfigurationFieldEnum.constants';
import { SystemModules } from '../assist/_subs/constants/assists.constant';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { switchMap } from 'rxjs/operators';
import {DialerDashboardService} from 'src/app/views/pages/dialer-dashboard/Services/DialerDashboardService.service';
import { DialerSearchService } from '../dialer-search/services/dialer-search.service';

enum Status {
  CALLING, CONNECTED, ENDED, NOT_ANSWERED
};
@Component({
  selector: 'kt-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit, OnDestroy {

  @Input() public dialerFlag = false;
  @ViewChild('myButton') myButton : ElementRef;
  
  selectedActivity: number;
  addCompaniesForm :FormGroup = this.fb.group({});
  opportunityStatusId: number;
  moduleObj: any;
  moduleObj2: any;
  estimateModuleId:string|number;
  companyId:number|string;
  contactId:number;
  customAttributesList=[];
  maxDate: Date|String;
  minDate: Date|String;
  DisplayDateFlag:boolean;
  DisplayDateMinFlag:boolean;
  DisplayDateMaxFlag:boolean;
  fileArray = {};
  errorMes:string="";
  productFormFieldArray=[];
  productFormFields={};
  productFormDiscountDetails={};
  stateListAct = {};
  cityListAct = {};
  flag:String;
  opportunityId:number;
  baseUrl = environment.imageUrl;
  fixedCountryfieldName = 'countryName';
  fixedStatefieldName = 'stateName';
  fixedCityfieldName = 'cityName';
  isActivityListNotEmpty: boolean = true;
  disabled:Boolean=true;
  allContactLookUp = [];
  activityList: ManageActivitiesModel[];
  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];
  isStatusFieldShow:Boolean=false;
  systemAttributeOwnerArray=[];
  stateList:stateModel[];
  cityList:cityModel[];
  skuList:any;
  openDivs=[];
  ShowModal:boolean=false;
  spinnerCount: number = 0;
  uploadQueue = {};
  private subscriptions: Subscription[] = [];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  user = JSON.parse(localStorage.getItem('currentUser'));
  isAppIntegerationEnable=JSON.parse(localStorage.getItem('isAppIntegerationEnable'))
  isFromCallLogsPage: boolean = false;
  callLogDataForEditActivity;

  private numericPattern="^(0|[1-9][0-9]*)$";
  private decimalPattern="^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";
  private albhabetPatternErrorMsg ='Only Alphabets are allowed';
  private albhaNumericPatternErrorMsg ='Only Alpha-Numeric characters are allowed';

  documentTypeMap = new Map([
    [1, {type: "PDF", extension:".pdf", matchString: "application/pdf"}],
    [2, {type: "Word", extension:".docx", matchString: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"}],
    [3, {type: "Excel", extension:".xlsx", matchString: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}],
    [4, {type: "Power Point", extension:".pptx", matchString: "application/vnd.openxmlformats-officedocument.presentationml.presentation"}]
  ]);
  documentIconUrlMap = new Map([
    ["pdf", "./assets/media/svg/icons/General/PDF-File.svg"],
    ["docx", "./assets/media/svg/icons/General/file-alt-solid.svg"],
    ["xlsx", "./assets/media/svg/icons/General/file-excel-solid.svg"],
    ["pptx", "./assets/media/svg/icons/General/file-powerpoint-solid.svg"]
  ]);
  modalTitleBgColor: string;
  modalTitleTxtColor: string;
  modalTitleCallIcon: string;
  isSubmitted:boolean=true;
  errorFlag:boolean;
  customAttributeValues=[];
  skuItemsArray={};
  planId:number=null;
  plannedActivityId:number=null;
  isActivityFieldDisabled = false;

  public singleDropdownFilter: FormControl = new FormControl();

  public multiDropdownFilter: FormControl = new FormControl();

  public countryNameFilter: FormControl = new FormControl();

  public stateNameFilter: FormControl = new FormControl();

  public cityNameFilter: FormControl = new FormControl();
  public phoneNumber:String;
  public ucid:String;
  public ivrEvent:String;
  public agentId:String;
  public uui:String;
  public appId:String;
  public callStartTime:any;
  public  isSaveButtonCalled:boolean=false;
  private ozonetelQueryParamter={};
  public isSystemAttribute25Disabled:boolean =false;
  public productFormIdWithProductIdNameMap=new Map<number, any[]>();
  private previousDependentProductFormArray=[];
  private intervalSubscription: Subscription;
  title = '';


  constructor(
    private _ds: DialerService,
    private _customAttrService:CustomAttributesService,
    private _as:AddressService,
    private _cs:CompaniesService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    private _contact_service:ContactsService,
    private productFormService: ProductFormService,
    private _docUpload: ReferenceDocumentService,
    private _us: UploadImageService,
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _ai:AppIntegrationService,
    private ds:DialerDashboardService,
    @Optional() public activeModal: NgbActiveModal) {
      environment.baseUrl=localStorage.getItem('baseUrl');
    }

  ngOnInit(): void {
    if (!this.isFromCallLogsPage) {
      const intervalTime = 10000;
      this.intervalSubscription = interval(intervalTime).pipe(
        switchMap(async () => this.fetchCallDetails(true))
      ).subscribe();
    }
    if(this.isAppIntegerationEnable && this.isAppIntegerationEnable==false){
      window.close();
    }
    this.route.queryParams.subscribe(params => {
     this.ozonetelQueryParamter=params;
     if(!this.planId) {
      const planId=this.extractIVRParamsInteger(this.ozonetelQueryParamter['uui'],'I');
      if (planId) {
        this.planId = planId;
      }
    }
    this.plannedActivityId=this.extractIVRParamsInteger(this.ozonetelQueryParamter['uui'],'A');
    
      if(params['type']=='InBound'){
        this._ai.getConfigurationDetails(this.selectedProject, params['appId']).subscribe(res => {
          if (res['responseData']?.configFieldSaveValue.length == 0) {
            this.snackBar.open('App Configuration data not found', '', {
              duration: 3000,
              panelClass: ['red-snackbar']
            });
            return;
          }
          res['responseData'].configFieldSaveValue.forEach(element => {

             if (element.fieldId == AppConfigurationFieldsEnum.AGENT_ID && !element.fieldValue.split(",").includes(this.user?.userId?.toString())) {
                this.snackBar.open(this.user?.firstName+" "+this.user?.lastName+" is not configured for IVR call", '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                });
                window.close();
              }
            })
           
          });
      }
    });
    this.errorFlag=false;
    this._ds.getAllActivities(this.selectedProject).subscribe(
      data => {
        this.activityList = data['responseData'].filter(x =>
          x.active === 1 && x.allowForDialer === 1);

          if(this.activityList.length > 0){

            if (this.plannedActivityId) {
              this.selectedActivity = this.plannedActivityId;
              // Disable the select activity field
              this.isActivityFieldDisabled = true;
            } else {
              this.selectedActivity = this.selectedActivity?this.selectedActivity:this.activityList[0].activityId;
            }
            this.changeActivity();
          } else {
            this.isActivityListNotEmpty = false;
          }
      }
    )
  }
  

  changeActivity(){
    this.systemAttributecompaniesArray=[];
    this.fileArray={};
    this.productFormFields={};
    this.productFormFieldArray=[];
    this.customAttributesList=[];
    this.initaddCompanyForm();
    this.getFields();

  }

  initaddCompanyForm(){
    this.addCompaniesForm = this.fb.group({  
       
    });
  }

  getFields(){
    this._customAttrService.getFields(this.selectedActivity, this.selectedProject).subscribe(
      async res=>{
        res['responseData'] = res['responseData'].filter(ele => {
          if(ele.status == 1){

            // if(ele.attributeType.id==4 ){

            //   if( ele.allowBrowsing==1 ){
            //     return 1;
                
            //   }else{
            //     return 0;
            //   }
              
            // }else{
              return 1;
            // }
          }
          
        })
        //this.SpinnerService.hide("addCompany");

        res['responseData'].forEach(element=>{
          if(element.systemAttribute==null)
          {
            element.systemAttribute={};
          }

          if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
            
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            if(element.systemAttribute['id']==8 && this.opportunityStatusId!=null && this.opportunityStatusId!=undefined){
              this.addCompaniesForm.get(element.combinedAttributeName).setValue(this.opportunityStatusId);
            }
           
            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                  }
                }
              })
              }
              if(null!=this.moduleObj2){
              this.moduleObj2.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                  }
                }
              })
            }
            }

          }else{
            if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           
              let countryFormControl = this.fixedCountryfieldName;
              let stateFormControl = this.fixedStatefieldName;
              let cityFormControl = this.fixedCityfieldName;
              if(this.selectedActivity > 100){
                countryFormControl = element.combinedAttributeName + this.fixedCountryfieldName;
                stateFormControl = element.combinedAttributeName + this.fixedStatefieldName;
                cityFormControl = element.combinedAttributeName + this.fixedCityfieldName;
              }   

              if(element.mandatoryFlag==1){
                
                this.addCompaniesForm.addControl(countryFormControl,  new FormControl('',Validators.compose([ Validators.required])));
                this.addCompaniesForm.addControl(stateFormControl,  new FormControl('',Validators.compose([ Validators.required])));
                this.addCompaniesForm.addControl(cityFormControl,  new FormControl('',Validators.compose([ Validators.required])));
  
              }else{

                this.addCompaniesForm.addControl(countryFormControl,  new FormControl());
                this.addCompaniesForm.addControl(stateFormControl,  new FormControl());
                this.addCompaniesForm.addControl(cityFormControl,  new FormControl());
    
              }

              if(element.linkedAttributeId != null ){
                if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      // this.addCompaniesForm.get(countryFormControl).setValue(ele.value[0].name+'@'+ele.value[0].id);
                      this.setLinkedCountryStateCityField(element.combinedAttributeName, ele.value);
                    }
                  }
                })
                }
                if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      // this.addCompaniesForm.get(countryFormControl).setValue(ele.value[0].name+'@'+ele.value[0].id);
                      this.setLinkedCountryStateCityField(element.combinedAttributeName, ele.value);
                    }
                  }
                })
              }
              }

            }
          }

          if(element.attributeType.id==2 && element.systemAttribute.id !==28){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            
            if(element.linkedAttributeId != null ){
              var multiSelectArray=[];
              if(null!=this.moduleObj){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if((ele.attributeId==element.linkedAttributeId) && (ele.attribTypeId==element.attributeType.id)){
                  if(ele.value.length>0){
                    if(ele.value.length == 1){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    } else {
                      ele.value.forEach(res=>{
                        multiSelectArray.push(res.id);
                      })
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                    }
                  }
                }
              })
            }
            if(null!=this.moduleObj2){
              this.moduleObj2.customAttributeValues.forEach(ele=>{
                if((ele.attributeId==element.linkedAttributeId) && (ele.attribTypeId==element.attributeType.id)){
                  if(ele.value.length>0){
                    if(ele.value.length == 1){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }else {
                      ele.value.forEach(res=>{
                        multiSelectArray.push(res.id);
                      })
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                    }
                  }
                }
              })
            }
            }

          }

          if(element.attributeType.id==3  || element.attributeType.id==21 ){
            this.maxDate= new Date();
            this.minDate= new Date();
            if(element.dateAttributes){
            
              if(element.dateAttributes.stdLookupAttribute != null){
                this.DisplayDateMinFlag=false;
                this.DisplayDateMaxFlag=false;
                if(element.dateAttributes.stdLookupAttribute.id == 2){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 3){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 5){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay()+6);
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 6){
                  this.DisplayDateFlag=true;
                  var date = new Date();
                  //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 7){
                  this.DisplayDateFlag=true;
                  var date = new Date();
                  //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                  element.dateAttributes.maxDate= new Date(date.getFullYear(), date.getMonth() + 1, 0);
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 8){
                  this.DisplayDateFlag=true;
                  var now = new Date();
                  var start = new Date(now.getFullYear(), 0, 0);
                  var diff = Number(now) - Number(start);
                  var oneDay = 1000 * 60 * 60 * 24;
                  var day = Math.floor(diff / oneDay);
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
               
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 9){
                  this.DisplayDateFlag=true;
                  var now = new Date();
                  var start = new Date(now.getFullYear(), 0, 0);
                  var end = new Date(now.getFullYear(), 11, 31);
                  var diffStart = Number(now) - Number(start);
                  var diffend = Number(end) - Number(now) ;
                  var oneDay = 1000 * 60 * 60 * 24;
                  var day = Math.floor(diffStart / oneDay);
                  var dayEnd = Math.floor(diffend / oneDay);
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
                 }
              } else{
                  if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed == null){
                
                    this.DisplayDateFlag=false;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateMaxFlag=false;
                  }
                  else if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed != null){
                  
                    this.DisplayDateMaxFlag=true;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateFlag=false;
                    if(element.dateAttributes.futureDateAllowed >= 0){
                      element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                    }
                  }
                  else if(element.dateAttributes.previousDateAllowed != null && element.dateAttributes.futureDateAllowed == null){
                
                    this.DisplayDateMinFlag=true;
                    this.DisplayDateFlag=false;
                    this.DisplayDateMaxFlag=false;
                    if(element.dateAttributes.previousDateAllowed >= 0){
                      element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);    
                    }
                  }else{
                   
                    this.DisplayDateFlag=true;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateMaxFlag=false;
                    if(element.dateAttributes.previousDateAllowed >= 0){
                     element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                    }
        
                    if(element.dateAttributes.futureDateAllowed >= 0){
                      element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                    }
                } 
              }
              if(element.attributeType.id==21){
                let DateTimeMinDate = new Date(element.dateAttributes.minDate);
                let minYear = DateTimeMinDate.getFullYear();
                let minMonth = (DateTimeMinDate.getMonth() + 1).toString().padStart(2, '0'); 
                let minDays = DateTimeMinDate.getDate().toString().padStart(2, '0');
                element.dateAttributes.minDate = `${minYear}-${minMonth}-${minDays}T00:00:00`;
                let DateTimeMaxDate = new Date(element.dateAttributes.maxDate);
                let maxYear = DateTimeMaxDate.getFullYear();
                let maxMonth = (DateTimeMaxDate.getMonth() + 1).toString().padStart(2, '0'); 
                let maxDays = DateTimeMaxDate.getDate().toString().padStart(2, '0');
                element.dateAttributes.maxDate = `${maxYear}-${maxMonth}-${maxDays}T00:00:00`;
               }  
            } else {
              
              this.minDate="";
              this.maxDate="";
            }

            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                //this.addCompaniesForm.controls[element.combinedAttributeName].disable(); 
            } else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
            }
          }

          if(element.attributeType.id==4){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            } else{
        
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            this.fileArray[element.combinedAttributeName] = {
              "attributeName": element.combinedAttributeName,
              "files": [],
              "maxUpload": element.maxImagesAllowed,
              "showUploadIcon": true,
              "uploaded": 0,
              "hasError": false
            };
            this.setupLinkedMediaFormControls(element);
          }

          if(element.attributeType.id==5){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            } else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
            }
          }

          if(element.attributeType.id==6 || element.attributeType.id==20){
            let patternString = ".*";
            let patternErrorMsg = '';
            if(element.characterType == 3){
              patternString = "^[a-zA-Z ]*$";
              patternErrorMsg = this.albhabetPatternErrorMsg;
            }else if(element.characterType == 2){
              patternString = "^[a-zA-Z0-9 ]*$";
              patternErrorMsg = this.albhaNumericPatternErrorMsg;
            }
            element.patternErrorMsg = patternErrorMsg;
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(patternString)])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', [Validators.pattern(patternString)]));
            }

            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
            }

          }

          if(element.attributeType.id==7){
            
            let pattern = this.numericPattern;
            if(element.decimalFlag) {
              pattern = this.decimalPattern;
            }
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(pattern)])));
            } else {

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',Validators.compose([ Validators.pattern(pattern)])));
            }

            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
              
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele2=>{
                  if(ele2.attributeId==element.linkedAttributeId){
                    if(ele2.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele2.value[0].name);
                    }
                  }
                })
              }
            }
          }

          if(element.attributeType.id==8){
            let patternString = ".*";
            let patternErrorMsg = '';
            if(element.characterType == 3){
              patternString = "^[a-zA-Z \n]*$";
              patternErrorMsg = this.albhabetPatternErrorMsg;
            }else if(element.characterType == 2){
              patternString = "^[a-zA-Z0-9 \n]*$";
              patternErrorMsg = this.albhaNumericPatternErrorMsg;
            }
            element.patternErrorMsg = patternErrorMsg;
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required, Validators.pattern(patternString)])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',  Validators.compose([Validators.pattern(patternString)])));
            }

            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
               this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
            }

          }

          if(element.attributeType.id==9){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
            }

            if(element.linkedAttributeId != null ){
              if(null!=this.moduleObj){
                this.moduleObj.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
              if(null!=this.moduleObj2){
                this.moduleObj2.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.linkedAttributeId){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
            }

          }

          if(element.attributeType.id==15){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            } else{
        
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            let validDocTypes = [];
            let allowedDocText = [];
            if(element.documentType != null && element.documentType != ""){
              (element.documentType as string).split(",").forEach(x => {
                let type =this.documentTypeMap.get(+x);
                validDocTypes.push(type);
                allowedDocText.push(type.extension);
              });
            }
            else{
              for (const [key, value] of this.documentTypeMap.entries()) {
                validDocTypes.push(value);
                allowedDocText.push(value.extension);
              }
            }

            this.fileArray[element.combinedAttributeName] = {
              "attributeName": element.combinedAttributeName,
              "files": [],
              "documentIcons": [],
              "allowedDocText": allowedDocText.join(", "),
              "validDocType": validDocTypes,
              "maxUpload": element.maxImagesAllowed,
              "showUploadIcon": true,
              "uploaded": 0,
              "hasError": false
            };
            this.setupLinkedMediaFormControls(element);
          }

          if(element.attributeType.id==16){
            if(element.mandatoryFlag==1 && element.parentAttributeId === 0){

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            } else{
        
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            this.fileArray[element.combinedAttributeName] = {
              "attributeName": element.combinedAttributeName,
              "files": [],
              "maxUpload": element.maxImagesAllowed,
              "showUploadIcon": element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID?false:true,
              "uploaded": 0,
              "hasError": false
            };
            this.setupLinkedMediaFormControls(element);

          }

          if(element.attributeType.id==17){
            this.fileArray[element.combinedAttributeName] = {
              "attributeName": element.combinedAttributeName,
              "files": [],
              "documentIcons": [],
              // "allowedDocText": allowedDocText.join(", "),
              // "validDocType": validDocTypes,
              "maxUpload": element.maxImagesAllowed,
              "showUploadIcon": false,
              "uploaded": 0,
              "hasError": false
            };
            const fileAttr = this.fileArray[element.combinedAttributeName];

            (element.referenceDocumentUrl as Array<string>).forEach( file => {
              fileAttr.files.push(file);
              let fileType = file.split('.').pop().toLowerCase();
              const icon = this.documentIconUrlMap.get(fileType);
              fileAttr.documentIcons.push(icon ? icon : file);
            })

          }

          //Condition for Product-Form
          if(element.attributeType.id==13){
            // if(element.mandatoryFlag==1){
              
            //     this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
            // }else{

            //     this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
            // }
            this.addCompaniesForm.addControl(element.combinedAttributeName, new FormArray([]))
            if(element.parentFormId != null ){
              this.productFormFieldArray.push(element);
            }

          }

          //Condition for Data List
          if (element.attributeType.id == 18) {

            if (element.mandatoryFlag == 1 && element.parentAttributeId === 0) {
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
            } else {
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if (element.linkedAttributeId != null) {
              if (null != this.moduleObj) {
                this.moduleObj.customAttributeValues.forEach(ele => {
                  if (ele.attributeId == element.linkedAttributeId) {
                    if (ele.value.length > 0) {
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
              if (null != this.moduleObj2) {
                this.moduleObj2.customAttributeValues.forEach(ele => {
                  if (ele.attributeId == element.linkedAttributeId) {
                    if (ele.value.length > 0) {
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
            }
            if (element.childAttributeList?.length > 0) {
              element.childAttributeList.forEach(childElement => {
                childElement.parentAttributeId = element.parentDataListId;
                childElement.parentCombinedAttributeName = element.combinedAttributeName;
                childElement.mandatoryFlag = element.mandatoryFlag;
                childElement.parentLinkedDataListAttribId = element.id;
                if (childElement.attributeType.id == 1) {
                  if (childElement.systemAttribute === null) {
                    childElement.systemAttribute = {};
                  }
                  if (childElement.mandatoryFlag == 1 && childElement.parentAttributeId === 0) {
                    this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
                  } else {
                    this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl());
                  }
                }
              }
              )
            }
          }
          if(element.attributeType.id ==22){
            if(element.mandatoryFlag==1&& element.parentAttributeId === 0){
              
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
          }else{

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl(''));
          }
          if(element.linkedAttributeId != null ){
            if(null!=this.moduleObj){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }
            if(null!=this.moduleObj2){
              this.moduleObj2.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }
          }
          }
          if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_TYPE_FIELD_ID){
            if(this.ozonetelQueryParamter && this.ozonetelQueryParamter['type']=="Inbound"){
              this.addCompaniesForm.get(element.combinedAttributeName).setValue(element.lookupValues.find(a => a.stageId === 102)?.id);
            }else if(this.ozonetelQueryParamter && this.ozonetelQueryParamter['type']=="Manual"){
              this.addCompaniesForm.get(element.combinedAttributeName).setValue(element.lookupValues.find(a => a.stageId === 101)?.id);
            }
           
            this.addCompaniesForm.get(element.combinedAttributeName).disable();
          }
          if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_DATE_TIME_FIELD_ID){
            this.addCompaniesForm.get(element.combinedAttributeName).disable();
          }
          if(element.systemAttribute.id==SystemAttributeEnum.DIALER_DURATION_FIELD_ID){
            this.addCompaniesForm.get(element.combinedAttributeName).disable();
          }
          if(element.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID){
            this.addCompaniesForm.get(element.combinedAttributeName).disable();
          }
          //CONDITION FOR COMPANIES LIST
          if(element.systemAttribute.id==11 || element.systemAttribute.id==5 || element.systemAttribute.id==25){
            this.systemAttributecompaniesArray.push(element);  
          }

          //CONDITION FOR CONTACTS LIST
          if(element.systemAttribute.id==6 || element.systemAttribute.id==27){
            this.systemAttributecontactsArray.push(element);  
          }

           //CONDITION FOR OPPORTUNITY LIST
          if(element.systemAttribute.id==26){
            this.systemAttributeopportunityArray.push(element);  
           
           
          }

           //CONDITION FOR PRODUCTS LIST
          if(element.systemAttribute.id==28){
            this.systemAttributeproductsArray.push(element);  
          }

          //other opoportunity inputs
          // if(element.systemAttribute.id==29 ||
          //    element.systemAttribute.id==30 ||
          //    element.systemAttribute.id==31 ||
          //    element.systemAttribute.id==32){
              
          //     this.systemAttributeopportunityListArray.push(element);  
          // }

          if(element.linkedAttributeId != null ){
                            
            if(element.linkedAttributeModuleId==3){             
              this.systemAttributeopportunityListArray.push(element);  
            }
            if(element.linkedAttributeModuleId==2){             
              this.systemAttributecompanyListArray.push(element);  
            }
            if(element.linkedAttributeModuleId==1){             
              this.systemAttributecontactsListArray.push(element);  
            }   
              
          }

          //CONDITION FOR ESTIMATE LIST
          if(element.systemAttribute.id==40){
            this.systemAttributeEstimateArray.push(element);        
          }

          //CONDITION FOR ESTIMATE LIST
          if(element.systemAttribute.id==14 || element.systemAttribute.id==17 ||element.systemAttribute.id==39 ||element.systemAttribute.id==61 ){
            this.systemAttributeStatusArray.push(element);    
            this.isStatusFieldShow=false;       
          }

          //CONDITION FOR COMPANY OWNER LIST
          if(element.systemAttribute.id==34 || element.systemAttribute.id==35 ){
            this.systemAttributeOwnerArray.push(element);   
          }

          if(element.systemAttribute.id==28){
          
            this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()]));
            
          }
        })
        
        if(this.systemAttributeproductsArray.length >0 || this.productFormFieldArray.length >0){        
          await this.getAllProducts(); 
        }
        let customAttributesList = [];
        (res['responseData'] as Array<any>).forEach(field => {
            customAttributesList.push(field);
          if (field.attributeType.id == 18 && field.childAttributeList !== null && field.childAttributeList.length > 0) {
            customAttributesList.push(...field.childAttributeList);
          }
        });
        this.customAttributesList = customAttributesList;

        if(this.isFromCallLogsPage) {
          this.fetchCallDetailsForCallLogsPage();
        }
    
        this.getFieldValues();
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click();
        }, 300);
      },
      error => {
        this.disabled=true;
        this.errorMes=error.error.responseData;
      }
    ) 
  }

  setLinkedCountryStateCityField(combinedAttribName: string, values: {id: number, name: string}[]){
    if(values[0]) {
      const contryValue = values[0].name+'@'+values[0].id;
      this.addCompaniesForm.controls[combinedAttribName+this.fixedCountryfieldName].setValue(contryValue);
      this.changeCountry(contryValue, combinedAttribName, false);
    }
    if(values[1]) {
      const stateValue = values[1].name+'@'+values[1].id;
      this.addCompaniesForm.controls[combinedAttribName+this.fixedStatefieldName].setValue(stateValue);
      this.changeState(stateValue, combinedAttribName, false);
    }
    if(values[2]) {
      this.addCompaniesForm.controls[combinedAttribName+this.fixedCityfieldName].setValue(values[2].name+'@'+values[2].id);
    }
  }
  extractIVRParamsInteger(input: string, key: string): number | null {
    const index = input.indexOf(key);
    if (index === -1 || index === input.length - 1) {
      return null; // Key not found or at the end of the string
    }

    const startIndex = index + 1;
    let endIndex = startIndex;

    while (endIndex < input.length && !isNaN(Number(input[endIndex]))) {
      endIndex++;
    }

    const extractedNumber = input.substring(startIndex, endIndex);
    return extractedNumber ? parseInt(extractedNumber) : null;
  }
  getFieldValues(){
    if(this.systemAttributecompaniesArray.length >0){
      const mobileSearchRequestDto = this.getMobileSearchRequestDto();
      this._ds.getCompanyIdNameByMobileNo(mobileSearchRequestDto).subscribe(comData=>{
        comData['responseData'].forEach(res=>{
          res.lookupValue=res.name;
        });
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
   
        if(removeIndex >-1){
        
          this.customAttributesList[removeIndex].lookupValues=comData['responseData'];
          if(this.customAttributesList[removeIndex].lookupValues.length==1){
            const attribDetail=this.customAttributesList[removeIndex];
            this.addCompaniesForm.get(attribDetail.combinedAttributeName).setValue(attribDetail.lookupValues[0].id);
            this.addCompaniesForm.get(attribDetail.combinedAttributeName).disable();
            this.ds.setEntityType(attribDetail.lookupValues[0].id);
            this.isSystemAttribute25Disabled=true;
          }else {
            this.isSystemAttribute25Disabled=false;
          }
          if(this.companyId){
            this.addCompaniesForm.get(this.customAttributesList[removeIndex].combinedAttributeName).setValue(this.companyId);
          }

        }
      });
    }

    if(this.systemAttributecontactsArray.length >0){
      this.getAllContacts();
    }

    if(this.systemAttributeopportunityArray.length >0){
      this.getAllOpportunities();
    }

    if(this.systemAttributeopportunityListArray.length >0){
      if(this.opportunityId && this.flag=="opportunity"){        
        this.getAllOpportunitiesDependentInputs(this.opportunityId);
      } else {
        this.systemAttributeopportunityListArray.forEach(rep=>{
          if(rep.systemAttribute.id !==26){
            var divId = this.createDivId(rep);
            var division1=<HTMLElement>document.getElementById(divId);
            if(division1){            
              division1.classList.add('hidden');
            }
          }
         
        })        
      }
    }

    if(this.systemAttributecompanyListArray.length >0){
      if(this.opportunityId && this.flag=="company"){
        this.getAllCompanyDependentInputs(this.opportunityId);
      }
    }

    if(this.systemAttributeEstimateArray.length >0){
      this.getAllEstimates();
    }

    if(this.systemAttributeStatusArray.length >0){
      this.setStatus();
    }

    if(this.systemAttributeOwnerArray.length >0){      
      this.getAllOwner();
    }

    this.ShowModal=true
  }

  getMobileSearchRequestDto() {
    const mobileSearchRequestDto: any = {};
    if(this.isFromCallLogsPage) {
      mobileSearchRequestDto.moduleType = SystemModules.COMPANY_OR_CUSTOMER;
      mobileSearchRequestDto.appId = 1; //Ozonotel
      if(this.callLogDataForEditActivity.contactNumber != null) {
        mobileSearchRequestDto.mobileNo = this.callLogDataForEditActivity.contactNumber.toString().replace(/^\s*(0+91|0091|\+91|91|0+)\s*/, '');
      } else {
        mobileSearchRequestDto.mobileNo = '';
      }
      mobileSearchRequestDto.agentId = this.callLogDataForEditActivity.agentId;
      mobileSearchRequestDto.projectId = this.selectedProject;
      mobileSearchRequestDto.entityId = this.callLogDataForEditActivity.entityRecordId;
    } 
    else {
      mobileSearchRequestDto.campaignName=this.ozonetelQueryParamter['campaignName'];
      mobileSearchRequestDto.moduleType=2;
      mobileSearchRequestDto.appId=this.ozonetelQueryParamter['appId'];

      mobileSearchRequestDto.mobileNo= this.ozonetelQueryParamter['phoneNumber'].replace(/^\s*(0+91|0091|\+91|91|0+)\s*/, '');

      mobileSearchRequestDto.agentId=this.ozonetelQueryParamter['agentID'];
      if(this.ozonetelQueryParamter['type']=="Manual"){
        mobileSearchRequestDto.projectId=this.extractIVRParamsInteger(this.ozonetelQueryParamter['uui'],'P');
        mobileSearchRequestDto.entityId=this.extractIVRParamsInteger(this.ozonetelQueryParamter['uui'],'E');
      }
    }
    return mobileSearchRequestDto;
  }

  getControls(combinedAttributeName) {
    return (this.addCompaniesForm.get(combinedAttributeName) as FormArray)?.controls;

  }

  getAllEstimates(){
    this._customAttrService.getActivityLookup(this.estimateModuleId,this.selectedProject,this.companyId,this.opportunityId).subscribe(
      data=>{
       
        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId;          
        })

        if(this.systemAttributeEstimateArray[0]) {
          var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
        }
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }

  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  

      var id=this.createDivId(res);
      var division1=<HTMLElement>document.getElementById(id);
      
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues.find(obj => obj.lookupValue === "Active").id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }

  changeCountry(countryId, combinedAttributeName: string, needReset: boolean = true){
    //get states
    var id=countryId.split('@',2);
    this.getAllStates(id[1], combinedAttributeName, needReset);
    if(needReset) { // to reset city dropdown options
      this.changeState('0@0', combinedAttributeName, needReset);
    }
  }

  changeState(stateId, combinedAttributeName: string, needReset: boolean = true){
    //get cities
    var id=stateId.split('@',2);
    this.getAllCities(id[1], combinedAttributeName, needReset);
  }

  getAllStates(countryId, combinedAttributeName: string, needReset: boolean){
    this._as.getStatesForCountry(countryId).subscribe(
      (result)=>{  
        if(this.selectedActivity > 100) {
          if(combinedAttributeName!==null) {
            this.stateListAct[combinedAttributeName]=result['responseData'];
            if(needReset) {
              // reset city and state form controls
              this.addCompaniesForm.get(combinedAttributeName+this.fixedStatefieldName).reset();
              this.addCompaniesForm.get(combinedAttributeName+this.fixedCityfieldName).reset();
            }
          } 
        } else {
          this.stateList=result['responseData']; 
          if(needReset) {
            // reset city and state form controls
            this.addCompaniesForm.get(this.fixedStatefieldName).reset();
            this.addCompaniesForm.get(this.fixedCityfieldName).reset();
          }
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  getAllCities(stateId, combinedAttributeName: string, needReset: boolean){
    this._as.getCitiesForState(stateId).subscribe(
      (result)=>{
        if(this.selectedActivity > 100) {
          if(combinedAttributeName!==null) {
            this.cityListAct[combinedAttributeName]=result['responseData'];
            if(needReset) {
              // reset city and state form controls
              this.addCompaniesForm.get(combinedAttributeName+this.fixedCityfieldName).reset();
            }
          }
        } else {
          this.cityList=result['responseData']; 
          if(needReset) {
            // reset city and state form controls
            this.addCompaniesForm.get(this.fixedCityfieldName).reset();
          }
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  setupLinkedMediaFormControls(element: CustomAttibute) {
    if(element.linkedAttributeId != null ){
      if(null!=this.moduleObj){
      this.moduleObj.customAttributeValues.forEach(ele=>{
        if(ele.attributeId==element.linkedAttributeId){
          if(ele.value.length>0){
            this.assignFilesToLinkedFieldFormControl(element, ele.value);
          }
        }
      })
      }
      if(null!=this.moduleObj2){
      this.moduleObj2.customAttributeValues.forEach(ele=>{
        if(ele.attributeId==element.linkedAttributeId){
          if(ele.value.length>0){
            this.assignFilesToLinkedFieldFormControl(element, ele.value);
          }
        }
      })
     }
    }
  }

  assignFilesToLinkedFieldFormControl(element: CustomAttibute, values: Array<{id: number, name: string}>) {
    const fileURLs = values.map(x => x.name.replace(this.baseUrl, ''));
    const fileAttr = this.fileArray[element.combinedAttributeName];
    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(fileURLs);
    fileAttr.files = fileURLs;
    if(element.attributeType.id==15){
      for (let i = 0; i < values.length; i++) {
        let fileType = values[i].name.split('.').pop().toLowerCase();
        fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
      }
    }
    fileAttr.uploaded = fileAttr.files.length;
    fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
  }

  getAllProducts(){
    this._ps.getProductLookup(this.selectedProject).subscribe(
      data=>{
        this.skuList= data['responseData'];     
        this.setupProductForms();  
      }
    ) 
  }

  setupProductForms(){
    for (const element of this.productFormFieldArray){
      if(element.parentAttributeId!=null && element.parentAttributeId>0){
        continue;
      }
      this._customAttrService.getFields(element.parentFormId, this.selectedProject).subscribe(
        res=>{
          this.productFormFields[element.combinedAttributeName]={fields:res['responseData'].filter(ele => ele.status==1),
          formGroup: {},
          productFieldName:"",
          productFieldNameInput:"",
          productFormView: null,
        numericFields:0,
        enteredNumericFields:[0],
        numericData:[{}],
        subTotal: 0,
        discountValue: Number(0),
        discountPercent: Number(0),
        total: 0,
        summaryFieldCombinedAttributeName:""
        };
          this.productFormFields[element.combinedAttributeName].fields.forEach((ele: CustomAttibute)=>{ 
            if(ele.systemAttribute?.id ==48)
            {
              this.productFormFields[element.combinedAttributeName].productFieldName=ele.combinedAttributeName;
              this.productFormFields[element.combinedAttributeName].productFieldNameInput=ele.combinedAttributeName+"inputField";
              this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = ((ele.mandatoryFlag === 1) && (element.mandatoryFlag == 1)) ?[null, Validators.required] : [null];
              this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName+"inputField"] = [{value:null,disabled:true}];
            }
            else if(ele.mandatoryFlag === 1 &&  element.mandatoryFlag == 1){
              this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [null, Validators.required];
          }
            else{
              this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [];
            }
    
            if(ele.attributeType.id==7){
              this.productFormFields[element.combinedAttributeName].numericFields += 1;
            }

            if(ele.attributeType.id==4){
              this.fileArray[element.combinedAttributeName + ele.combinedAttributeName+0] = {
                "attributeName": ele.combinedAttributeName,
                "files": [],
                "maxUpload": ele.maxImagesAllowed,
                "showUploadIcon": true,
                "uploaded": 0,
                "hasError": false
              };
            }
          })
          const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
          control.push(this.fb.group(this.productFormFields[element.combinedAttributeName].formGroup));
          
        this.productFormService.getSelectedProductForm(element.parentFormId).subscribe(
          res=>{
            this.productFormDiscountDetails[element.combinedAttributeName]={
            isAllowDiscount: false,
            isDiscountValue:false,
            isDiscountPercent:false
          };

          const productDetailsArray = res['responseData']?.productIdList
          ?.map(productId => this.skuList.find(r => r.productId == productId))
          .filter(productDetails => productDetails !== undefined);
          this.productFormIdWithProductIdNameMap.set(element.parentFormId, productDetailsArray || []);

          if(res['responseData']?.allowDiscounts == 1 ){
            this.productFormDiscountDetails[element.combinedAttributeName].isAllowDiscount = true;
          }
          if(res['responseData']?.allowDiscounts == 1 && res['responseData']?.discountType?.length > 0){
            const discType: any[] = res['responseData']['discountType'];
            if(discType.includes(0) || discType.includes('0')){
              this.productFormDiscountDetails[element.combinedAttributeName].isDiscountValue = true;
            }
            if(discType.includes(1) || discType.includes('1')){
              this.productFormDiscountDetails[element.combinedAttributeName].isDiscountPercent = true;
            }
          }
          
          const summaryTotalFieldAttributeId=res['responseData'].summaryTotalField;
          const summaryTotalFieldObject=this.productFormFields[element.combinedAttributeName].fields.find(x =>x.id === summaryTotalFieldAttributeId);
          if(summaryTotalFieldObject !== undefined){
            this.productFormFields[element.combinedAttributeName].summaryFieldCombinedAttributeName=summaryTotalFieldObject.combinedAttributeName;
          }

          this.productFormFields[element.combinedAttributeName].productFormView = res['responseData'].productFormView;
          if(res['responseData'].productFormView===1)
          {
            const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
            for(let i=0;i<this.productFormIdWithProductIdNameMap.get(element.parentFormId).length;i++){
              if(i>0){
                this.addTable(element.combinedAttributeName);
              }
              this.updateItems(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productId,i,element.combinedAttributeName,element.parentFormId);
              control.at(i).get( this.productFormFields[element.combinedAttributeName].productFieldName).setValue(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productId);
              control.at(i).get( this.productFormFields[element.combinedAttributeName].productFieldNameInput).setValue(this.productFormIdWithProductIdNameMap.get(element.parentFormId)[i].productName);
            }
          }
        })
      })
    }
  }

  addTable(combinedAttributeName) {
    const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
    
    this.productFormFields[combinedAttributeName].fields.forEach(ele=>{ 
      if(ele.attributeType.id == 4){
        this.fileArray[combinedAttributeName + ele.combinedAttributeName+control.length] = {
          "attributeName": ele.combinedAttributeName,
          "files": [],
          "maxUpload": ele.maxImagesAllowed,
          "showUploadIcon": true,
          "uploaded": 0,
          "hasError": false
        };
      }
    })
  
    control.push(this.fb.group(this.productFormFields[combinedAttributeName]?.formGroup));
    this.productFormFields[combinedAttributeName].enteredNumericFields.push(0);
    this.productFormFields[combinedAttributeName].numericData.push({});
  }

  updateItems(selectedProductId,i,combinedAttributeName,parentFormId:number) {
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  let attribute = null;
  let listPriceAttributeName = null;
  let listPriceCombinedAttributeName = null;
  let listPriceAttributeId = null;
  let productPrice = null;
  this.productFormFields[combinedAttributeName].fields.forEach(ele=>{ 
    if(ele.systemAttribute?.id == 50){
      attribute = ele.combinedAttributeName;
      listPriceAttributeId = ele.id;
      listPriceAttributeName = ele.attributeName;
      listPriceCombinedAttributeName = ele.combinedAttributeName;
    }
    })
    for(let sku of this.productFormIdWithProductIdNameMap.get(parentFormId)){
      if(sku.productId == selectedProductId){
        control.at(+i).get(attribute).setValue(sku.price);
        productPrice = sku.price;

      }
   }

   if(attribute){
    if(!this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId] && productPrice>=0){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] += 1;
    }
    else if(this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId] && productPrice === null){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] -= 1;
    }
    this.productFormFields[combinedAttributeName].numericData[i][listPriceAttributeId]={
      attributeId: listPriceAttributeId,
      attributeName: listPriceAttributeName,
      combinedAttributeName: listPriceCombinedAttributeName,
      value: productPrice
    };

    // calculate total
    if(this.productFormFields[combinedAttributeName].enteredNumericFields[i]==this.productFormFields[combinedAttributeName].numericFields){
      const keys = Object.keys(this.productFormFields[combinedAttributeName].numericData[i]);
      let productFormData=[];
      keys.forEach((key, index) => {

      productFormData.push(
      this.productFormFields[combinedAttributeName].numericData[i][key]
      )   
      
    });
    // call api method
    this.calculateTotal(combinedAttributeName, productFormData, i, control);
    
    }
    this.calculateSummeryFieldsIfMatches(combinedAttributeName, attribute);
   }

 
}

calculateSummeryFieldsIfMatches(productFormCombinedAttributeName, fieldCombinedAttributeName) {
  if (this.productFormFields[productFormCombinedAttributeName].summaryFieldCombinedAttributeName === fieldCombinedAttributeName) {
    this.calculateSummeryFields(productFormCombinedAttributeName);
  }
}

calculateTotal(combinedAttributeName, productFormData, i, control){
  let totalFieldCombinedNameList : string[] =[];
  let calculationFormulaList : string[]=[];
  this.productFormFields[combinedAttributeName].fields.forEach(f => {
    if(f.attributeType.id==14){
      totalFieldCombinedNameList.push(f.combinedAttributeName);
      calculationFormulaList.push(f.calculationFormula.replace(/,/g, ''));
    } 
  });

  if(calculationFormulaList.length>0 && productFormData?.length>0){
    totalFieldCombinedNameList.forEach((totalFieldcombinedAttributeName, index)=>{
      // call api
      this.productFormService.validateExpression({expression: calculationFormulaList[index], attributeList: productFormData}).subscribe(
        res=>{
          if(res['responseData']?.validated){
            control.at(+i).get(totalFieldcombinedAttributeName).setValue(res['responseData']?.calculatedTotal);
            // calculate sub total
            this.calculateSummeryFields(combinedAttributeName);
          }
        });
    })
  }
}

calculateSummeryFields(combinedAttributeName){
  const totalFieldCombinedName=this.productFormFields[combinedAttributeName].summaryFieldCombinedAttributeName;
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  let subTotal = 0;
  for(let i=0;i<control.length;i++){
  let total = control.at(i).get(totalFieldCombinedName).value === null ? 0 : Number(control.at(+i).get(totalFieldCombinedName).value);
  subTotal += total
  }
  this.productFormFields[combinedAttributeName].subTotal = subTotal;
  // calc total
  this.productFormFields[combinedAttributeName].total = this.productFormFields[combinedAttributeName].subTotal;
  // re calc discount
  if(this.productFormFields[combinedAttributeName].discountPercent){
   this.discountPercentChanged(combinedAttributeName);
  }
  if(this.productFormFields[combinedAttributeName].discountValue){
    this.discountValueChanged(combinedAttributeName);
  }
}

discountPercentChanged(combinedName){
  if(this.productFormFields[combinedName].discountPercent === null || this.productFormFields[combinedName].discountPercent === ''){
    this.productFormFields[combinedName].discountPercent = 0;
  }

  const discountAmt = (this.productFormFields[combinedName].subTotal * this.productFormFields[combinedName].discountPercent) / 100;
  this.productFormFields[combinedName].discountValue = discountAmt;
  this.productFormFields[combinedName].total = this.productFormFields[combinedName].subTotal - discountAmt;
}

discountValueChanged(combinedName){
  if(this.productFormFields[combinedName].discountValue === null || this.productFormFields[combinedName].discountValue===''){
    this.productFormFields[combinedName].discountValue = 0;
  }
  if(this.productFormFields[combinedName].subTotal){
    const discountPercent = (this.productFormFields[combinedName].discountValue / this.productFormFields[combinedName].subTotal) * 100;
    this.productFormFields[combinedName].discountPercent = discountPercent;
    this.productFormFields[combinedName].total = this.productFormFields[combinedName].subTotal - this.productFormFields[combinedName].discountValue;
  }
}

getAllCompanies(){
  this._cs.getCompanyForSelfAndTeam(this.selectedProject,this.selectedActivity,false,true).subscribe(
    data=>{
      
      data['responseData'].forEach(res=>{
        
          res.lookupValue=res.companyName;
          res.id=res.companyId;
        
      })
      var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
   
      if(removeIndex >-1){
        
        this.customAttributesList[removeIndex].lookupValues=data['responseData'];

      }
   
      if(this.flag=="company"){
        if(this.systemAttributecompaniesArray[0]. attributeType. typeName == "Multi Select Dropdown"){
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue([Number(this.companyId)]);
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
        }
        else{
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
        }
       // this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
        if(this.selectedActivity==SystemAttributeEnum.OPPORTUNITY_MODULE){
          const ownerDetail =data['responseData'].find(d=>d.id==Number(this.companyId));
          const idx=this.customAttributesList.findIndex(ele=> ele.systemAttribute?.id==SystemAttributeEnum.OPPORTUNITY_OWNER_FIELD_ID);
          this.customAttributesList[idx].lookupValues=[{id:ownerDetail.ownerId,lookupValue:ownerDetail.ownerName}]
          this.addCompaniesForm.get(this.customAttributesList[idx].combinedAttributeName).setValue(ownerDetail.ownerId);
        }
      }
      else if(this.flag=="opportunity"){
        if(this.companyId !=null){
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
        }
      }
      else if(this.flag=="contact"){
        if(this.companyId !=null){
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
         // this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
        }
      }
      else{
        if(this.companyId !=null){
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
        }
      }
    }
  ) 
}

getAllContacts(setupOppoContactLinkedFieldLV: boolean = false, attribId: number = 0){
  this._contact_service.getAllContactDetailsForSelfAndTeam(this.selectedProject,this.selectedActivity).subscribe(
    data=>{
      data['responseData'].forEach(res=>{
        
          res.lookupValue=res.contactName;
          res.id=res.contactId;
        
      });
      this.allContactLookUp = data['responseData'];
      var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecontactsArray[0].id);
    
      if(removeIndex >-1){
        
        this.customAttributesList[removeIndex].lookupValues=data['responseData'];

      }

      if(this.contactId  && this.flag=="contact" && this.systemAttributecontactsArray[0] !== undefined){
        this.addCompaniesForm.controls[this.systemAttributecontactsArray[0].combinedAttributeName].setValue([Number(this.contactId)]);
      }

      if(setupOppoContactLinkedFieldLV) { // set the lookup array if there any linked field of Opportunitiy module's "Contact" field
        this.setupOppoContactLinkedFieldLV(attribId);
      }
    }
  ) 
}

getAllOwner(){
  this._customAttrService.getOwner(this.selectedProject,Number(this.selectedActivity)).subscribe(
    data=>{
      data['responseData'].forEach(res=>{
        
          res.lookupValue=res.fullName;
        
      })
      var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
      var user = JSON.parse(localStorage.getItem('currentUser'));
      
      if(removeIndex >-1){
        
        this.customAttributesList[removeIndex].lookupValues=data['responseData'];
        this.addCompaniesForm.controls[this.systemAttributeOwnerArray[0].combinedAttributeName].setValue(Number(user.userId));

      }

    }
  ) 
}


getAllOpportunities(){
  let compId = this.companyId;
  if(this.companyId === undefined || this.companyId === null){
    compId = "";
  }
 // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
  this._os.getAllOpportunityDetails2(compId,false,true).subscribe(
    data=>{
     
      data['responseData'].forEach(res=>{
        
          res.lookupValue=res.opportunityName;
          res.id=res.opportunityId;
        
      })
      var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
    
      if(removeIndex >-1){
        
        this.customAttributesList[removeIndex].lookupValues=data['responseData'];
        this.customAttributesList[removeIndex].hasChildAttribute=true;
        if(this.flag !== 'opportunity') {
          this.getAllOpportunitiesDependentInputs(0);// to reset opportunity linked field when select customer changed
        }

      }

      if(this.opportunityId && this.flag=="opportunity"){
        this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
        this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 

      }

    }
  ) 
}

getAllOpportunitiesDependentInputs(id){
  //api to prefill opportunity inputs
  this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
    
    this.systemAttributeopportunityListArray.forEach(res=>{
      if(res.systemAttribute.id==26 && this.opportunityId && this.flag=="opportunity") {
        return;
      }
      if(res.systemAttribute && res.systemAttribute.id == 21) {
        this.resetLinkedCountryStateCityField(res.combinedAttributeName);
      } 
      else if([4, 15, 16].includes(res.attributeType.id)) {
        this.resetLinkedMediaFields(res);
      }else {
        const control = this.addCompaniesForm.controls[res.combinedAttributeName];
        if(control) {
          control.reset();
        }
      }
    });
    
    var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
 
    if(res['responseData'][0] && res['responseData'][0].customAttributeValues) {
      res['responseData'][0].customAttributeValues.forEach(res1=>{
      
        if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
        
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(res1.value[0].id);   
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
        }
        this.systemAttributeopportunityListArray.forEach(res=>{
        
        
          if(res1.value.length >0){
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
              if(res1.systemAttribute && res1.systemAttribute.id == 21) {
                this.setLinkedCountryStateCityField(res.combinedAttributeName, res1.value);
              }
              else if(res.systemAttribute.id==26){
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
              }else{                
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id);  
              }          
            }          
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){ 
           
              if(res.systemAttribute.id==26){
              
                if(this.opportunityId && this.flag=="opportunity"){

                } else{
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                }

              } else{
                if(res.attributeType.id==2){
                  var arraynew=[];
                  res1.value.forEach(d=>{
                    arraynew.push(d.id);
                  })
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                  
                  if(res1.systemAttribute && res1.systemAttribute.id == 6) { // set the lookup array of a field if it's a linked field of Opportunitiy module's "Contact" field (i.e systemAttributeId = 6)
                    if(this.allContactLookUp || this.allContactLookUp.length > 0) {
                      this.setupOppoContactLinkedFieldLV(res.id);
                    } else {
                      this.getAllContacts(true, res.id);
                    }
                  }
                
                } 
                else if([4, 15, 16].includes(res.attributeType.id)){
                  this.assignFilesToLinkedFieldFormControl(res, res1.value);
                
                } else{
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);                  
                }  
              }                          
            }
          }         
        })
      })
    }
  })
}

private updateProducts(skuItems: any) {

  const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
  
  const itemsArray=[];
  skuItems.forEach(data=>{
    itemsArray.push(
      {
        "sku":data.productId,
        "quantity": data.quantity,
        "listPrice":  data.price,
        "sellingPrice" : data.sellingPrice,
        "discount" :  data.discountPercent,
        "tax" :  data.taxPercent,
        "total" :  data.totalPrice
      }
    );
  })

  for (let i = 1; i < itemsArray.length; i++) {
    control.push(this.getSKU());
  }

  this.addCompaniesForm.patchValue({skuItems: itemsArray});

}
  
invokeChildMethod(lookupvalue,customAttr){ 
  if (!lookupvalue) {
    lookupvalue = 0;
  }
  var attributeId=customAttr.id;
  let parentLinkedDataListAttribId = 0;
  
  // Datalist child field condition
  if (customAttr.attributeType.id == 18) {
    attributeId = customAttr.parentDataListId;
    parentLinkedDataListAttribId = customAttr.id;
  } 
  else if (customAttr.moduleType == 6) {
    parentLinkedDataListAttribId = customAttr.parentLinkedDataListAttribId;
  } else if (customAttr.attributeType.id === 1 && customAttr.linkedAttributeId !== null) {
    attributeId = customAttr.linkedAttributeId;
  }
 
  //for opportunity Owner field
   if(customAttr.systemAttribute?.id==SystemAttributeEnum.OPPORTUNITY_COMPANY_NAME_FIELD_ID){
    const ownerLookupDetail=customAttr.lookupValues.find(a=>a.id==lookupvalue);
    const idx=this.customAttributesList.findIndex(ele=> ele.systemAttribute?.id==SystemAttributeEnum.OPPORTUNITY_OWNER_FIELD_ID);
    this.customAttributesList[idx].lookupValues=[{id:ownerLookupDetail.ownerId,lookupValue:ownerLookupDetail.ownerName}]
    this.addCompaniesForm.get(this.customAttributesList[idx].combinedAttributeName).setValue(ownerLookupDetail.ownerId);
   }
 
  //if(customAttr.attributeName =='Select Customer'){
  if(customAttr.systemAttribute && customAttr.systemAttribute.id == 25){ // for select customer field
   this.companyId=lookupvalue;
   this.ds.setEntityType(lookupvalue);
   if(this.flag == 'company') {
    this.opportunityId = null;
   }
   this.getAllOpportunities();
   if(this.flag == 'contact' || this.flag=='company') { // while adding activity from contact dashboard OR while switching to companies from add activity -> update company field values on customer change 
     this.getAllCompanyDependentInputs(lookupvalue);
   } else {
    this.ngOnInit();
   }
  //  this.companyId=lookupvalue;
  }

  if(customAttr.systemAttribute && customAttr.systemAttribute.id == 26){ // for select opportunity field
    this.opportunityId=lookupvalue;   
  }


  if(this.systemAttributeopportunityListArray.length>0){      
      this.systemAttributeopportunityListArray.forEach(rep=>{
      var divId = this.createDivId(rep);
      var division1=<HTMLElement>document.getElementById(divId);
      
      if(division1){            
        this.addValidators(rep.combinedAttributeName, rep.mandatoryFlag === 1, rep.attributeType.id, rep.decimalFlag === 1);            
        division1.classList.remove('hidden');
      }
    })
    if(customAttr.systemAttribute && customAttr.systemAttribute.id == 26){
      this.getAllOpportunitiesDependentInputs(lookupvalue);
    }
  }
  
  if(this.systemAttributeEstimateArray.length>0){
    //fill sku table
    this._customAttrService.getActivity(lookupvalue, this.selectedProject).subscribe(
      (result)=>{
        
        result['responseData'][0].customAttributeValues.forEach(res=>{
          
          if(res.systemAttribute){
            if(res.systemAttribute.id==28){                
              var actId=result['responseData'][0].activityId;               
              this.updateProducts(res.value);
            }
          }
        }) 

      },
      error => {
        console.log(error)
      }
    )
  }

  //deleting previous product form if it is dependent on other field having no child(attributeId)
  if( this.previousDependentProductFormArray.length>0){
    this.dependentProductFormDeleteOrAdd(attributeId, null,lookupvalue,false);
  }
  
  if(customAttr.hasChildAttribute){
    //cal  api
    this.showSpinner();
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{
      
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          });
          let childAttribDivIds = [];

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{    
              //logic for product form if it is dependent on other field(attributeId)
              if(element.parentFormId!=null|| this.previousDependentProductFormArray.length>0){
                this.dependentProductFormDeleteOrAdd(attributeId, element,lookupvalue,true);
              }       
              if(customAttr.linkedAttributeId!==null) {
                const linkedField = this.customAttributesList.find(x => x.linkedAttributeId === element.id);
                if(linkedField) {
                  element.combinedAttributeName = linkedField.combinedAttributeName;
                  element.mandatoryFlag = linkedField.mandatoryFlag;
                }
              }       
              // update combined attribute name and mandatoryFlag info for datalist child fields
              if (customAttr.attributeType.id == 18) {
                element.combinedAttributeName = customAttr.combinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }    
              else if(customAttr.moduleType == 6 && customAttr.parentCombinedAttributeName) {
                element.combinedAttributeName = customAttr.parentCombinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
              var divId = this.createDivId(element, customAttr.linkedAttributeId!==null, this.customAttributesList);
              childAttribDivIds.push(divId);
            
              if(removeIndex >-1){
              
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item.divId; }).indexOf(String(divId));
              
                if(removeIndexInside >-1){
                  //yes exists then no need to do anything
                }else{
                  this.openDivs[removeIndex].value.push({
                    divId: divId, combinedAttributeName: element.combinedAttributeName, 
                    id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                  });
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                    this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);          
                    division1.classList.remove('hidden');
                  } 
                }
              } else {
                
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    {
                      divId: divId, combinedAttributeName: element.combinedAttributeName, 
                      id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                    }
                  ] 
                });

                var division1=<HTMLElement>document.getElementById(divId);
                
                if(division1){            
                  this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);          
                  division1.classList.remove('hidden');
                } 
              }               
        
              const childAttrib = this.findChildAttribByIdAndCombinedName(element.id, element.combinedAttributeName, customAttr.linkedAttributeId!==null);

              if(childAttrib){
                childAttrib.lookupValues=element.lookupValues;
                this.resetChildAttribsAndinvokeChildMethod(childAttrib);       
              }
          
            });
            // ----------------> START:: hiding the divs of child attrib when such a value selected for parent attrib 
            // where there's no relation mapping with child attrib
            var removeIndex = this.openDivs.map(function (item) { return item.attributeId; }).indexOf(attributeId);
            let childAttribsToKeep = [];
            if (removeIndex > -1) {
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res => {
                if (parentLinkedDataListAttribId===res.parentLinkedDataListAttribId && !childAttribDivIds.includes(res.divId)) {
                  var division = <HTMLElement>document.getElementById(res.divId);
                  if (division) {
                    const control = this.addCompaniesForm.get(res.combinedAttributeName);
                    if (control) {
                      control.clearValidators();
                      control.reset();
                      control.updateValueAndValidity();
          
                      const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);

                      if (childAttrib) {
                        this.resetChildAttribsAndinvokeChildMethod(childAttrib);
                      }
                    }
                    division.classList.add('hidden');
                  }
                } else {
                  childAttribsToKeep.push(res);
                }
              }); 
            };
            this.openDivs[removeIndex].value = childAttribsToKeep;
            // <---------------- END

          } else {
            // -> hiding the divs of child attrib whose relationships doesn't exist anymore
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
            let markToRemovedChildDivIds: string[] = [];
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                if(res.parentLinkedDataListAttribId===parentLinkedDataListAttribId) { // proceed only if parentLinkedDataListAttribId is matched
                  markToRemovedChildDivIds.push(res.divId);
                  var division=<HTMLElement>document.getElementById(res.divId);
                  if(division){              
                    const control = this.addCompaniesForm.get(res.combinedAttributeName);
                    if (control) {
                      control.clearValidators();
                      control.reset();
                      control.updateValueAndValidity();

                      const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);

                      if (childAttrib) {
                        this.resetChildAttribsAndinvokeChildMethod(childAttrib);
                      }
                    }
                    division.classList.add('hidden');
                  }
                  division.classList.add('hidden');
                }
              }); 
              //remove key an pairs
              if(this.openDivs[removeIndex].value) {
                if(this.openDivs[removeIndex].value.length === markToRemovedChildDivIds.length) { // remove the whole
                  this.openDivs.splice(removeIndex, 1);           
                }        
                else if(this.openDivs[removeIndex].value.length !== markToRemovedChildDivIds.length) { // remove only the mark for remove ones
                  this.openDivs[removeIndex].value = this.openDivs[removeIndex].value.filter(x => !markToRemovedChildDivIds.includes(x.divId));
                }
              }
            // <- END
            }            
          }            
        }
        this.hideSpinner();
      }
    )
  }
}

findChildAttribByIdAndCombinedName(id, combinedAttributeName, isLinkedField) {
  if(isLinkedField) {
    return this.customAttributesList.find(item => item.linkedAttributeId == id);
  }
  return this.customAttributesList.find(item => item.id == id && item.combinedAttributeName == combinedAttributeName);
}

resetChildAttribsAndinvokeChildMethod(childAttrib) {
  //  -> it will reset child attrib and invoke child method for the same to remove the grand child attribs
  const control = this.addCompaniesForm.get( childAttrib.combinedAttributeName);
  if(control){
    control.reset();
    if([4, 15, 16].includes(childAttrib.attributeType.id)) {
      const selectedFileLength = this.fileArray[childAttrib.combinedAttributeName].files.length;
      for (let index = 0; index < selectedFileLength; index++) {
        this.removeFile(childAttrib.combinedAttributeName, 0)
      }
    }
  }
  if(childAttrib.attributeType.id == 1) {
    this.invokeChildMethod(0, childAttrib);
  }
}

createDivId(customAttr, isLinkedField: boolean = false, fieldList: any[] = []): string {
  if(isLinkedField) {
    const linkedField = fieldList.find(x => x.linkedAttributeId === customAttr.id);
    if(linkedField) {
      return 'div_' + linkedField.combinedAttributeName + '_' + linkedField.id;
    }
  }
  return 'div_' + customAttr.combinedAttributeName + '_' + customAttr.id;
}

showSpinner() {
  if( this.spinnerCount++ === 0) {
    this.SpinnerService.show('addCompany');
  }
}

hideSpinner() {
  if( --this.spinnerCount === 0) {
    this.SpinnerService.hide('addCompany');
  }
}

addValidators(combinedAttributeName: string, mandatoryFlag: boolean, attributeTypeId: number, decimalFlag: boolean){
  if([10, 11, 17].includes(attributeTypeId)){
    return;
  }
  let validatorFns: ValidatorFn[] = [];
  if(mandatoryFlag){
    validatorFns.push(Validators.required);
  }
  if(attributeTypeId == 7){
    let pattern=this.numericPattern;
    if(decimalFlag) {
      pattern = this.decimalPattern;
    }
    validatorFns.push(Validators.pattern(pattern));
  }
  else if(attributeTypeId == 9){
    validatorFns.push(Validators.email);
  }
  const control = this.addCompaniesForm.get(combinedAttributeName);
  if(control) {
    control.setValidators(validatorFns);
    control.updateValueAndValidity();
  }
}

getAllCompanyDependentInputs(id){
  //api to prefill opportunity inputs
  this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{
    this.systemAttributecompanyListArray.forEach(res=>{
      if(res.systemAttribute && res.systemAttribute.id == 16) {
        this.resetLinkedCountryStateCityField(res.combinedAttributeName);
      } 
      else if([4, 15, 16].includes(res.attributeType.id)) {
        this.resetLinkedMediaFields(res);
      }
      else {
        const control = this.addCompaniesForm.controls[res.combinedAttributeName];
        if(control) {
          control.reset();
        }
      }
    });

    if(res['responseData'][0]) {
      res['responseData'][0].customAttributeValues.forEach(res1=>{
      
        this.systemAttributecompanyListArray.forEach(res=>{
    
          if(res1.value.length >0){
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
              if(res1.systemAttribute && res1.systemAttribute.id == 16) {
                this.setLinkedCountryStateCityField(res.combinedAttributeName, res1.value);
              } else {
                if(res.attributeType.id==2){
                  var arraynew=[];
                  res1.value.forEach(d=>{
                    arraynew.push(d.id);
                  })
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                
                }else{
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
                
                }
              }
              
              
            }
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              if(res.attributeType.id==2){
                var arraynew=[];
                res1.value.forEach(d=>{
                  arraynew.push(d.id);
                })
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
              }  
              else if([4, 15, 16].includes(res.attributeType.id)){
                this.assignFilesToLinkedFieldFormControl(res, res1.value);
              
              } else{
                let value = res1.value[0].name;
                if(res1.systemAttribute && res1.systemAttribute.id == 2){
                  value = id;
                }
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(value);  
              }
            }                          
          }
        })
      })
    }
  })
}

setupOppoContactLinkedFieldLV(attribId: number) {
  const index= this.customAttributesList.map(function(item) { return item.id; }).indexOf(attribId);
  if(index >-1){
    this.customAttributesList[index].lookupValues=this.allContactLookUp;
  }
}

  getSKU() {
    
    return this.fb.group({
     sku: [''],
     quantity: [''],
     listPrice: [''],
     sellingPrice: [''],
     discount: [''],
     tax: [''],
     total: ['']
    });
  }

  resetLinkedCountryStateCityField(combinedAttribName: string) {
    const control1 = this.addCompaniesForm.controls[combinedAttribName+this.fixedCountryfieldName];
    if(control1) {
      control1.reset();
      this.stateListAct[combinedAttribName] = [];
    }
    const control2 = this.addCompaniesForm.controls[combinedAttribName+this.fixedStatefieldName];
    if(control2) {
      control2.reset();
      this.cityListAct[combinedAttribName] = [];
    }
    const control3 = this.addCompaniesForm.controls[combinedAttribName+this.fixedCityfieldName];
    if(control3) {
      control3.reset();
    }
  }

  resetLinkedMediaFields(element: CustomAttibute) {
    this.fileArray[element.combinedAttributeName].files = [];
    this.fileArray[element.combinedAttributeName].documentIcons = [];
    this.fileArray[element.combinedAttributeName].uploaded = 0;
    this.fileArray[element.combinedAttributeName].hasError = false;
    this.fileArray[element.combinedAttributeName].showUploadIcon = true;
    this.addCompaniesForm.controls[element.combinedAttributeName].reset();
  } getDecimalFieldLength(value: string): number {
    if(value) {
      const wholeNumber = this.extractWholeNumber(String(value));
      if(wholeNumber !== null) {
        return String(wholeNumber).length;
      }
    }
    return 0;
  }

  extractWholeNumber(val: String): number {
    if(val!=='' && val!==null) {
      val = val.replace(/\.\d*/, '');
      if(val!=='' && val!==null && val.match(/^\d*$/) !==null) {
        return Number(val);
      }
    }
    return null;
  }

  checkFileType(file, attributeTypeId, validDocTypes) {
    if(attributeTypeId == 4){
      return file.type.match('image/jpeg|image/png');
    }
    if(attributeTypeId == 15){
      for(let type of validDocTypes){
        if(file.type.match(type.matchString)){
          return true;
        }
      }
      return false;
    }
    if(attributeTypeId == 16){
      return file.type.match('video/mp4|video/quicktime');
    }
    else{
      return false;
    } 
  }

  getFileErrorMsg(attribTypeId, allowedDocs){
    if(attribTypeId == 4){
      return 'Only .jpeg, .png files allowed';
    }
    if(attribTypeId == 15){
      return `Only ${allowedDocs} files allowed`;
    }
    if(attribTypeId == 16){
      return 'Only .mp4, .mov files allowed';
    }
    return 'Error';

  }
	
  getSlicedErrorFileName(name: string){
    if(name.length <=20){
      return name;
    }
    return ". . . ." + name.slice(-20);
  }

  updateFileControlValue(attr){
    this.addCompaniesForm.controls[attr].setValue(this.fileArray[attr].files);
  }

  checkUploadQueue(attr, event, updateControl: boolean = true){
    if(--this.uploadQueue[attr] == 0){
      this.SpinnerService.hide(attr);
      if(updateControl){
        this.updateFileControlValue(attr)
      }
      event.target.value='';
      delete this.uploadQueue[attr];     
    }
  }
        
  processFile(event, attribName, attributeTypeId, maxImage, fieldCombinedAttributeName: string = '',formArrayIndex:string='') {
    const arrayAttribName=fieldCombinedAttributeName+formArrayIndex;
    const initialFileCount = event.target.files.length;
    if (initialFileCount > 0) {
      this.SpinnerService.show(attribName+arrayAttribName);
      const fileAttr = this.fileArray[attribName+arrayAttribName];
      fileAttr.hasError = false;
      this.uploadQueue[attribName+arrayAttribName] = initialFileCount;
    
      let uploadedFilesCount = fileAttr.files.length;
      for (let i = 0; i < initialFileCount; i++) {
        if ( !maxImage || uploadedFilesCount < maxImage) {
          if (this.checkFileType(event.target.files[i], attributeTypeId, fileAttr.validDocType)) {
            if(attributeTypeId == 4){
              this.subscriptions.push(this._us.uploadImage(this.selectedActivity, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
      
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                if(arrayAttribName!=null && arrayAttribName!='' && (formArrayIndex!=null && formArrayIndex.toString()!='')){
                  const control = <FormArray>this.addCompaniesForm.controls[attribName];
                  control.at(+formArrayIndex).get(fieldCombinedAttributeName).setValue(fileAttr.files);
                }
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if(attributeTypeId == 15){
              this.subscriptions.push(this._docUpload.uploadDoc(this.selectedActivity, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let fileType = res.responseData.name.split('.').pop().toLowerCase();
                fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
  
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
  
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if(attributeTypeId == 16){
              if(event.target.files[i].size < 26214400){
                this.subscriptions.push(this._docUpload.uploadDoc(this.selectedActivity, event.target.files[i], this.selectedProject).subscribe(res => {
                  fileAttr.files.push(res.responseData.name);
                  let uploadCount = fileAttr.files.length;
                  fileAttr.uploaded = uploadCount;
    
                  fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    
                  this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                },
                  error => {
                    fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                    fileAttr.hasError = true;
                    this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
                  })
                );
              }
              else{
                fileAttr.errorMsg = 'File size limit 25 MB';
                fileAttr.hasError = true;
                this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
              }
            }
              // this.subscriptions.push(fileUploadSubscription);
            uploadedFilesCount++;
          } else {
            fileAttr.errorMsg = this.getFileErrorMsg(attributeTypeId, fileAttr.allowedDocText);
            fileAttr.hasError = true;
            this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
          }  
        } else {
          this.uploadQueue[attribName+arrayAttribName] -= (initialFileCount-i-1);
          this.checkUploadQueue(attribName+arrayAttribName, event, arrayAttribName === '');
          break;
        }
      }      
    }
  }

  removeFile(attr, id, arrayAttribName:string = ''){
    const fileAttr = this.fileArray[attr + arrayAttribName];
    if(fileAttr){
      fileAttr.files.splice(id, 1);
      fileAttr.documentIcons?.splice(id, 1);
      let uploadCount = fileAttr.files.length;
      fileAttr.uploaded = uploadCount;  
      fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    }
      fileAttr.hasError = false;
      if(arrayAttribName === ''){
        this.updateFileControlValue(attr)
      }
}

// showImageModalOnly(imgURL){
//   const ref = this.modalService.open(ImageViewerComponent,
//     {
//       centered: false,
//       size : 'xl'
//     }
//   );
//   ref.componentInstance.dataString = imgURL.replace(this.baseUrl, '');

//   ref.result.then((result) => {
//    // this.modalResult(result);
//   });
// }

  showRefDocModal(attr, id){
    const fileAttr = this.fileArray[attr];
    if(fileAttr){
      let fileType = fileAttr.files[id].split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      if(icon){
        window.open(fileAttr.files[id]);
      }
      else{
        const ref = this.modalService.open(ImageViewerComponent,
          {
            centered: false,
            size : 'xl'
          }
        );
        ref.componentInstance.dataString = fileAttr.files[id].replace(this.baseUrl, '');
    
        ref.result.then((result) => {
         // this.modalResult(result);
        });
      }
    }
}

  isControlHasError(controlName: string, validationType: string): boolean {

    if(controlName==='sku'){
      var control = this.addCompaniesForm.controls['skuItems'].get('sku');
      if (!control) {
        return false;
      }
    } else{
      var control = this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    }
   
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }
  closeModal() {
    window.close();
    this.activeModal?.close();
  }
  save() {
    if(this.isSaveButtonCalled==true){
      return;
    }
    this.isSaveButtonCalled=true;
    this.disabled=true;
    this.errorFlag=false;
    
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      var message='';
      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        const min = element.minDigits;
        const max = element.maxDigits;
        let elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!="" && elementValue!=null){
          if(element.decimalFlag) {
            elementValue = this.extractWholeNumber(elementValue);
          }
          if((min!=null && min!="0" && min!=0 && Number(min) > Number(elementValue.toString().length)) 
              || (max!=null && max!="0" && max!=0 && Number(elementValue.toString().length) > Number(max))){
                if((max!=null || max!="0" || max!=0) && (min!=null && min!="0" && min!=0 && Number(min) > Number(elementValue.toString().length))) {
                  message='Enter number greater than '+Number(min) +' digits for '+element.attributeName;
                } else if((min!=null || min!="0" || min!=0) && (max!=null && max!="0" && max!=0 && Number(elementValue.toString().length) > Number(max))) {
                  message='Enter number less than '+Number(max) +' digits for '+element.attributeName;
                } else {
                  message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.attributeName;
                }
            this.errorFlag=true;
            this.OpenAlertValidation(message);
            this.disabled=true;
          }
        }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
       
        const min = element.minNumber;
        const max = element.maxNumber;
        const elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!="" && elementValue!=null){
          if((min!=null && Number(min) > Number(elementValue)) || (max!=null && Number(elementValue) > Number(max))){
            this.errorFlag=true;
            message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.attributeName;
            this.OpenAlertValidation(message);
            this.disabled=true;
          }
        }        
      } 
     
    
    // image validation section
    if(element.attributeType.id === 4 && element.maxImagesAllowed != 0 ){      
      const fileAttr = this.fileArray[element.combinedAttributeName];
      if(fileAttr.files.length > element.maxImagesAllowed){
        fileAttr.hasError = true;
        fileAttr.errorMsg = `Maximum ${element.maxImagesAllowed} image(s) allowed`;
        this.errorFlag = true;
      }                 
    }


    })
    
    const controls = this.addCompaniesForm.controls;
  

    if (this.addCompaniesForm.invalid) {
      // this.addProductError=true;
      Object.keys(controls).forEach(controlName =>{
        controls[controlName].markAsTouched();
        if((controls[controlName] as FormArray).controls){
          if((controls[controlName] as FormArray).controls.length>0){
            const grps = <FormGroup[]>(controls[controlName] as FormArray).controls;
            grps.forEach(g => {
              Object.keys(g.controls).forEach( name => {
                g.controls[name].markAsTouched();
              });
            })
          }
        }
        }
      );
      this.disabled=false;
      this.isSaveButtonCalled=false;
      return;
    }

    if(this.errorFlag){
      this.disabled=false;
      this.isSaveButtonCalled=false;
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }
 
    const addData=this.AddCompany(controls);
    if(this.isFromCallLogsPage) {
      addData.monitorUcid = this.callLogDataForEditActivity.monitorUcid;
    } else {
      addData.monitorUcid = this.ozonetelQueryParamter['monitorUcid'];
    }

    this.SpinnerService.show("addCompany");

    this._customAttrService.add(addData,this.selectedActivity).subscribe(

      data => {
        if(this.flag == 'opportunity' && data['responseData'].contactId != undefined){
          this.mapOportunityWithContact(data['responseData'].contactId, this.opportunityId, this.selectedProject);
        }
        if(data['statusCode']>=200){
          window.close();
          this.activeModal?.close('SUCCESS');
        }
        
        this.addCompaniesForm.reset();
       
        //temp commented by Ankit Raj
        /*
        if(this.activityFlag){

          this.eventEmitterService.onModalCloseResult(this.parentData);
          
        } else{
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        }
        */
        this.SpinnerService.hide("addCompany");
        
      },
      error => {
        this.disabled=false;
        this.isSaveButtonCalled=false;
        this.errorMes=error.error.responseData?.errorMsg;
        this.SpinnerService.hide("addCompany");
        //this.eventEmitterService.onModalCloseResult('ERROR');
      }        
    );    
  }
  AddCompany(controls){
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      
      if((element.attributeType.id==1 ||
          element.attributeType.id==2 ||
          element.attributeType.id==5 ||
          element.attributeType.id==6 ||
          element.attributeType.id==20||
          element.attributeType.id==7 || 
          element.attributeType.id==8 || 
          element.attributeType.id==9 ||
          element.attributeType.id==18) && 
          (element.systemAttribute.id!==16) && 
          (element.systemAttribute.id!==15) && 
          (element.systemAttribute.id!==21) &&
          (element.systemAttribute.id!==28)){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "parentLinkedDatalistAttribId": element.moduleType==6 ? element.parentLinkedDataListAttribId : null,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
            
          ]
        });
      }else{
       
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
        
          const [country, state, city] = this.getCuntryStateCityFormValue(element.combinedAttributeName);
          var address2='';
          if(country!=null && state!=null && city!=null){
            address2=(country.trim()+'->'+state.trim()+'->'+city.trim());
          } else if(country!=null && state==null && city==null){
            address2=(country.trim());
          } else if(country!=null && state!=null && city==null){
            address2=(country.trim()+'->'+state.trim());
          } else{
           address2='';
          }
                  
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':address2
              }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }                      
          ]          
        })
      }
      if(element.attributeType.id==21 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']?.replace('T', ' ')
            }                      
          ]          
        })
      }
      if(element.attributeType.id==22 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']
            }                      
          ]          
        })
      }

      if(element.attributeType.id==4 || element.attributeType.id==15 || element.attributeType.id==16){
        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          if( this.addCompaniesForm.controls[id]['value'] != null){
            this.addCompaniesForm.controls[id]['value']?.forEach(resultImage=>{
              resultArrayOfImages.push(
                {
                  'name':resultImage
                }
              )
            })
          }
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages            
          })
        }               
      }

      if(element.systemAttribute.id==28){
            
        /*
        const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
        var length=this.addCompaniesForm.controls.skuItems.value.length;

        for(var i=0;i<length;i++){
           
          
          this.skuItemsArray.push(
            {
              "productId": control.at(+i).get('sku').value,
              "price": Number(control.at(+i).get('listPrice').value).toFixed(2),
              "quantity": control.at(+i).get('quantity').value,
              "sellingPrice": Number(control.at(+i).get('sellingPrice').value).toFixed(2),
              "discountPercent": Number(control.at(+i).get('discount').value).toFixed(2),
              "taxPercent": Number(control.at(+i).get('tax').value).toFixed(2),
              "totalPrice": Number(control.at(+i).get('total').value).toFixed(2)
            }
          )  
        }

        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":this.skuItemsArray,
          "systemAttribute":{
            "id": element.systemAttribute.id
          }   
        })
        */
      }

      if(element.attributeType.id==13 && element.parentFormId){
        this.skuItemsArray = {};
        let k=0;
            
        const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
        var length=this.addCompaniesForm.controls[element.combinedAttributeName]?.value.length||0;

        for(var i=0;i<length;i++){

           let productFormData = {};
           let productRow = [];

          for(let j=0; j < this.productFormFields[element.combinedAttributeName].fields?.length; j++){
            const column = this.productFormFields[element.combinedAttributeName].fields[j];

            if(column.attributeType.id == 4){
              productFormData = {
                attributeId: column.id,
                value: (this.fileArray[element.combinedAttributeName + column.combinedAttributeName + i].files as Array<string>)?.join(',')
              };
            }
            else{
              productFormData = {
                attributeId: column.id,
                value: control.at(+i).get(column.combinedAttributeName).value
              };
            }

            if(productFormData['value'] !== null && productFormData['value'] !== '' ){
              productRow.push(productFormData);
            }
          }
           k=k+1;
           this.skuItemsArray[k]=productRow;
        }

        let summaryLevelDiscount={};
        let value1= this.productFormFields[element.combinedAttributeName]?.discountValue||0;
        let decimalValue=(Math.round(Number(value1)*100)/100).toFixed(2);
        let value2= this.productFormFields[element.combinedAttributeName]?.discountPercent||0;
        let decimalPercent=(Math.round(Number(value2)*100)/100).toFixed(2);
        // let value = (<string> this.productFormFields[element.combinedAttributeName].discountValue)
        summaryLevelDiscount={
          // "value": Number.parseFloat(Number(this.productFormFields[element.combinedAttributeName].discountValue).toFixed(2)),
          "value":decimalValue,
          "percent": decimalPercent
        }
        this.skuItemsArray["summaryDiscount"]=summaryLevelDiscount;

        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[this.skuItemsArray],
          // productTotal: Number(this.productFormFields[element.combinedAttributeName].total).toFixed(2),
          // discountValue: Number(this.productFormFields[element.combinedAttributeName].discountValue).toFixed(2),
          // discountPercent: Number(this.productFormFields[element.combinedAttributeName].discountPercent).toFixed(2),
          // "systemAttribute":{
          //   "id": element.systemAttribute.id
          // }   
        })
      }

    })

    function formatDate(date) {
      if(date == null || date == "")
        return "";
      else {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
      }
    }
    var addData;
    if(this.selectedActivity==1 || this.selectedActivity==2 || this.selectedActivity==3 || this.selectedActivity==4 ){
       addData = {
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };  
    }else{
       addData = {
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "moduleType":this.selectedActivity,
        "planId":this.planId,
        "customAttributeValues": this.customAttributeValues
  
      };  
    }
  
    return addData;
  }
  OpenAlertValidation(message){
    const initialNotice = message;
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       width: '600px',
       position: {top: '20px'},
       data: { title: 'Alert', body: initialNotice }
     });
  
    }
    getCuntryStateCityFormValue(combinedAttributeName: string) {
      let country = '';
      let state = '';
      let city = '';
      if(this.selectedActivity > 100) {
        country = this.addCompaniesForm.controls[combinedAttributeName + this.fixedCountryfieldName]['value'];
        state = this.addCompaniesForm.controls[combinedAttributeName + this.fixedStatefieldName]['value'];
        city = this.addCompaniesForm.controls[combinedAttributeName + this.fixedCityfieldName]['value'];
      } else {
        country = this.addCompaniesForm.controls[this.fixedCountryfieldName]['value'];
        state = this.addCompaniesForm.controls[this.fixedStatefieldName]['value'];
        city = this.addCompaniesForm.controls[this.fixedCityfieldName]['value'];
      }
      return [country, state, city];
    }
    private mapOportunityWithContact(contactId: any, opportunityId: any, projectId: any){
      this._customAttrService.mapOpportunityContact(contactId, opportunityId, projectId).subscribe(
        data => {
        },
        error => {
          this.errorMes=error.error.responseData.errorMsg;        
        }
      );
    }

  //This method will set the call details fields when we perform activity from (CRM>Call Logs) page
  fetchCallDetailsForCallLogsPage() {
    this.disabled = false;
    const monitorUcid = this.callLogDataForEditActivity.monitorUcid;
    this._ds.fetchCallDetailsByUcid(this.selectedProject, monitorUcid).subscribe(
      data=>{
        if(data['statusCode']==200 && data['responseData']!=null){
          const callDetails=data['responseData'];
          this.customAttributesList.forEach(res=>{
            if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_DATE_TIME_FIELD_ID){
              this.addCompaniesForm.get(res.combinedAttributeName).setValue(callDetails.startTime);
            }
            if(res.systemAttribute.id==SystemAttributeEnum.DIALER_DURATION_FIELD_ID){
              this.addCompaniesForm.get(res.combinedAttributeName).setValue(callDetails.callDuration);
            }
            if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_TYPE_FIELD_ID){
              let lookupId='';
              if(callDetails.callStatus=='NotAnswered'&&(callDetails.callType=='Manual' ||callDetails.callType=='InBound')){
                lookupId = res.lookupValues.find(a => a.stageId === 103)?.id;
              }
              else if(callDetails.callType=='Manual'){
                lookupId = res.lookupValues.find(a => a.stageId === 101)?.id;
              }else if(callDetails.callType=='InBound'){
                lookupId = res.lookupValues.find(a => a.stageId === 102)?.id;
              }
              this.addCompaniesForm.get(res.combinedAttributeName).setValue(lookupId)
            }
            if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID && callDetails.callRecording?.length>0){
              let files = [];
              this.fileArray[res.combinedAttributeName] = {
                "attributeName": res.combinedAttributeName,
                "files": files,
                "maxUpload": res.maxImagesAllowed,
                "showUploadIcon": false,
                "uploaded": 0,
                "hasError": false
              };
              const fileAttr = this.fileArray[res.combinedAttributeName];
                    files.push(callDetails.callRecording);
                    fileAttr.files = files;
                    fileAttr.uploaded = files.length;
                    this.updateFileControlValue(res.combinedAttributeName);
            }
          })
        }
      }, 
      error=>{
        this.snackBar.open('Unable to load data,please try again ', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    );
  }

fetchCallDetails(hideMsg = false){


 this._ds.fetchCallDetailsByUcid(this.selectedProject,this.ozonetelQueryParamter['monitorUcid']).subscribe(
  data=>{
    if(data['statusCode']==200 && data['responseData']!=null){
      const callDetails=data['responseData'];
      this.customAttributesList.forEach(res=>{
        if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_DATE_TIME_FIELD_ID){
          this.addCompaniesForm.get(res.combinedAttributeName).setValue(callDetails.startTime);
        }
        if(res.systemAttribute.id==SystemAttributeEnum.DIALER_DURATION_FIELD_ID){
          this.addCompaniesForm.get(res.combinedAttributeName).setValue(callDetails.callDuration);
        }
        if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_TYPE_FIELD_ID){
          let lookupId='';
          if(callDetails.callStatus=='NotAnswered'&&(callDetails.callType=='Manual' ||callDetails.callType=='InBound')){
            lookupId = res.lookupValues.find(a => a.stageId === 103)?.id;
          }
          else if(callDetails.callType=='Manual'){
            lookupId = res.lookupValues.find(a => a.stageId === 101)?.id;
          }else if(callDetails.callType=='InBound'){
            lookupId = res.lookupValues.find(a => a.stageId === 102)?.id;
          }
          this.addCompaniesForm.get(res.combinedAttributeName).setValue(lookupId)
        }
        if(res.systemAttribute.id==SystemAttributeEnum.DIALER_CALL_RECORDING_FIELD_ID && callDetails.callRecording?.length>0){
          let files = [];
          this.fileArray[res.combinedAttributeName] = {
            "attributeName": res.combinedAttributeName,
            "files": files,
            "maxUpload": res.maxImagesAllowed,
            "showUploadIcon": false,
            "uploaded": 0,
            "hasError": false
          };
          const fileAttr = this.fileArray[res.combinedAttributeName];
                files.push(callDetails.callRecording);
                fileAttr.files = files;
                fileAttr.uploaded = files.length;
                this.updateFileControlValue(res.combinedAttributeName);
        }
      })
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }

    }else{
      if(!hideMsg) {
        this.snackBar.open('Seems like call has still not ended,please try again after disconnecting the call ', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    }
    this.disabled=false;
  },error=>{
    this.snackBar.open('Unable to load data,please try again ', '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
    this.disabled=false;
  }
 )
  
  
}
productFormFieldChange(combinedAttributeName,i,field,value){
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  if(field.attributeType.id==7){
    if(!this.productFormFields[combinedAttributeName].numericData[i][field.id] && value){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] += 1;
    }
    else if(this.productFormFields[combinedAttributeName].numericData[i][field.id] && !value){
      this.productFormFields[combinedAttributeName].enteredNumericFields[i] -= 1;
      this.productFormFields[combinedAttributeName].numericData[i][field.id] = null;
    }

    if(value){
      this.productFormFields[combinedAttributeName].numericData[i][field.id]={
        attributeId: field.id,
        attributeName: field.attributeName,
        combinedAttributeName: field.combinedAttributeName,
        value: value
      };
    }
    
  }
  if(this.productFormFields[combinedAttributeName].enteredNumericFields[i]==this.productFormFields[combinedAttributeName].numericFields){
    const keys = Object.keys(this.productFormFields[combinedAttributeName].numericData[i]);
    let productFormData=[];
    keys.forEach((key, index) => {

    productFormData.push(
    this.productFormFields[combinedAttributeName].numericData[i][key]
    )   
    
  });
  // call api method
  this.calculateTotal(combinedAttributeName, productFormData, i, control);
  
  }
}
clickImage(attribName, arrayAttribName: string = null){
  if(arrayAttribName !== null){
    let element:HTMLElement =document.getElementById(attribName + arrayAttribName) as HTMLElement;
    element.click();
  }
  else{
    let element:HTMLElement =document.getElementById(attribName) as HTMLElement;
    element.click();
  }
}
deleteRow(combinedAttributeName, i: number) {
  const control = <FormArray>this.addCompaniesForm.controls[combinedAttributeName];
  if(control.length===1) return;
  control.removeAt(i);

  this.productFormFields[combinedAttributeName].enteredNumericFields.splice(i, 1);
  this.productFormFields[combinedAttributeName].numericData.splice(i, 1);

  let totalFieldCombinedName ='';
  this.productFormFields[combinedAttributeName].fields.forEach(f => {
    if(f.systemAttribute?.id === 52){
      totalFieldCombinedName = f.combinedAttributeName;
    } 
  });
  this.calculateSummeryFields(combinedAttributeName);
}

  toggleAllSelection(matSelect: MatSelect,customAttr) {
    if (customAttr.allSelected) {
      matSelect.options.forEach((item: MatOption) => {
        if(item.value!==undefined && item.value!==null) {
          item.select();
        }  });
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick(matSelect: MatSelect,customAttr) {
    let newStatus = true;
    matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    customAttr.allSelected = newStatus;
  }

isFormArrayControlHasError(controlName: string,formArrayControlName,formArrayIndex, validationType: string): boolean {

  const control =<FormArray>this.addCompaniesForm.controls[controlName];
  if (!control) {
    return false;
  }
const formArrayControl=control.at(formArrayIndex).get(formArrayControlName)
  const result = formArrayControl.hasError(validationType) && (formArrayControl.dirty || formArrayControl.touched);
  return result;
}

private dependentProductFormDeleteOrAdd(attributeId: any, element: any,lookupId:any,hasChild:boolean) {
  if (this.previousDependentProductFormArray.some(r => r.parentAttribId == attributeId)) {

    const indexesToRemove = this.previousDependentProductFormArray
      .map((item, index) => ({ index, item }))
      .filter(({ item }) => item.parentAttribId === attributeId && item.parentLookupId !== lookupId)
      .map(({ index }) => index);
    
    if (indexesToRemove.length > 0) {
      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        const idx = indexesToRemove[i];
        const combinedAttribToBeDeleted = this.previousDependentProductFormArray[idx].combinedAttributeName;
    
        // Additional operations on deletion
        const prevControl = <FormArray>this.addCompaniesForm.controls[combinedAttribToBeDeleted];
        this.productFormFields[combinedAttribToBeDeleted].fields.forEach((y: CustomAttibute) => {
          if (y.attributeType.id == 4) {
            for (let j = 0; j < prevControl.length; j++) {
              const propertyToDelete = combinedAttribToBeDeleted + y.combinedAttributeName + j;
              if (propertyToDelete in this.fileArray) {
                delete this.fileArray[propertyToDelete];
              }
            }
          }
        });
    
        delete this.productFormFields[combinedAttribToBeDeleted];
        delete this.productFormDiscountDetails[combinedAttribToBeDeleted];
        this.addCompaniesForm.setControl(combinedAttribToBeDeleted, new FormArray([]));
        this.previousDependentProductFormArray.splice(idx, 1);
      }
    }
  }
  if(hasChild){
    element.parentAttribId = attributeId;
    element.parentLookupId=lookupId;

    if(element.parentFormId!=null){
      this.previousDependentProductFormArray.push(element);
      this.setupProductFormsForDependentField(element);
    }
   }
}
setupProductFormsForDependentField(dependentProductFormField) {

  this._customAttrService.getFields(dependentProductFormField.parentFormId, this.selectedProject).subscribe(
    res => {
      this.productFormFields[dependentProductFormField.combinedAttributeName] = {
        fields: res['responseData'].filter(ele => ele.status == 1),
        formGroup: {},
        productFieldName: "",
        productFieldNameInput: "",
        productFormView: null,
        numericFields: 0,
        enteredNumericFields: [0],
        numericData: [{}],
        subTotal: 0,
        discountValue: Number(0),
        discountPercent: Number(0),
        total: 0,
        summaryFieldCombinedAttributeName: ""
      };
      this.productFormFields[dependentProductFormField.combinedAttributeName].fields.forEach((ele: CustomAttibute) => {
        if (ele.systemAttribute?.id == 48) {
          this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldName = ele.combinedAttributeName;
          this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldNameInput = ele.combinedAttributeName + "inputField";
          this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = ((ele.mandatoryFlag === 1) && (dependentProductFormField.mandatoryFlag == 1)) ? [null, Validators.required] : [null];
          this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName + "inputField"] = [{ value: null, disabled: true }];
        }
        else if (ele.mandatoryFlag === 1 &&  dependentProductFormField.mandatoryFlag == 1) {
          this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = [null, Validators.required];
        }
        else {
          this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup[ele.combinedAttributeName] = [];
        }

        if (ele.attributeType.id == 7) {
          this.productFormFields[dependentProductFormField.combinedAttributeName].numericFields += 1;
        }

        if (ele.attributeType.id == 4) {
          this.fileArray[dependentProductFormField.combinedAttributeName + ele.combinedAttributeName + 0] = {
            "attributeName": ele.combinedAttributeName,
            "files": [],
            "maxUpload": ele.maxImagesAllowed,
            "showUploadIcon": true,
            "uploaded": 0,
            "hasError": false
          };
        }
      })
      const control = <FormArray>this.addCompaniesForm.controls[dependentProductFormField.combinedAttributeName];
      control.push(this.fb.group(this.productFormFields[dependentProductFormField.combinedAttributeName].formGroup));
      this.productFormService.getSelectedProductForm(dependentProductFormField.parentFormId).subscribe(
        res => {
          this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName] = {
            isAllowDiscount: false,
            isDiscountValue: false,
            isDiscountPercent: false
          };

          const productDetailsArray = res['responseData']?.productIdList
            ?.map(productId => this.skuList.find(r => r.productId == productId))
            .filter(productDetails => productDetails !== undefined);
            this.productFormIdWithProductIdNameMap.set(dependentProductFormField.parentFormId, productDetailsArray || []);

          if (res['responseData']?.allowDiscounts == 1) {
            this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isAllowDiscount = true;
          }
          if (res['responseData']?.allowDiscounts == 1 && res['responseData']?.discountType?.length > 0) {
            const discType: any[] = res['responseData']['discountType'];
            if (discType.includes(0) || discType.includes('0')) {
              this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isDiscountValue = true;
            }
            if (discType.includes(1) || discType.includes('1')) {
              this.productFormDiscountDetails[dependentProductFormField.combinedAttributeName].isDiscountPercent = true;
            }
          }

          const summaryTotalFieldAttributeId = res['responseData'].summaryTotalField;
          const summaryTotalFieldObject = this.productFormFields[dependentProductFormField.combinedAttributeName].fields.find(x => x.id === summaryTotalFieldAttributeId);
          if (summaryTotalFieldObject !== undefined) {
            this.productFormFields[dependentProductFormField.combinedAttributeName].summaryFieldCombinedAttributeName = summaryTotalFieldObject.combinedAttributeName;
          }

          this.productFormFields[dependentProductFormField.combinedAttributeName].productFormView = res['responseData'].productFormView;
          if (res['responseData'].productFormView === 1) {
            const control = <FormArray>this.addCompaniesForm.controls[dependentProductFormField.combinedAttributeName];
            for (let i = 0; i < this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId).length; i++) {
              if (i > 0) {
                this.addTable(dependentProductFormField.combinedAttributeName);
              }
              this.updateItems(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productId, i, dependentProductFormField.combinedAttributeName,dependentProductFormField.parentFormId);
              control.at(i).get(this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldName).setValue(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productId);
              control.at(i).get(this.productFormFields[dependentProductFormField.combinedAttributeName].productFieldNameInput).setValue(this.productFormIdWithProductIdNameMap.get(dependentProductFormField.parentFormId)[i].productName);
            }
          }
        })
    })
}

receiveCompanyData(data) {
  this.title = data.name;
}

triggerClick(){}

ngOnDestroy() {
  if (this.intervalSubscription) {
    this.intervalSubscription.unsubscribe();
  }
}

isMandatory(mandatoryFlg:number){
  return mandatoryFlg === 1 ? true : false;
}

}
