import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ManageActivitiesService } from '../../../manage-activities/services/manage-activities.service';
import { ManageActivitiesModel } from '../../../manage-activities/models/manage-activities.model';
import { AlertDialogComponent } from '../../../shared/alert-dialog/alert-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { I } from '@angular/cdk/keycodes';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'kt-manage-activity-settings',
  templateUrl: './manage-activity-settings.component.html',
  styleUrls: ['./manage-activity-settings.component.scss']
})
export class ManageActivitySettingsComponent implements OnInit {
  @Input() public parentData;
  
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  customerLabel: string = "Customer";
  opportunityLabel : string = "Opportunity";
  errorMes: string = "";
  unsubscribe: Subject<any>;
  disabled:boolean;
  settingActivityForm: FormGroup;
  _onDestroy = new Subject<void>();
  activityModel: any;
  activityId: number;
  allowCompany: boolean;
  allowOpportunity: boolean;
  allowDialer:boolean;
  firstConditionOperator:string="AND";
  //allowGeofence: boolean;
  geofence: boolean;
  activityName: string;
  geofenceDistance: number | string;
  isActive: number;
  allowCompanyName: string;
  allowOpportunityName: string;
  allowDialerName="Dialer";
  captureLiveLocation: boolean;
  opportunityEnabled: false;
  advanceSetting = false;
  startDate: Date|String;
  endDate: Date|String;
  startTime: Date|String;
  anyTransactionPerformed: boolean;
  formSubmitted: boolean = false;
  //value1;
  entityValues = [{
    activityTypeId: null,
    entityId: null,
    entityFieldId: null,
    entityFieldValue: null,
    conditionOperation: null
  }]
  newAllentdata: any = [];
  arrIndex:any = 1;
  activeFor:any = null;
  roleList: [];
  userList: [];
  entityNameList: [];
  disableButton:boolean=false;
  selectedUser = [];
  selectedRoles = [];
  fieldList = [];
  fieldValuesList= [];
  entityFieldValue = [];
  fieldId:any = '';
  entityId:any = '';
  conditionalOp:any = 'AND';
  startHH:any = "";
  startMM:any = "";
  endHH:any ="";
  endMM:any ="";
  startAMPM:any ='AM';
  endAMPM:any ='AM';
  hours:any = [];
  Minutes:any = [];
  additionalFilterDisplay:boolean = false;
  selectedIds:any = [];
  allExtraFields:any = [];
  selectedFieldsIds:any = [];
  isrender: boolean = false;

  selectable: boolean = false;
  removable: boolean = false;
  selectedValues: any = [];
  selectedNewIds: any = [];
  todayDate:any;
  lastEndDate:any;
  invalidStartDate: boolean = false;
  invalidEndDate: boolean = false;
  invalidEntity: boolean = false;
  allSelected: any;
  isStartDatePresent:boolean=true;
  isEndDatePresent:boolean=true;

  constructor( private fb: FormBuilder,
     private activityService: ManageActivitiesService,
     public dialog: MatDialog,
     private SpinnerService: NgxSpinnerService,
     public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // this.getAllCustomers();
    for (let i = 0; i < 13; i++) { 
      i.toString().length == 1 ? this.hours.push(0+i.toString()) : this.hours.push(i.toString())
    }
    for (let j = 0; j < 61; j++) { 
      j.toString().length == 1 ? this.Minutes.push(0+j.toString()) : this.Minutes.push(j.toString())
    }
    this.getdynamicFieldNames();
    this.SpinnerService.show('editActivity');
    this.disabled=false;
    this.activityId = this.parentData;
    
    this.startDate= new Date();
    this.endDate= new Date();

    this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
    this.endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
    
    this.settingActivityForm = this.fb.group({
      activityName: ['', Validators.compose([Validators.required,Validators.maxLength(500) ])],
      allowForCompany: [],
      allowForOpportunity: [],
      allowForDialer:[],
      geofence: [],
      geofenceDistance:[],
     // geofenceDistance:['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      status: [],
      captureLiveLocation: [],
      mySwitch: [true],
      activeFor:[null],
      startDate:[],
      endDate:[],
      additionalFilter:[],
      allowUserToAssignOther:['', Validators.compose([Validators.required])],
      serialKey: ['', Validators.compose([ Validators.required])],
      selectedIdList: [],
      additionalEntityFilterList: [],
      startHours: [],
      startMin: [],
      endHours: [],
      endMin: [],
      selectUser: [],
      selectRole: [],
      extraFields: this.fb.array([]),
     });
     this.getSelectedActivity(this.activityId, this.selectedProject);

     this.getUserRoles();
     this.getUsers();
     this.getEntityName();

  }

  
  changed(value) {
    this.advanceSetting = value;
  }

  getdynamicFieldNames(){
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
      result['responseData'].forEach(
        row => {

          if(row.moduleId==2){
            this.allowCompanyName =row.pluralName;
            this.customerLabel = row.singularName;
          } 
          if(row.moduleId==3){
            this.allowOpportunityName =row.pluralName;
            this.opportunityEnabled = row.opportunityEnabled;
            this.opportunityLabel =row.singularName;
          } 
        }
      )
    },
    err=>{
    }
  )
  }

  getAllCustomers(){
    this.activityService.getCustomers().subscribe(
      data=>{
        console.log(data);
        
        
      }
    
    )
  }

  getSelectedActivity(activityId:any,selectedProject:number){
    this.activityService.getSelectedActivity(activityId,selectedProject).subscribe(
      res=>{
        this.activityModel = res['responseData'];
        this.settingActivityForm.controls['serialKey'].setValue(this.activityModel.serialKey);
        this.settingActivityForm.controls['mySwitch'].setValue(this.activityModel.advanceSetting == 1 ? true : false);
        this.activityId = this.activityModel.activityId;
        this.activityName = this.activityModel.activityName.trim();
        this.allowCompany = this.activityModel.allowForCompanies == 1 ? true : false;
        this.allowOpportunity = this.activityModel.allowForOpportunities ==1 ? true : false;
        this.allowDialer = this.activityModel.allowForDialer == 1? true:false;
        this.geofence = this.activityModel.allowGeofence == 1 ? true : false;
        this.geofenceDistance = this.activityModel.geofenceDistance;
        this.isActive = this.activityModel.active;
        this.anyTransactionPerformed = this.activityModel.anyTransactionPerformed;
        this.captureLiveLocation = this.activityModel.captureLiveLocation == 1 ? true : false;
               
        this.settingActivityForm.controls['status'].setValue(this.activityModel.active.toString());

        if(!this.allowCompany && !this.allowOpportunity){
          this.settingActivityForm.controls.geofence.disable();
        }        

        const date = new Date();
        var month = (date.getMonth()+1).toString();
        month.length == 1 ? month = '0'+(date.getMonth()+1).toString() : month = (date.getMonth()+1).toString();
        var day = (date.getDate()).toString();
        day.length == 1 ? day = '0'+(date.getDate()).toString() : day = (date.getDate()).toString();

        this.todayDate = date.getFullYear()+'-'+month+'-'+day;

        if(this.activityModel.startDate) {
          const startSplit = this.activityModel.startDate.split('-');
          if(+startSplit[0] > date.getFullYear() 
          || (+startSplit[0] == date.getFullYear() && +startSplit[1] > date.getMonth()+1)
          || (+startSplit[0] == date.getFullYear() && +startSplit[1] == date.getMonth()+1 && +startSplit[2] > date.getDate()) ) {
            this.lastEndDate = this.activityModel.startDate;
          } else {
            this.lastEndDate = this.todayDate;
          }
        }
        else{
          this.lastEndDate = this.todayDate;
        }

        this.settingActivityForm.controls['startDate'].setValue(this.activityModel.startDate);
        const currentDate : Date = new Date();
        const startDate: Date = new Date(this.settingActivityForm.controls['startDate'].value)
       // if(startDate< currentDate){
        if(this.activityModel.anyTransactionPerformed){
          this.disableButton=true;
          this.settingActivityForm.get('startDate').setErrors(null);
          this.settingActivityForm.get('startDate').disable();
          this.settingActivityForm.controls['startHours'].disable();
          this.settingActivityForm.controls['startMin'].disable();
          this.settingActivityForm.controls.serialKey.disable();
        }
        this.settingActivityForm.controls['endDate'].setValue(this.activityModel.endDate);
        if(this.activityModel.activeFor) {
          this.settingActivityForm.controls['activeFor'].setValue((this.activityModel.activeFor).toString());
          if((this.activityModel.activeFor).toString() == "1")
            this.activeFor = "roles";
          if((this.activityModel.activeFor).toString() == "2")
            this.activeFor = "users";
        }
          
        
        // this.activeFor == "2" ? this.selectedUser : this.selectedRoles;

        this.entityValues = this.activityModel.additionalEntityFilterList;
        if(this.activityModel.startDate && this.activityModel.startTime) {
          const startHr = this.activityModel.startTime.split(':')[0];
          const startmin = this.activityModel.startTime.split(':')[1].substring(0,2);
          this.startAMPM = this.activityModel.startTime?.split(':')[1].split(' ')[1]||"AM";
          this.settingActivityForm.controls['startHours'].setValue(startHr);
          this.settingActivityForm.controls['startMin'].setValue(startmin);

          this.startHH = startHr;
          this.startMM = startmin;
        }
        if(this.activityModel.endDate && this.activityModel.endTime){
          const endHr = this.activityModel.endTime?.split(':')[0] || "";
          const endmin = this.activityModel.endTime?.split(':')[1].substring(0,2) || "";
          this.endAMPM = this.activityModel.endTime?.split(':')[1].split(' ')[1] || "AM";
          this.settingActivityForm.controls['endHours'].setValue(endHr);
          this.settingActivityForm.controls['endMin'].setValue(endmin);
          this.endHH = endHr;
          this.endMM = endmin;
        }
        
        if(this.activityModel.selectedIdList && this.activityModel.selectedIdList.length) {
          for (let i = 0; i < this.activityModel.selectedIdList.length; i++) {
            this.selectedIds.push(this.activityModel.selectedIdList[i].toString());
          }
        }

        this.activeFor == "roles" 
        ? this.settingActivityForm.controls['selectRole'].setValue(this.activityModel.selectedIdList)
        : this.settingActivityForm.controls['selectUser'].setValue(this.activityModel.selectedIdList)

        this.activeFor == 'roles' ? this.selectedRoles = this.activityModel.selectedIdList : this.selectedUser = this.activityModel.selectedIdList;

        this.settingActivityForm.controls['additionalFilter'].setValue(this.activityModel.additionalFilter == 1 ? true : false );
        this.additionalFilterDisplay = this.activityModel.additionalFilter == 1 ? true : false;
        this.settingActivityForm.controls['allowUserToAssignOther'].setValue(this.activityModel.allowUserToAssignOther.toString());
        this.settingActivityForm.controls['mySwitch'].setValue(this.activityModel.advanceSetting == 1 ? true : false);
        this.advanceSetting = this.activityModel.advanceSetting == 1 ? true : false

        this.extraFields.clear();
        this.activityModel.additionalEntityFilterList.map((item, i) => {
          if (item.entityId == 2) {
            this.getEntityField(2, i, item.entityFieldId)
          }
          if (item.entityId == 3) {
            this.getEntityField(3, i, item.entityFieldId)
          }
         
          // setTimeout(() => {
          //  this.isrender = true;
          //   document.getElementById("entityIds").click();
          //   console.log(document.getElementById("entityIds"));
            
          // }, 15000);
          const updatedItem = item;
          updatedItem.entityId = item.entityId.toString();
          updatedItem.entityFieldId = +item.entityFieldId;
          const newarr = updatedItem.entityFieldValue.split(',')
          const entfIds = [];
          const entfIds2 = [];
          newarr.map(ent => {
            entfIds.push(+ent)
            entfIds2.push({value: +ent})
          })
          this.selectedFieldsIds[i] = entfIds;
          this.extraFields.push(this.fb.group(updatedItem))
        })

        if(!this.allowCompany && !this.allowOpportunity){
          this.settingActivityForm.controls.geofence.disable();
        }        

      },
      err=>{
        console.log(err);
      }
    )
    this.SpinnerService.hide('editActivity');
  }

  strtDate(value) {
    this.isStartDatePresent=true;
    var month = (value.getMonth()+1).toString();
      month.length == 1 ? month = '0'+(value.getMonth()+1).toString() : month = (value.getMonth()+1).toString();
    var day = (value.getDate()).toString();
      day.length == 1 ? day = '0'+(value.getDate()).toString() : day = (value.getDate()).toString();

    this.lastEndDate = value.getFullYear()+'-'+month+'-'+day;
  }
  endDateInput(){
    this.isEndDatePresent=true;
  }

  get extraFields(): FormArray {
    return this.settingActivityForm.get('extraFields') as FormArray;
  }

  remove(item:any, ind) {
    const index = this.selectedValues[ind].indexOf(item);
    if(index >= 0) {
      this.selectedValues[ind].splice(index, 1);
    }
  }

  selected (event, index) {
    if (!this.selectedValues[index].length){
      this.selectedValues[index] = [];
    }
      if(!this.selectedValues[index].some(e => e.value == event.option.value)) {
        this.selectedValues[index].push({name: event.option.viewValue, value: event.option.value});
      }
  }

  changeConditionOperator(ConditionOperator:string){
    let fArray=this.extraFields.value;
    this.extraFields.clear();
    fArray.forEach(ele=>{
     this.extraFields.push(this.fb.group({
       activityTypeId: ele.activityTypeId,
       conditionOperation: [ConditionOperator],
       entityFieldId: [ele.entityFieldId],
       entityFieldValue: [ele.entityFieldValue],
       entityId: [ele.entityId]
     })) 
    })
     this.firstConditionOperator=ConditionOperator;
   }

  addExtraField() {
    this.firstConditionOperator=this.extraFields.value[0]?this.extraFields.value[0].conditionOperation:this.firstConditionOperator;
    this.extraFields.push(this.fb.group({
      activityTypeId: this.activityId,
      conditionOperation: [this.firstConditionOperator],
      entityFieldId: [null],
      entityFieldValue: [""],
      entityId: [null]
    }))    
  }

  removeExtraField (index) {
    if(this.extraFields.controls.length > 1){
      this.extraFields.removeAt(index);
    }
  }

  aditionalChange(e) {
    this.additionalFilterDisplay = e.checked;

    if(!e.checked) {
      this.extraFields.controls = [];
    }
    else {
      this.addExtraField();
    }

    for (let i = 0; i < this.entityValues.length; i++) {
      if(!e.checked) {
        this.selectedValues[i] = [];
      }
      if (this.entityValues[i].conditionOperation == 'AND') {
        var doCAND1 = document.getElementById('conditionalAnd'+i);
        if(doCAND1)
          doCAND1.classList.add('selected');

        var doCOR1 = document.getElementById('conditionalOr'+i);
        if(doCOR1)
          doCOR1.classList.remove('selected');
      }
      if (this.entityValues[i].conditionOperation == 'OR') {
        var doCOR2 = document.getElementById('conditionalOr'+i);
        if(doCOR2)
          doCOR2.classList.add('selected');

        var doCAND2 = document.getElementById('conditionalAnd'+i);
        if(doCAND2)
          doCAND2.classList.remove('selected');
      }
    }
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  Save(){ 
    const controls = this.settingActivityForm.controls;

    if((controls.mySwitch.value ==true) && ((!this.isStartDatePresent) || (!this.isEndDatePresent))){return;}
    if(this.startMM.length>0 && this.startHH.length==0){return}
    if(this.startHH.length>0 && this.startMM.length==0){return}
    if(this.endMM.length>0 && this.endHH.length==0){return}
    if(this.endHH.length>0 && this.endMM.length==0){return}

    const currentDate = new Date();
    if(  controls.endDate.value>controls.startDate.value ||
       ((+(formatDate(controls.endDate.value).split('-')[0])  >= +currentDate.getFullYear())
    && (+(formatDate(controls.endDate.value).split('-')[1]) >= +(currentDate.getMonth()+1))
    && (+(formatDate(controls.endDate.value).split('-')[2]) >= +(currentDate.getDate()))
    )|| controls.endDate.value==null){  
     this.invalidEndDate = false;
    } else if(controls.endDate.value<controls.startDate.value) {
      this.invalidEndDate = true
    }

    

    this.formSubmitted = true;
    this.disabled=true;

    if(controls.geofence.value == true){
      this.settingActivityForm.controls.geofenceDistance.setValidators([Validators.required]);
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
     } else {
      this.settingActivityForm.controls.geofenceDistance.clearValidators();
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
    }

    if (this.settingActivityForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    this.disabled=false;

    // for (let i = 0; i < this.entityValues.length; i++) {
    //   console.log(typeof(this.entityValues[i].entityFieldValue));
      
    //         if(typeof(this.entityValues[i].entityFieldValue) == 'object') {
    //           this.entityValues[i].entityFieldValue = null
    //         }
    // }

    const newar = [];

    this.selectedValues.map(e => {
      newar.push(e.value)
    })


    for (let i = 0; i < this.selectedValues.length; i++) {
      this.selectedValues[i].map(e => {
        if (!newar[i]){
          newar[i] = [];
        }
        newar[i].push(e.value)
      })
    }

    this.newAllentdata = [];

    for (let i = 0; i < controls.extraFields.value.length; i++) {
     this.newAllentdata.push({
      activityTypeId: controls.extraFields.value[i].activityTypeId,
      entityId: controls.extraFields.value[i].entityId,
      entityFieldId: controls.extraFields.value[i].entityFieldId,
      entityFieldValue:  !newar[0] ? '' : newar[i].join(),
      conditionOperation: controls.extraFields.value[i].conditionOperation
     })
    }

    if (this.startMM.toString().length == 1) {
      this.startMM = 0+this.startMM.toString()
    }
    if (this.endMM.toString().length == 1) {
      this.endMM = 0+this.endMM.toString()
    }

    if(!this.newAllentdata.length && controls.additionalFilter.value) {
      this.invalidEntity = true;
      return;
    }
    if(controls.additionalFilter.value && this.newAllentdata.length && this.newAllentdata[0] && this.newAllentdata[0].entityId == null) {
      this.invalidEntity = true;
      return;
    } else {
      this.invalidEntity = false;
    }

    const addSettingsData = {
      "activityId": this.activityId,
      "activityName":controls.activityName.value.trim(),
      "allowForCompanies":controls.allowForCompany.value == true ? 1 :0,
      "projectId": this.selectedProject,
      "allowForOpportunities":controls.allowForOpportunity.value == true ? 1:0,
      "allowForDialer":controls.allowForDialer.value == true ? 1:0,
      "allowGeofence": controls.geofence.value == true ? 1: 0,
      "geofenceDistance": controls.geofenceDistance.value,
      "active": controls.status.value,
      "captureLiveLocation": controls.captureLiveLocation.value == true ? 1 : 0,
      "advanceSetting": controls.mySwitch.value == true ? 1 : 0,
      "activeFor":controls.activeFor.value,
      "startDate":formatDate(controls.startDate.value),
      "endDate":formatDate(controls.endDate.value),
      "additionalFilter":controls.additionalFilter.value == true ? 1 : 0,
      "allowUserToAssignOther":controls.allowUserToAssignOther.value,

      "endTime":controls.endDate.value?this.formatTime(this.endHH,this.endMM,this.endAMPM):null,
      "startTime":controls.startDate.value?this.formatTime(this.startHH,this.startMM,this.startAMPM):null,
      "selectedIdList":  this.activeFor == 'users' ? this.selectedUser : this.selectedRoles,
      "additionalEntityFilterList": this.newAllentdata,
      "serialKey":controls.serialKey.value.trim()
  };

  if (addSettingsData.advanceSetting == 1 && !addSettingsData.activeFor) { return; }
  if (addSettingsData.advanceSetting == 1 && !addSettingsData.selectedIdList.length) { return; }
  if (this.startHH < 0) { return; }
  if (this.startHH > 12) { return; }
  if (this.startMM < 0) { return; }
  if (this.startMM > 60) { return; }
  if (this.endHH < 0) { return; }
  if (this.endHH > 12) { return; }
  if (this.endMM < 0) { return; }
  if (this.endMM > 60) { return; }

    this.SpinnerService.show('editActivity');
    this.activityService.saveActivity(addSettingsData).subscribe(
      data => {
       // console.log(data);
      //  this.settingActivityForm.reset();
      //  this.disabled=false;
     if(data['statusCode']>=400){
       this.handleErrorForEditActivity(data['statusCode']);
       return;
      }
        this.SpinnerService.hide('editActivity');
        this.snackBar.open('Activity Settings Updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
      },
      error => {
        this.SpinnerService.hide('editActivity');
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.snackBar.open(this.errorMes, '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }); 
  

      function formatDate(date) {
        if(date == null || date == "")
          return "";
        else {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
      
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;    
      
          return [year, month, day].join('-');
        }
      }
      
  }
  
  formatTime(hours:String,minutes:String,amPm:String){
    if(hours.length==0 && minutes.length==0){
      return null;
    }
    return hours + ':' + minutes + ' ' + amPm;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.settingActivityForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  conditionalOper(value, index) {    
    // this.conditionalOp = value;
    if(value == 'AND') {
      this.entityValues[index].conditionOperation = value;
      var doCAND = document.getElementById('conditionalAnd'+index);
      doCAND.classList.add('selected');
      var doCOR = document.getElementById('conditionalOr'+index);
      doCOR.classList.remove('selected');
    }
    if(value == 'OR') {
      this.entityValues[index].conditionOperation = value;
      var doCOR = document.getElementById('conditionalOr'+index);
      doCOR.classList.add('selected');
      var doCAND = document.getElementById('conditionalAnd'+index);
      doCAND.classList.remove('selected');
    }
  }
 
  timeChange(value, item) {
    
    switch (item) {
      case 'startHH':
        if(!(this.settingActivityForm.controls.startDate.value)){
          this.isStartDatePresent=false;
        }
        else{
          this.isStartDatePresent=true;
        }
        this.startHH = value;
        break;
      case 'startMM':
        if(!(this.settingActivityForm.controls.startDate.value)){
          this.isStartDatePresent=false;
        }
        else{
          this.isStartDatePresent=true;
        }
        this.startMM = value;
        break;
      case 'endHH':
        if(!this.settingActivityForm.controls.endDate.value){
          this.isEndDatePresent=false;
        }else if( value.trim()==""|| this.settingActivityForm.controls.endDate.value){
          this.isEndDatePresent=true;
        }
        this.endHH = value;
        break;
      case 'endMM':
        if(!this.settingActivityForm.controls.endDate.value){
          this.isEndDatePresent=false;
        }else{
          this.isEndDatePresent=true;
        }
        this.endMM = value;
        break;
      case 'startAMPM':
        this.startAMPM = value;
        break;
      case 'endAMPM':
        this.endAMPM = value;
        break;
      default:
        break;
    }
    if(this.startHH =="" && this.startMM==""){
      this.isStartDatePresent=true;
    }
    if( this.endHH =="" && this.endMM==""){
      this.isEndDatePresent=true;
    }
   
  }

  openDialog() {
    const controls = this.settingActivityForm.controls;
    const checkbox = controls.allowForOpportunity.value;
    
    if(checkbox) {
      const initialNotice = `Please make sure that field ‘Select Opportunity’ is Active in field details.`;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '600px',
        data: { title: 'Alert', body: initialNotice }
      });
    }
    this.enableOrDisableGeofence();
  }

  addGeofenceField(value){
    this.geofenceDistance="";
    if(value){
      this.settingActivityForm.controls.geofenceDistance.setValidators([Validators.required]);
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
     } else {
      this.settingActivityForm.controls.geofenceDistance.clearValidators();
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
    }
  }

  ResetForm(){
    this.activityId=this.parentData;
    this.getSelectedActivity(this.activityId, this.selectedProject);
  }


  enableOrDisableGeofence(){     
    const controls = this.settingActivityForm.controls;
    if(controls.allowForCompany.value || controls.allowForOpportunity.value){
      controls.geofence.enable();    
    }else{
      controls.geofence.disable();     
      controls.geofence.reset();
      this.geofence = false;
      this.addGeofenceField(this.geofence);
    }     
  }

  onActiveFor(value) {
    this.activeFor = value == 2 ? 'users' : 'roles';

    this.additionalFilterDisplay = false;
    this.extraFields.controls = [];
    this.settingActivityForm.controls['additionalFilter'].setValue(false);
    for (let i = 0; i < this.entityValues.length; i++) {
      this.selectedValues[i] = [];
    }
  }

  onUserChange(user) {
    this.selectedUser = user;
    console.log(this.formSubmitted && (!this.selectedUser || !this.selectedUser.length));
    
  }

  onRoleChange(role) {
    this.selectedRoles = role;    
  }

  getUserRoles(){
    this.activityService.getActiveRoles(this.selectedProject).subscribe(
      (res:any)=>{
       if(res) {
        this.roleList = res.responseData;
        if((this.roleList.length == this.activityModel?.selectedIdList?.length) && (this.activityModel.activeFor).toString() == "1"){
          this.allSelected = true;
        }
       }
      },
      err=>{
        console.log(err);
      }
    )
  }
  getUsers(){
    this.activityService.getActiveUsers(this.selectedProject).subscribe(
      (res:any)=>{
       if(res) {
        this.userList = res.responseData;
        if((this.userList.length == this.activityModel?.selectedIdList?.length) && (this.activityModel.activeFor).toString() == "2"){
          this.allSelected = true;
        }
        
       }
      },
      err=>{
        console.log(err);
      }
    )
  }

  getEntityName(){    
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      (res:any)=>{
       this.entityNameList = res.responseData;
      },
      err=>{
        console.log(err);
      }
    )
  }

  onEntityChange(value, index) {
    this.getEntityField(value, index)
    this.entityValues[index].entityId = value;
    this.entityId = value;
    this.selectedFieldsIds[index] = [];
  }

  getEntityField(moduleId, index, item?){
    this.activityService.getActiveField(moduleId,this.selectedProject).subscribe(
      (res:any)=>{
       this.fieldList[index]= res.responseData
       this.fieldList[index]=this.fieldList[index].filter(res=>(res.attributeType.id==1 || res.attributeType.id==2)&& res.isActive==1)
       this.fieldList[index].map((e, i) => {
         if(e.id == item) { 
           for (let j = 0; j < this.fieldList[index][i].lookupValues.length; j++) {
              for (let k = 0; k < this.selectedFieldsIds[index].length; k++) {
                if (this.fieldList[index][i].lookupValues[j].id == this.selectedFieldsIds[index][k]) {
                  this.selectedValues[index] = [];
                  this.selectedValues[index].push({
                    name: this.fieldList[index][i].lookupValues[j].lookupValue,
                    value: this.fieldList[index][i].lookupValues[j].id
                  })
                }
              }
           }
           
          this.fieldValuesList[index] = this.fieldList[index][i].lookupValues;
          setTimeout(() => {
            this.isrender = true;
             document.getElementById("entityIds").click();             
           }, 3000);
         }
       })
      },
      err=>{
        console.log(err);
      }
    )
  }

  onEntityFieldChange(value, index) {
    this.fieldValuesList[index] = this.fieldList[index][value.source._keyManager._activeItemIndex].lookupValues;
    this.selectedValues[index] = []
  }

  onEntityFieldValue(value, index) {
    
  }
  triggerClick() {}
  
toggleAllSelection(matSelect: MatSelect) {
  if (this.allSelected) {
    matSelect.options.forEach((item: MatOption) => {(item.value!==undefined && item.value!==null) ? item.select() : null});
  } else {
    matSelect.options.forEach((item: MatOption) => item.deselect());
  }
}
 optionClick(matSelect: MatSelect) {
  let newStatus = true;
    matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  handleErrorForEditActivity(statusCode: number) {
    let message: string;
    if (statusCode == 412) {
      message = 'Activity Name already exists.';
    }
    else if(statusCode == 409){
      message = 'Serial No. Key already exist.';
    }
    else {
      message = 'Unable to save, please try again.';
    }
    this.SpinnerService.hide('editActivity');
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
  }
}
