import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors,FormArray,ValidatorFn } from '@angular/forms';
import { CompaniesService } from '../../../company-management/services/companies.service';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../services/address.service';
import { countriesModel } from '../../../company-management/models/countries.model';
import { stateModel } from '../../../company-management/models/state.model';
import { cityModel } from '../../../company-management/models/city.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { ContactsService } from '../../../contact-management/services/contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from '../../services/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { OpportunitiesService } from '../../../opportunities/services/opportunities.service';
import { ProductService  } from '../../../product-management/services/product.service';
import { ManageActivitiesService } from '../../../manage-activities/services/manage-activities.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import { ProductFormService } from '../../../product-form/service/product-form.service';
import { environment } from 'src/environments/environment';
import { ImageViewerComponent } from '../../image-viewer/image-viewer.component';

@Component({
  selector: 'kt-edit-activity-report',
  templateUrl: './edit-activity-report.component.html',
  styleUrls: ['./edit-activity-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditActivityReportComponent implements OnInit {
  allSelected=false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];

  companyId:number|string;
  contactId:number;
  opportunityId:number;
  flag:String;
  showModal:boolean=false
  skuItemsArray=[];
  skuList:any;
  totalSum: number = 0;
  showImageArray=[];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  imgURL:any;
  images=[];
  imagesnameArray=[];
  systemAttribute11Array=[];
  systemAttribute6Array=[];
  newOpenDiv=[];
  openDivs=[];
  moduleType:Number;
  moduleName:String;
  activityType:String;
  customAttributeValues=[];
  customAttributesTestValues:[];
  isSubmitted:boolean=true;
  errorMes:string="";
  disabled:Boolean=false;
  maxDate= new Date();
  minDate= new Date();
  customAttributesList=[];
  customAttributesList2=[];
  parentPropertyId:Number;
  countriesList:countriesModel[];
  stateList:stateModel[];
  cityList:cityModel[];
  image:any;
  addCompaniesForm :FormGroup;
  editId:any;
  editOppVal:any;
  singularName: string| String;
  systemAttributeOwnerArray=[];
  showUploadImageIcon: boolean = true;
  imageUploadedCounter: number = 0;
  maxImgUploadAllowed: number = 0;
  imageUploadMandatoryFlag: number = 0;
  imageNameIndexMap = new Map();
  errorFlag:boolean;
  imageNoUploaded: boolean = false;
  isSubmitShow:any;
  resCustomAttr:any;
  CompanyName: string;
  OpportunityName: string;
  ContactName: string;
  serialNo: string;
  private productFormFieldArray=[];
  private productFormFields={};
  private numericPattern="^(0|[1-9][0-9]*)$";
  private decimalPattern="^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";
  private fileArray = {};
  baseUrl = environment.imageUrl;
  private productFormDiscountDetails={};
  spinnerCount: number = 0;
  public singleDropdownFilter: FormControl = new FormControl();
  public multiDropdownFilter: FormControl = new FormControl();
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();
  private documentIconUrlMap = new Map([
    ["pdf", "./assets/media/svg/icons/General/PDF-File.svg"],
    ["docx", "./assets/media/svg/icons/General/file-alt-solid.svg"],
    ["xlsx", "./assets/media/svg/icons/General/file-excel-solid.svg"],
    ["pptx", "./assets/media/svg/icons/General/file-powerpoint-solid.svg"]
  ]);

  constructor(private fb: FormBuilder,
    private _cs:CompaniesService,
    public activeModal: NgbActiveModal,
    private _as:AddressService,
    private _us:UploadImageService,
    private _customAttrService:CustomAttributesService,
    private _contact_service:ContactsService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    public dialog: MatDialog,
    public activityService: ManageActivitiesService,
    private modalService: NgbModal,
    private productFormService: ProductFormService) { }

  ngOnInit(): void {
    this.getdynamicFieldNames();
    this.imageNameIndexMap.set("imageFileArray",[]);
    this.SpinnerService.show();
    
    this.getModuleName();

     //form builder 
     this.initaddCompanyForm();

     this.getAllCountries();
 
     this.getFields();
      this.errorFlag=false;
     if(this.isSubmitShow != false){  
      this.isSubmitShow="true";
     }

  }

  getdynamicFieldNames(){
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
      result['responseData'].forEach(
        row => {

          if(row.moduleId==2){
            this.CompanyName =row.singularName;
          } 
          if(row.moduleId==3){
            this.OpportunityName =row.singularName;
          } 
          if(row.moduleId==1){
            this.ContactName =row.singularName;
          } 
        }
      )
        if(this.activityType == 'Opportunity Created'){
          this.singularName='View '+this.OpportunityName;
        }else if(this.activityType == 'Customer Created'){
          this.singularName='View '+this.CompanyName;
        }else if(this.activityType == 'Contact Created'){
          this.singularName='View '+this.ContactName;
        }else{
          this.singularName=this.activityType;
        }
    },
    err=>{
    }
  )
  }

  getModuleName(){
    switch (this.moduleType){
      case 1 :{
        this.moduleName="Contact";
        break;
      }
      case 2 :{
        this.moduleName="Company";
        break;
      }
      case 3 :{
        this.moduleName="Opportunity";
        break;
      }
      default:{
        this.moduleName="";
        break;
      }
    }
  }

  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  
      console.log(res);
      var id='div_'+res.id;
      var division1=<HTMLElement>document.getElementById(id);
      console.log(division1);
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues[0].id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }


  getAllOwner(){
    console.log('owner filed loads');
    this._customAttrService.getOwner(this.selectedProject,Number(this.moduleType)).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.fullName;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }

  getAllOpportunities(){
        if(this.companyId!=null && this.companyId!='undefined'){
    // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
     this._os.getAllOpportunityDetails2(this.companyId).subscribe(
       data=>{
        
         data['responseData'].forEach(res=>{
           
             res.lookupValue=res.opportunityName;
             res.id=res.opportunityId;
           
         })
         var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
       
         if(removeIndex >-1){
           
           this.customAttributesList[removeIndex].lookupValues=data['responseData'];
           this.customAttributesList[removeIndex].hasChildAttribute=true;
   
         }
 
         if(this.opportunityId && this.flag=="opportunity"){
           console.log('into opp');
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 
 
         }
 
       }
     ) 
    }
   }
 
   getAllOpportunitiesDependentInputs(id){
     //api to prefill opportunity inputs
     this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
       console.log('query params 2');
       console.log(this.systemAttributeopportunityListArray);
 
       var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
    
       res['responseData'][0].customAttributeValues.forEach(res1=>{
         console.log(res1);
         if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
           
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(res1.value[0].id);   
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
         }
         this.systemAttributeopportunityListArray.forEach(res=>{
           
           
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.systemAttribute.id==26){
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable();
               }else{                
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable(); 
               }          
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              
               if(res.systemAttribute.id==26){
                 console.log(res);
                 if(this.opportunityId && this.flag=="opportunity"){
 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                   this.addCompaniesForm.controls[res.combinedAttributeName].disable();
                 }
                 
               }else{                
                 if(res.attributeType.id==2){
                   var arraynew=[];
                   res1.value.forEach(d=>{
                     arraynew.push(d.id);
                   })
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                   this.addCompaniesForm.controls[res.combinedAttributeName].disable();
                 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name); 
                   this.addCompaniesForm.controls[res.combinedAttributeName].disable(); 
                 
                 }  
               }                
           
             }
           }         
         })
       })
     })
   }
 
   getAllCompanyDependentInputs(id){
     //api to prefill opportunity inputs
     this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{
 
       res['responseData'][0].customAttributeValues.forEach(res1=>{
       
         this.systemAttributecompanyListArray.forEach(res=>{
     
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable();
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable();
               
               }
                      
               
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable();
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
                 this.addCompaniesForm.controls[res.combinedAttributeName].disable();
               
               }
           
             }
           }
          
          
         })
       })
     })
   }
 
   getAllEstimates(){
    this._customAttrService.getActivityLookup(this.moduleType,this.selectedProject,this.companyId, this.opportunityId).subscribe(
      data=>{
        console.log('estimates');
        console.log(data['responseData']);

        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId; 
             
        })

        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }

   getAllProducts(){
     this._ps.getProductLookup(this.selectedProject).subscribe(
       data=>{
         this.skuList= data['responseData']; 
         this.setupProductForms();    
       }
     ) 
   }
   setupProductForms(){
    this.productFormFieldArray.forEach((element)=>{
      this._customAttrService.getFields(element.parentFormId, this.selectedProject).subscribe(
        res=>{
          this.productFormFields[element.combinedAttributeName]={fields:res['responseData'].filter(ele => ele.status==1),
          formGroup: {},
          productFieldName:"",
          productFieldNameInput:"",
          productFormView: null,
        numericFields:0,
        enteredNumericFields:[0],
        numericData:[{}],
        subTotal: Number(0),
        discountValue: Number(0),
        discountPercent: Number(0),
        total: Number(0)
        };
        let productFormData = this.editOppVal.customAttributeValues.find(res => res.attributeId === element.id)?.productFormData;
        if(productFormData==undefined){
          this.createFormGroupForProductForm(element,null,0);
        }
        else{
          productFormData.forEach((pData,pIndex)=>{
            this.createFormGroupForProductForm(element,pData,pIndex);
          })
        }
      //setupProductFormSummaryDiscountData if discount at Summary Level is allowed
      this.setupProductFormSummaryDiscountData(element);
      })
    })
  }
  showImageModalOnly(imgURL){
    const ref = this.modalService.open(ImageViewerComponent,
      {
        centered: false,
        size : 'xl'
      }
    );
    ref.componentInstance.dataString = imgURL.replace(this.baseUrl, '');
  }

  showRefDocModal(imgURL){
    if(imgURL){
      let fileType = imgURL.split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      if(icon){
        window.open(this.baseUrl+imgURL);
      }
      else{
        this.showImageModalOnly(imgURL);
      }
    }
  }
  private createFormGroupForProductForm(element: any,pData:any,pDataIndex): void {
      this.productFormFields[element.combinedAttributeName].fields.forEach((ele: CustomAttibute) => {
        const fieldData=pData?(pData.value.find(d => ele.id === d.id)?.name) :null;
        if (ele.systemAttribute?.id == 48) {
          this.productFormFields[element.combinedAttributeName].productFieldName = ele.combinedAttributeName;
          this.productFormFields[element.combinedAttributeName].productFieldNameInput = ele.combinedAttributeName + "inputField";
          this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [{ value: fieldData ? Number(fieldData) : null, disabled: true }];
          this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName + "inputField"] = [{ value:  fieldData ? Number(fieldData) : null, disabled: true }];
        } else {
          // for image/Document/Video/reference document
          if([4,15,16,17].includes(ele.attributeType.id)){
            this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [{ value:null, disabled: true }];
            this.fileArray[element.combinedAttributeName + ele.combinedAttributeName + pDataIndex] = {
              "attributeName": ele.combinedAttributeName,
              "files": [],
              "maxUpload": ele.maxImagesAllowed,
              "showUploadIcon": true,
              "uploaded": 0,
              "hasError": false
            };
            fieldData?.split(',').forEach(img=>{
              this.fileArray[element.combinedAttributeName + ele.combinedAttributeName + pDataIndex].files.push(img);
            })
          }
          //for singleSelect or numeric type field
          else if(ele.attributeType.id==1||ele.attributeType.id==7){
            this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [{ value:  fieldData ? Number(fieldData):null, disabled: true }];
          }
          //for multiSelectFields
          else if(ele.attributeType.id==2){
            this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [{ value: fieldData?JSON.parse(fieldData):null, disabled: true }];
          }
          else{
            this.productFormFields[element.combinedAttributeName].formGroup[ele.combinedAttributeName] = [{ value:  fieldData ? fieldData:null, disabled: true }];
          }
        }
        // for calculated field type
        if(ele.attributeType.id==14){
          this.productFormFields[element.combinedAttributeName].total+=fieldData ? Number(fieldData) :0;
        }
      })
      const control = <FormArray>this.addCompaniesForm.controls[element.combinedAttributeName];
      control.push(this.fb.group(this.productFormFields[element.combinedAttributeName].formGroup));
  }
 
  setupProductFormSummaryDiscountData(element){
    const productFormSummaryDiscountData=this.editOppVal.customAttributeValues.find(res => res.attributeId === element.id)?.productFormSummaryDiscountData;
    this.productFormService.getSelectedProductForm(element.parentFormId).subscribe(
      res=>{
        this.productFormDiscountDetails[element.combinedAttributeName]={
        isAllowDiscount: false,
        isDiscountValue:false,
        isDiscountPercent:false
      };

      if(res['responseData']?.allowDiscounts == 1 ){
        this.productFormDiscountDetails[element.combinedAttributeName].isAllowDiscount = true;
        this.productFormFields[element.combinedAttributeName].subTotal=this.productFormFields[element.combinedAttributeName].total
        this.productFormFields[element.combinedAttributeName].total=this.productFormFields[element.combinedAttributeName].total-productFormSummaryDiscountData?.value;
      }
      if(res['responseData']?.allowDiscounts == 1 && res['responseData']?.discountType?.length > 0){
        const discType: any[] = res['responseData']['discountType'];
        if(discType.includes(0) || discType.includes('0')){
          this.productFormDiscountDetails[element.combinedAttributeName].isDiscountValue = true;
          this.productFormFields[element.combinedAttributeName].discountValue=productFormSummaryDiscountData?.value;
        }
        if(discType.includes(1) || discType.includes('1')){
          this.productFormDiscountDetails[element.combinedAttributeName].isDiscountPercent = true;
          this.productFormFields[element.combinedAttributeName].discountPercent=productFormSummaryDiscountData?.percent;
        }
      }
    })
  }
  getCompanyDetails(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{    
        this.editOppVal=data['responseData'];
  
      }
    )
  }
  
  initaddCompanyForm(){

    this.addCompaniesForm = new FormGroup({   
    });
  }

  
  getAllCountries(){
    this._as.getAllCountries(this.selectedProject).subscribe(
		(result)=>{ 
       this.countriesList=result['responseData']; 
		},
		error => {
			console.log(error)
    })
  }

  changeCountry(countryId){
    //get states
    var id=countryId.split('@',2);
    this.getAllStates(id[1]);
  }

  getSKU() {
    
    return this.fb.group({
     sku: ['', Validators.compose([ Validators.required])],
     quantity: ['', Validators.compose([ Validators.required])],
     listPrice: [''],
     sellingPrice: ['', Validators.compose([ Validators.required])],
     discount: [''],
     tax: ['', Validators.compose([ Validators.required])],
     total: ['']
    });
  }

  getControls(combinedAttributeName) {
    return (this.addCompaniesForm.get(combinedAttributeName) as FormArray)?.controls;
  }

  private updateItems(skuItems: any) {
    
    const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
    
    this.totalSum = 0;
    for (let i in skuItems) {
      if(skuItems[i].sku!==''){
        for(let sku of this.skuList){
          if(sku.productId == skuItems[i].sku){
            control.at(+i).get('listPrice').setValue(sku.price, {onlySelf: true, emitEvent: false});
          }
       }
      }

      if((skuItems[i].listPrice!=='' && skuItems[i].sellingPrice!=='') &&
          (skuItems[i].listPrice!==null && skuItems[i].sellingPrice!==null)){

          var discount=Number(skuItems[i].listPrice-skuItems[i].sellingPrice);
          discount=((discount/skuItems[i].listPrice)*100);
          discount=Number(discount.toFixed(2));
          control.at(+i).get('discount').setValue(discount, {onlySelf: true, emitEvent: false});

      }else{
        control.at(+i).get('discount').setValue('', {onlySelf: true, emitEvent: false});
      }

    
    if((skuItems[i].quantity!=='' && skuItems[i].sellingPrice!=='' && skuItems[i].tax!=='')
    && (skuItems[i].quantity!== null && skuItems[i].sellingPrice!==null && skuItems[i].tax!==null)){

      let total = skuItems[i].quantity*(Number(skuItems[i].sellingPrice)+Number((skuItems[i].sellingPrice * (skuItems[i].tax/100))));
      total=Number(total.toFixed(2));
      control.at(+i).get('total').setValue(total, {onlySelf: true, emitEvent: false});
       this.totalSum += total;

      }else{
        let total=0;
        control.at(+i).get('total').setValue('', {onlySelf: true, emitEvent: false});
       this.totalSum += total;
      }

    }
  }


  getAllStates(countryId){
    this._as.getStatesForCountry(countryId).subscribe(
      (result)=>{  
        this.stateList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  changeState(stateId){
    //get cities
    var id=stateId.split('@',2);
    console.log(id);
    this.getAllCities(id[1]);
  }

  getAllCities(stateId){
    this._as.getCitiesForState(stateId).subscribe(
      (result)=>{
        this.cityList=result['responseData']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  getAllCompanies(){
    this._cs.getCompany(this.selectedProject).subscribe(
      data=>{
        console.log(data);
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.companyName;
            res.id=res.companyId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    )
 
  }

  getAllContacts(){
    this._contact_service.getAllContactDetails(this.selectedProject).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.contactName;
            res.id=res.contactId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute6Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }
  getFields(){
    console.log(this.editId); 
    console.log(this.moduleType);
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{       
        this.editOppVal=data['responseData'][0];
        this.addCompaniesForm = new FormGroup({
          serialNo: new FormControl({ value: this.editOppVal.serialNo, disabled: true })
        });
         this._customAttrService.getFields(this.moduleType, this.selectedProject).subscribe(
          res=>{
            res['responseData'] = res['responseData'].filter(ele => {
              if(ele.status == 1){
                if(ele.attributeType.id==4 ){
                  this.maxImgUploadAllowed = ele.maxImagesAllowed;
                  if(this.maxImgUploadAllowed === null){
                    this.maxImgUploadAllowed = 0;
                  }
                  this.imageUploadMandatoryFlag = ele.maxImagesAllowed;;
                  // if( ele.allowBrowsing==1 ){
                  //   return 1;
                  // }else{
                  //   return 0;
                  // }
                  return 1;
                }else{
                  return 1;
                }
              }
            })
                    
            this.SpinnerService.hide();
            this.showModal=true
            console.log("my get fields api result");
            console.log(res['responseData']);
            res['responseData'].forEach(element=>{
              if(element.systemAttribute==null)
              {
                element.systemAttribute={};
              }
              if(element.attributeType.id==1 && element.systemAttribute['id']==25){
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeName =='Select Company' || ele.attributeName =='Select Customer'){
                    ele['value'].forEach(custValue => {
                    console.log(custValue);
                    var commpppp= custValue.id;
                    this.companyId=commpppp;
                    })
                  }
               
              })
            }
  
              
              if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
                
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
                let controlValue = 0;
     
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
        
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      element.lookupValues = [{id: ele.value[0]?.id, lookupValue: ele.value[0]?.name}];
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                      this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                      controlValue = ele.value[0].id;
                    }
                  }
                })
                if(element.parentAttributeId === 0 && element.hasChildAttribute) {
                  setTimeout(() => {
                    this.invokeChildMethod(controlValue, element, '');
                  }, 10);
                }
               
              }else{
                if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           
    
                  if(element.mandatoryFlag==1){
                    this.addCompaniesForm.addControl('countryName',  new FormControl({ value: [], disabled: true },Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('stateName',  new FormControl({ value: [], disabled: true },Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('cityName',  new FormControl({ value: [], disabled: true },Validators.compose([ Validators.required])));
      
                  }else{
    
                    this.addCompaniesForm.addControl('countryName',  new FormControl({ value: [], disabled: true }));
                    this.addCompaniesForm.addControl('stateName',  new FormControl({ value: [], disabled: true }));
                    this.addCompaniesForm.addControl('cityName',  new FormControl({ value: [], disabled: true }));
        
                  }

                  this.editOppVal.customAttributeValues.forEach(ele=>{
              
                    if(ele.attributeId==element.id){
                
                      if(ele.value.length>0){

                        if(ele.value.length > 0){
                          this.addCompaniesForm.controls['countryName'].setValue(ele.value[0].name+'@'+ele.value[0].id);
                        }
                        //after filling country get states 
                        this.getAllStates(ele.value[0].id);
                        if(ele.value.length > 1){
                          this.addCompaniesForm.controls['stateName'].setValue(ele.value[1].name+'@'+ele.value[1].id);
                            //after filling state get cities
                          this.getAllCities(ele.value[1].id);
                        }
                        
                        if(ele.value.length > 2){
                          this.addCompaniesForm.controls['cityName'].setValue(ele.value[2].name+'@'+ele.value[2].id);
                        }
                      }

                    }
                  })

                }
              }
    
              if(element.attributeType.id==2){
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
                var multiSelectArray=[];
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(ele.value.length>0){
                        if(element.parentAttributeId !==0){
                          this.newOpenDiv.push('div_'+ele.attributeId)
                        }
                        this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);  
                        this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                      }
                      ele.value.forEach(res=>{
                        multiSelectArray.push(res.id);
                      })
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                      this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
              }
    
              if(element.attributeType.id==5){
                //radio
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                      this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
              }
    
              if(element.attributeType.id==6){
                //text
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
               
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                      this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
    
              }
    
              if(element.attributeType.id==7){
                //numeric
                const pattern="^(0|[1-9][0-9]*)$";
                if(element.mandatoryFlag==1){
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required,Validators.pattern(pattern)])));
                }else{
    
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true },Validators.compose([ Validators.pattern(pattern)])));
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                     this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
              }
              
    
              if(element.attributeType.id==8){
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
    
              }
    
              if(element.attributeType.id==3 || element.attributeType.id==21){
                if(element.dateAttributes){
                  if(element.dateAttributes.previousDateAllowed){

                    if(element.dateAttributes.previousDateAllowed){
                      this.minDate.setDate(this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
        
                    }
        
                    if(element.dateAttributes.futureDateAllowed){
                   
                      this.maxDate.setDate(this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
            
                    }
                  }
                }
                 
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
                }
    
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                      this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
              }
    
              if(element.attributeType.id==4){
                console.log('element');
                console.log(element);
                element.showIcon = true;  

                this.editOppVal.customAttributeValues.forEach(ele=>{
               
                  if(ele.attributeId==element.id){
                   
                    if(ele.value.length>0){     
                      
                      element.showIcon = false;                      
                      this.showImageArray.push(
                        {
                          "element":element.combinedAttributeName,
                          "image":ele.value
                        }
                      );
                      console.log('jyg');
                      console.log(this.showImageArray);
                      
                      var imagesnameSubArray=[];
                      ele.value.forEach(img=>{
                        var splitted=img.name.split('/');
            
                        imagesnameSubArray.push(splitted[splitted.length -1]);
                      })
                      
                      this.imagesnameArray.push({
                        "name":element.combinedAttributeName,
                        "value":imagesnameSubArray
                      })                                      
                                            
                    }
                  }
                })
              }
              if(element.attributeType.id==22){
                
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }));
               
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                     this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
    
              }
              if(element.attributeType.id==9){
                
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([Validators.email])));
               
                /* if(element.mandatoryFlag==1){
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([Validators.email])));
                   // this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
                }else{
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl({ value: [], disabled: true }, Validators.compose([Validators.email])));
                }*/

                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                     this.addCompaniesForm.controls[element.combinedAttributeName].disable();
                    }
                  }
                })
    
              }
                  //Condition for Data List
                  if (element.attributeType.id == 18) {
    
                    if (element.mandatoryFlag == 1 && element.parentAttributeId === 0) {
                      this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
                    } else {
                      this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                    }
                    this.addCompaniesForm.get(element.combinedAttributeName).disable();
                    // START::setting value to main datalist field
                    let controlValue = 0;
                    this.editOppVal.customAttributeValues.forEach(ele=>{
                 
                      if(ele.attributeId==element.id){
            
                        if(ele.value.length>0){
                          if(element.parentAttributeId !==0){
                            this.newOpenDiv.push({divId: this.createDivId(element), field: element});
                          }
                          this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                          this.addCompaniesForm.get(element.combinedAttributeName).disable();
                          controlValue = ele.value[0].id;
                  
                        }
                      }
                    });
                    
                    if(element.hasChildAttribute) {
                      setTimeout(() => {
                        this.invokeChildMethod(controlValue, element, '');
                      }, 10);
                    }
                    // END::setting value to main datalist field
    
                    if (element.childAttributeList?.length > 0) {
                      element.childAttributeList.forEach(childElement => {
                        // setting some proprties to child datalist fields for later use
                        childElement.parentAttributeId = element.parentDataListId;
                        childElement.parentCombinedAttributeName = element.combinedAttributeName;
                        childElement.mandatoryFlag = element.mandatoryFlag;
                        childElement.parentLinkedDataListAttribId = element.id;
    
                        if (childElement.systemAttribute === null) {
                          childElement.systemAttribute = {};
                        }
    
                        if (childElement.attributeType.id == 1) {
                          if (childElement.mandatoryFlag == 1 && childElement.parentAttributeId === 0) {
                            this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
                          } else {
                            this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl());
                          }
                          // START::setting value to child datalist field
                          this.editOppVal.customAttributeValues.forEach(ele=>{
                       
                            if(ele.attributeId==childElement.id && ele.parentLinkedDatalistAttribId==childElement.parentLinkedDataListAttribId){
                  
                              if(ele.value.length>0){
                                this.newOpenDiv.push({divId: this.createDivId(childElement), field: childElement});
                                this.addCompaniesForm.controls[childElement.combinedAttributeName].setValue(ele.value[0].id);
                                this.addCompaniesForm.get(childElement.combinedAttributeName).disable();
                              }
                            }
                          });
                          // END::setting value to child datalist field
                        }
                      }
                      )
                    }
                  }

             
              //other conditions
              // if(element.systemAttribute.id==11 || element.systemAttribute.id==5 || element.systemAttribute.id==25){
              //   this.systemAttribute11Array.push(element);  
              // }

              //CONDITION FOR CONTACTS LIST
              if(element.systemAttribute.id==6 || element.systemAttribute.id==27){
                this.systemAttribute6Array.push(element);  
              }

              //CONDITION FOR OPPORTUNITY LIST
              if(element.systemAttribute.id==26){
                this.systemAttributeopportunityArray.push(element);  
              }

               //CONDITION FOR COMPANY OWNER LIST
              if(element.systemAttribute.id==34 || element.systemAttribute.id==35 ){
                console.log('condition for owner array');
               this.systemAttributeOwnerArray.push(element);   
              }
              //CONDITION FOR PRODUCT FORM DATA
              if(element.attributeType.id==13){
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormArray([]))
                if (element.parentFormId != null) {
                  this.productFormFieldArray.push(element);
                }
              }

   
            })
            if(this.systemAttributeproductsArray.length >0 || this.productFormFieldArray.length >0){        
              this.getAllProducts(); 
            }
            (res['responseData'] as Array<any>).forEach(field => {
                this.customAttributesList.push(field);
              if (field.attributeType.id == 18 && field.childAttributeList !== null && field.childAttributeList.length > 0) {
                this.customAttributesList.push(...field.childAttributeList);
              }
            });
      
          },
          error => {
            this.disabled=false;
            this.errorMes=error.error.responseData.errorMsg;
    
          }
        )
      }
    )

    setTimeout(()=>{ 
       this.showDivs();
      if(this.systemAttribute11Array.length >0){
         this.getAllCompanies();
 
      }
 
      if(this.systemAttribute6Array.length >0){
        this.getAllContacts();
 
      }
 
      if(this.systemAttributeopportunityArray.length >0){
         this.getAllOpportunities();

      }

      if(this.systemAttributeopportunityListArray.length >0){
         if(this.opportunityId && this.flag=="opportunity"){
          this.getAllOpportunitiesDependentInputs(this.opportunityId);

        }
        else{
          this.systemAttributeopportunityListArray.forEach(rep=>{
            if(rep.systemAttribute.id !==26){
              var divId='div_'+rep.id;
              var division1=<HTMLElement>document.getElementById(divId);
              if(division1){            
                division1.classList.add('hidden');
              }
            }
           
          })        
        }
      }

      if(this.systemAttributecompanyListArray.length >0){
 
        if(this.opportunityId && this.flag=="company"){
          this.getAllCompanyDependentInputs(this.opportunityId);

        }
      }



      if(this.systemAttributeproductsArray.length >0){
           
        this.getAllProducts();

      }

      if(this.systemAttributeEstimateArray.length >0){
        
        this.getAllEstimates();

      }

      if(this.systemAttributeStatusArray.length >0){
   
        this.setStatus();

      }

      if(this.systemAttributeOwnerArray.length >0){
        
        this.getAllOwner();

      }
   
      if(this.showImageArray.length>0){
        this.showImage();
      }
    },2000);
  }

  showImage(){

    console.log('imageDiv');
 	var imageCounter = 0;
 	var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
 	
     this.showImageArray.forEach(res=>{
      var imageDiv=document.getElementById('image'+res.element);
      var combinedAttributeName = res.element;
      this.imageNameIndexMap.set("combinedAttributeName", res.element);
      console.log(imageDiv);
         res.image.forEach(res=>{
	          imageCounter++
	          if(imageCounter <= this.maxImgUploadAllowed || this.maxImgUploadAllowed === 0){
	           	
	          /* 	
	           	var data='<span> <img src="'+res.name+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(imageCounter)+'/'+this.maxImgUploadAllowed+' </span>';
          var moreData = '<div id="removableDiv'+imageCounter+'" class="artist-collection-photo"> <button id="myImgCont'+imageCounter+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+imageCounter+' type="button">�</button>  </div> ';
          data = data + moreData;
          */

         var data = '<div id="removableDiv'+imageCounter+'" class="artist-collection-photo"> ' 
         + '<img width="90" height="100" src="'+res.name+'" alt="image.jpg" class="img-thumbnail">	';
         if(this.maxImgUploadAllowed !== 0){
         	data =  data + '<div class="img-index"> '+(imageCounter)+'/'+this.maxImgUploadAllowed+' </div> ';
         }
         data =  data + '</div> ';

          imageDiv.innerHTML += data;   
	           	
	           
	            oldUploadedImageFileArray.push(res.name);
	         }
         })
     })
     this.imageUploadedCounter = imageCounter + 1;
     if(imageCounter >= this.maxImgUploadAllowed && this.maxImgUploadAllowed > 0){
        this.showUploadImageIcon = false;
     }
     
     // attach remove event
    //  let children = document.getElementsByClassName("closeImageButton");
    //   for (let i = 0; i < children.length; i++) {
    //     children[i].addEventListener("click", (event: Event) => {
    //       this.removeImage(event);
    //     });
    //   }
        	
     this.imageNameIndexMap.set("maxImage", this.maxImgUploadAllowed);
     this.imageNameIndexMap.set("mandatoryFlag", this.imageUploadMandatoryFlag);
    this.imageNameIndexMap.set("imageFileArray", oldUploadedImageFileArray);
     
  }
  showDivs(){
    this.newOpenDiv.forEach(res=>{
      var division1=<HTMLElement>document.getElementById(res);

      if(division1){              
        division1.classList.remove('hidden');
      }
    })
  }


  createDivId(customAttr, isLinkedField: boolean = false, fieldList: any[] = []): string {
    if(isLinkedField) {
      const linkedField = fieldList.find(x => x.linkedAttributeId === customAttr.id);
      if(linkedField) {
        return 'div_' + linkedField.combinedAttributeName + '_' + linkedField.id;
      }
    }
    return 'div_' + customAttr.combinedAttributeName + '_' + customAttr.id;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.addCompaniesForm.reset();
  }

  clickImage(id){
    let element:HTMLElement =document.getElementById(id) as HTMLElement;
    element.click();
  }
  isError(val){
  //console.log('val----------'+val);
  }
  AddCompany(controls){
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;


      if((element.attributeType.id==1 ||
        element.attributeType.id==2 ||
        element.attributeType.id==5 ||
        element.attributeType.id==6 ||
        element.attributeType.id==7 || 
        element.attributeType.id==8 || 
        element.attributeType.id==9) &&
         (element.systemAttribute.id!==16) &&
          (element.systemAttribute.id!==15) &&
           (element.systemAttribute.id!==21)){

       
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
            
          ]
          
        })
      }else{
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':String(this.addCompaniesForm.controls.countryName['value']==null ? '' :this.addCompaniesForm.controls.countryName['value'].trim()+'->'+ this.addCompaniesForm.controls.stateName['value'] == null ? '':this.addCompaniesForm.controls.stateName['value'].trim()+'->'+ this.addCompaniesForm.controls.cityName== null ? '':this.addCompaniesForm.controls.cityName['value'].trim())
              //  'name':this.addCompaniesForm.controls.countryName['value'].trim()+'->'+this.addCompaniesForm.controls.stateName['value'].trim()+'->'+this.addCompaniesForm.controls.cityName['value'].trim()
              }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }          
            
          ]
          
        })
      }

      if(element.attributeType.id==4){
        console.log('image control');
        console.log(this.addCompaniesForm.controls[id]);
        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          this.addCompaniesForm.controls[id]['value'].forEach(resultImage=>{
            resultArrayOfImages.push(
              {
                'name':resultImage
              }
            )
          })
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages
            
          })       
        }
      }

    })


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
    }

    var addData;
    console.log(this.moduleType);
    if(this.moduleType==1){
      addData = {
        "contactId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==2){
      addData = {
        "companyId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==3){
      addData = {
        "opportunityId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==4){
      addData = {
        "productId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues  
      };
    }else{

    }
       
    return addData;
  }

  Save(){
    this.disabled=true;
    this.errorFlag=false;
    
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      var message='';
      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        var min = element.minDigits;
        var max = element.maxDigits;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue.toString().length)){
                this.errorFlag=true;
                message='Enter number greater than '+Number(min) +' digits for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          else if(max!="" && max!=null){
          
              if(Number(elementValue.toString().length) > Number(max)){
                this.errorFlag=true;
                message='Enter number less than '+Number(max) +' digits for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }else if((min!=null && max!=null) && ((min!="0" || max!="0") || (min!=0 || max!=0))){
            if((Number(min) > Number(elementValue.toString().length)) || (Number(elementValue.toString().length) > Number(max))){
              this.errorFlag=true;
              message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          }
        }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
        var min = element.minNumber;
        var max = element.maxNumber;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
        //if(min!="" && max!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue)){
                this.errorFlag=true;
                message='Enter value greater than '+Number(min) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          if(max!="" && max!=null){
              if(Number(elementValue) > Number(max)){
                this.errorFlag=true;
                message='Enter value less than '+Number(max) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          if(min!=null && max!=null){
            if((Number(min) > Number(elementValue)) || (Number(elementValue) > Number(max))){
              this.errorFlag=true;
              message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          } 
      }
    }

    // image validation section
    if(element.attributeType.id === 4 && element.mandatoryFlag === 1 ){      
      var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
      if(oldUploadedImageFileArray.length === 0){
        this.errorFlag=true;
        this.imageNoUploaded=true;
      }                 
    }

    })
    if(this.errorFlag){
      return;
    }
    const controls = this.addCompaniesForm.controls;
    if (this.addCompaniesForm.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }

    console.log(controls);

    const addData=this.AddCompany(controls);
    console.log(addData);

    this._customAttrService.add(addData,this.moduleType).subscribe(

      data => {
        console.log(data);

        this.addCompaniesForm.reset();
        this.passEntry.emit('SUCCESS');
        this.activeModal.close('SUCCESS');
        this.eventEmitterService.onModalCloseResult('SUCCESS');
        
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData.errorMsg;
        console.log(this.errorMes);
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
        
    );
  }

  
  invokeChildMethod(lookupvalue, customAttr, editdata){
    if (!lookupvalue) {
      lookupvalue = 0;
    }
    
    let attributeId=customAttr.id;
    let parentLinkedDataListAttribId = 0;
    
    // Datalist child field condition
    if (customAttr.attributeType.id == 18) {
      attributeId = customAttr.parentDataListId;
      parentLinkedDataListAttribId = customAttr.id;
    } 
    else if (customAttr.moduleType == 6) {
      parentLinkedDataListAttribId = customAttr.parentLinkedDataListAttribId;
    } else if (customAttr.attributeType.id === 1 && customAttr.linkedAttributeId !== null) {
      attributeId = customAttr.linkedAttributeId;
    }
    
    //cal  api
    this.showSpinner();
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{        
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          });
          let childAttribDivIds = [];

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{
              if(customAttr.linkedAttributeId!==null) {
                const linkedField = this.customAttributesList.find(x => x.linkedAttributeId === element.id);
                if(linkedField) {
                  element.combinedAttributeName = linkedField.combinedAttributeName;
                }
              }    
              // update combined attribute name and mandatoryFlag info for datalist child fields
              if (customAttr.attributeType.id == 18) {
                element.combinedAttributeName = customAttr.combinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }    
              else if(customAttr.moduleType == 6 && customAttr.parentCombinedAttributeName) {
                element.combinedAttributeName = customAttr.parentCombinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }
              
              if(editdata){
                editdata.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
      
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    
                    }
                  }
                })
              }
              
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
            
              // var divId='div_'+element.id;
              var divId = this.createDivId(element, customAttr.linkedAttributeId!==null, this.customAttributesList);
              childAttribDivIds.push(divId);

              if(removeIndex >-1){
                console.log('if big div exsits');
                console.log(this.openDivs[removeIndex]);
                console.log(divId);
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item.divId; }).indexOf(String(divId));
                console.log(removeIndexInside);

                if(removeIndexInside >-1){
                  console.log('if small div exsits');
                  //yes exists then no need to do anything
                }else{
                  console.log('if small div not exsits');
                  this.openDivs[removeIndex].value.push({
                    divId: divId, combinedAttributeName: element.combinedAttributeName, 
                    attributeTypeId: element.attributeType.id, 
                    id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                  });
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                    this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                    division1.classList.remove('hidden');
                  } 
                }
                               

              }else{
                console.log('this else div');
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    {
                      divId: divId, combinedAttributeName: element.combinedAttributeName, 
                      attributeTypeId: element.attributeType.id, 
                      id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId}
                  ] 
                })

                var division1=<HTMLElement>document.getElementById(divId);
                console.log(division1);
                if(division1){            
                  this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                  division1.classList.remove('hidden');
                }               
          
              } 

              const childAttrib = this.findChildAttribByIdAndCombinedName(element.id, element.combinedAttributeName, customAttr.linkedAttributeId!==null);
            
              if (childAttrib) {
                childAttrib.lookupValues = element.lookupValues;
                if (childAttrib.attributeType.id === 1) { // following lines will execute for single select only as only single selects may have child fields
                  if (element.lookupValues.find(x => x.id == this.addCompaniesForm.get(childAttrib.combinedAttributeName)?.value)) {
                    this.invokeChildMethod(this.addCompaniesForm.get(childAttrib.combinedAttributeName).value, childAttrib, '');
                  }
                  else {
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib);
                  }
                }
              }

            });
            // ----------------> START:: hiding the divs of child attrib when such a value selected for parent attrib 
            // where there's no relation mapping with child attrib
            var removeIndex = this.openDivs.map(function (item) { return item.attributeId; }).indexOf(attributeId);
            let childAttribsToKeep = [];
            console.log(childAttribDivIds);
            if (removeIndex > -1) {
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res => {
                if (parentLinkedDataListAttribId===res.parentLinkedDataListAttribId && !childAttribDivIds.includes(res.divId)) {
                  var division = <HTMLElement>document.getElementById(res.divId);
                  if (division) {
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                } else {
                  childAttribsToKeep.push(res);
                }
              }); console.log(this.openDivs);
            };
            this.openDivs[removeIndex].value = childAttribsToKeep;
            // <---------------- END
          }else{
            // -> hiding the divs of child attrib whose relationships doesn't exist anymore
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
        
            let markToRemovedChildDivIds: string[] = [];
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                if(res.parentLinkedDataListAttribId===parentLinkedDataListAttribId) { // proceed only if parentLinkedDataListAttribId is matched
                  markToRemovedChildDivIds.push(res.divId);
                  var division=<HTMLElement>document.getElementById(res.divId);
                  if(division){      
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);        
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                }
              });
              //remove key an pairs
              if(this.openDivs[removeIndex].value) {
                if(this.openDivs[removeIndex].value.length === markToRemovedChildDivIds.length) { // remove the whole
                  this.openDivs.splice(removeIndex, 1);           
                }
                else if(this.openDivs[removeIndex].value.length !== markToRemovedChildDivIds.length) { // remove only the mark for remove ones
                  this.openDivs[removeIndex].value = this.openDivs[removeIndex].value.filter(x => !markToRemovedChildDivIds.includes(x.divId));
                }
              }            
              // <- END      
            }
            
          }

        }
        this.hideSpinner();
      }
    )
  }


  findChildAttribByIdAndCombinedName(id, combinedAttributeName, isLinkedField) {
    if(isLinkedField) {
      return this.customAttributesList.find(item => item.linkedAttributeId == id);
    }
    return this.customAttributesList.find(item => item.id == id && item.combinedAttributeName == combinedAttributeName);
  }

  resetChildAttribsAndinvokeChildMethod(childAttrib, clearValidatorsFlag = false) {
    //  -> it will reset child attrib and invoke child method for the same to remove the grand child attribs
    const control = this.addCompaniesForm.get(childAttrib.combinedAttributeName);
    if(control){
      control.reset();
      if(clearValidatorsFlag) {
        control.clearValidators()
        control.updateValueAndValidity();
      }
      if([4, 15, 16].includes(childAttrib.attributeType.id)) {
        const selectedFileLength = this.fileArray[childAttrib.combinedAttributeName].files.length;
        for (let index = 0; index < selectedFileLength; index++) {
          this.removeFile(childAttrib.combinedAttributeName, 0)
        }
      }
    }
    if(childAttrib.attributeType.id == 1){
      this.invokeChildMethod(0, childAttrib, '');
    }
  }

  addValidators(combinedAttributeName: string, mandatoryFlag: boolean, attributeTypeId: number, decimalFlag: boolean){
    if([10, 11, 17].includes(attributeTypeId)){
      return;
    }
    let validatorFns: ValidatorFn[] = [];
    if(mandatoryFlag){
      validatorFns.push(Validators.required);
    }
    if(attributeTypeId == 7){
      let pattern=this.numericPattern;
      if(decimalFlag) {
        pattern = this.decimalPattern;
      }
      validatorFns.push(Validators.pattern(pattern));
    }
    else if(attributeTypeId == 9){
      validatorFns.push(Validators.email);
    }
    const control = this.addCompaniesForm.get(combinedAttributeName);
    control.setValidators(validatorFns);
    control.updateValueAndValidity();
  }


  removeFile(attr, id){
    const fileAttr = this.fileArray[attr];
    if(fileAttr){
      fileAttr.files.splice(id, 1);
      fileAttr.documentIcons?.splice(id, 1);
      let uploadCount = fileAttr.files.length;
      fileAttr.uploaded = uploadCount;
      this.customAttributesList.forEach(x => {
        
        fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
      });
    }
    fileAttr.hasError = false;
    this.updateFileControlValue(attr);
  }

  updateFileControlValue(attr){
    this.addCompaniesForm.controls[attr].setValue(this.fileArray[attr].files);
  }

  showSpinner() {
    if( this.spinnerCount++ === 0) {
      this.SpinnerService.show('fetchingData');
    }
  }

  hideSpinner() {
    if( --this.spinnerCount === 0) {
      this.SpinnerService.hide('fetchingData');
    }
  }

  processFile(event,combinedAttributeName,mandatoryFlag,maxImage){    
    var imageArray= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    console.log(imageArray);
    console.log(maxImage);
    if(maxImage === null || maxImage === undefined){
      maxImage = 0;
    }
    if(imageArray >-1){          
      if(this.imagesnameArray[imageArray].value.length < maxImage || (maxImage === 0))
      {
        
        this.processArray(event,combinedAttributeName,mandatoryFlag);
      }else{
        this.disabled=false;
        this.errorMes="Max "+maxImage+ " images are allowed";
      }

    }else{      
      this.processArray(event,combinedAttributeName,mandatoryFlag);
    } 
  }

  processArray(event,combinedAttributeName,mandatoryFlag){
    //serach for image count
    var imageDiv=document.getElementById('image'+combinedAttributeName);
    var maxImage = this.maxImgUploadAllowed;
 
  // uploaded image count
    var imageArray = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    var uploadedImageCount = 0;
    if(imageArray >-1){
    	uploadedImageCount = this.imagesnameArray[imageArray].value.length;
    }
 
    if(mandatoryFlag==1){
      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
    }else{

      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl());
    }

var totalNumberOfUploadFilesAllowed = this.maxImgUploadAllowed - uploadedImageCount;
if(this.maxImgUploadAllowed === 0){
totalNumberOfUploadFilesAllowed = 1;
}
var imageFilesFromEvent = event.target.files;

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
       var numOfFilesAllowed = this.maxImgUploadAllowed - uploadedImageCount;
       if(this.maxImgUploadAllowed === 0){
      numOfFilesAllowed = 1;
      }
      for (let i = 0; ((i < filesAmount) && (numOfFilesAllowed > 0)); i++) {
         numOfFilesAllowed--;
        var reader = new FileReader();   

        reader.onload = (event:any) => {

           //insert data
            ++uploadedImageCount;
            /*
           var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
          
          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
          data = data + moreData;
          */

         var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
         + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
         + '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
         if(this.maxImgUploadAllowed !== 0){
         	data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
         }
         data = data + '</div> ';

          imageDiv.innerHTML += data;
          this.imageNoUploaded=false;
        
        let children = document.getElementsByClassName("closeImageButton");
		      for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
        	this.imageUploadedCounter = uploadedImageCount + 1;
		    if(uploadedImageCount == this.maxImgUploadAllowed){
		    	this.showUploadImageIcon = false;
		    }
        
        }
        reader.readAsDataURL(event.target.files[i]);
       
      }
      
    }
    
    // upload file on server
     var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
    for (let i = 0; (i < filesAmount && totalNumberOfUploadFilesAllowed > 0); i++) {  
      totalNumberOfUploadFilesAllowed--;
      
        oldUploadedImageFileArray.push(event.target.files[i]);
    this.imageNameIndexMap.set("imageFileArray", oldUploadedImageFileArray);
      
      this._us.uploadImage(this.moduleType,event.target.files[i], this.selectedProject).subscribe(
        res => {     
          console.log('image res');
          console.log(res);
          var removeIndex= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      
          if(removeIndex >-1){          
            this.imagesnameArray[removeIndex].value.push(res['responseData'].name);
    
          }else{
            this.imagesnameArray.push({
              "name":combinedAttributeName,
              "value":[res['responseData'].name]
            })
          }            
        },
        error=>{
          console.log("error occured");
        }
      )
    }

    console.log(this.imagesnameArray);
   
    setTimeout(()=>{
      var removeIndex2= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      console.log(removeIndex2);
      this.addCompaniesForm.get(combinedAttributeName).setValue(this.imagesnameArray[removeIndex2].value);

    },3000)
    
  }

	removeImage(clickEvent){
		var imagePositionIndex = clickEvent.target.dataset.imageindex
		var imageAttributeName = clickEvent.target.dataset.attributename;
	console.log("imagePositionIndex = "+imagePositionIndex+" and imageAttributeName = "+imageAttributeName);
		var removableImageIndex = imagePositionIndex - 1;
	console.log("remove image position index = "+removableImageIndex);
	
	var imageFileArrayFromMap = this.imageNameIndexMap.get("imageFileArray");
	
	console.log("inside removeImage imageFileArrayFromMap.length = "+imageFileArrayFromMap.length);
	
	if(removableImageIndex > -1 && removableImageIndex < imageFileArrayFromMap.length){
		imageFileArrayFromMap.splice(removableImageIndex, 1);
		this.imageNameIndexMap.set("imageFileArray", imageFileArrayFromMap);
		// remove image from UI
    	document.getElementById("removableDiv"+imagePositionIndex).outerHTML = "";
	}
	
	
	
	
	var imageObjectIndex = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(imageAttributeName);
    if(imageObjectIndex >-1){
    	var imageArray = this.imagesnameArray[imageObjectIndex].value;
    	var imageArrayLength = this.imagesnameArray[imageObjectIndex].value.length;
    	if(imageArrayLength > 0 && removableImageIndex > -1 && removableImageIndex < imageArrayLength){
    	 	this.imagesnameArray[imageObjectIndex].value.splice(removableImageIndex, 1);
    	}else{
    		console.log(imageArrayLength+" is the image array length, unable to remove image from index "+removableImageIndex);
    	}
    }else{
    	console.log("Image object not found with key "+imageAttributeName);
    	console.log(this.imagesnameArray);
    }
	
	
	
	// remove all images from UI and create from stating
	var combinedAttributeName = this.imageNameIndexMap.get("combinedAttributeName");
      var maxImage = this.maxImgUploadAllowed;
	var imageFileArray = this.imageNameIndexMap.get("imageFileArray");
    var imageDiv=document.getElementById('image'+imageAttributeName);
    imageDiv.innerHTML = "";
    var uploadedImageCount = 0;
    
   this.imageUploadedCounter = 0;
   var loopCounter = 0;
      
	for (let i = 0; i < imageFileArray.length; i++) {
		  loopCounter++;
		  var imgFileObj = imageFileArray[i];
		  console.log("for index ["+i+"] image "+(typeof imgFileObj !== 'string'));
		  if(typeof imgFileObj !== 'string' ){
		  		console.log("for index ["+i+"] image File "+imgFileObj);
		  	 	var reader = new FileReader();   
		        reader.onload = (event:any) => {
		        ++uploadedImageCount;
		        /* 
            var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
		          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
		          data = data + moreData;
              */

             var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
             + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
             + '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
              if(this.maxImgUploadAllowed !== 0){
             	data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
             }
             data =  data + '</div> ';

		          imageDiv.innerHTML += data;
		           let children = document.getElementsByClassName("closeImageButton");
		      		console.log("inside children.length = "+children.length);
				    for (let i = 0; i < children.length; i++) {
				        children[i].addEventListener("click", (event: Event) => {
				          this.removeImage(event);
				        });
				      }
		        }
		        reader.readAsDataURL(imgFileObj);
		  }else{
		  	 ++uploadedImageCount;
		  	 console.log("for index ["+i+"] image URL "+imgFileObj);
		  	 /*
         var data='<span> <img src="'+imgFileObj+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
	          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
	          data = data + moreData;
            */
           var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
           + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
           + '<img width="90" height="100" src="'+imgFileObj+'" alt="image.jpg" class="img-thumbnail">	';
           if(this.maxImgUploadAllowed !== 0){
           		data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
           	}
           data =  data + '</div> ';
	          imageDiv.innerHTML += data; 
		  }
      }
      
      let children = document.getElementsByClassName("closeImageButton");
      console.log(" children.length = "+children.length);
		    for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
		      
		    
		    this.imageUploadedCounter = loopCounter + 1;
		    if(loopCounter == maxImage && maxImage !== 0){
		    	this.showUploadImageIcon = false;
		    }else{
		    	this.showUploadImageIcon = true;
		    }    
		    
		    if(imageFileArray.length == 0){
		    	this.imageUploadedCounter = 0;
          if(this.imageUploadMandatoryFlag === 1){
            this.imageNoUploaded = true;
          }   
		    }
	}
  OpenAlertValidation(message){
    const initialNotice = message;
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       width: '600px',
       position: {top: '20px'},
       data: { title: 'Alert', body: initialNotice }
     });
  
}
 /** For  Select All in Multiselect */
  
 toggleAllSelection(matSelect: MatSelect) {
  if (this.allSelected) {
    matSelect.options.forEach((item: MatOption) => {(item.value!==undefined && item.value!==null) ? item.select() : null});
  } else {
    matSelect.options.forEach((item: MatOption) => item.deselect());
  }
}
 optionClick(matSelect: MatSelect) {
  let newStatus = true;
  matSelect.options.forEach((item: MatOption) => {
    if (!item.selected) {
      newStatus = false;
    }
  });
  this.allSelected = newStatus;
}
}



